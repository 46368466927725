import React, { Suspense, useState, useMemo } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import {
  createStyles, CssBaseline, makeStyles,
} from '@material-ui/core';
import '../../index.css';
import routes from '../../routes/routes';

const useStyles = ({ top = 0 } = {}) => makeStyles((theme) => createStyles({
  root: {
    marginTop: top,
    transition: theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    backgroundColor: theme.palette.grey['50'],
  },
}));

const SurveyApp: React.FC<Props> = ({ position }) => {
  const classes = useStyles(position)();
  const [eventPayload, setEventPayload] = useState<any>({});
  const value = useMemo(() => ({ eventPayload, setEventPayload }), [
    eventPayload,
  ]);
  return (
    <div className={classes.root}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading</div>}>
          <Switch>
            {routes.map(({
              path,
              exact,
              component: Component,
            }) => (
              <Route
                path={path}
                exact={exact}
                render={(props) => (
                  <div>
                    <CssBaseline />
                      <Component {...props} position={position} />
                  </div>
                )}
                key={path}
              />
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter >
    </div>
  );
};

export type Props = {
  position?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
};
SurveyApp.displayName = 'SurveyApp';
export default SurveyApp;
