import { axios } from '../internal-lib';
import { propertyServiceBaseURL } from '../config';
import onSuccess from './onSuccess';
import {
  BHPropertyFieldRule,
  BHPropertyFieldRulePatch,
  BHPropertyRules,
} from '../types/bh-property-field-rule';

export const getPropertyFieldRule = (surveyId: string) =>
  axios
    .get<BHPropertyRules>(
      `${propertyServiceBaseURL}propertyfieldrule/externalId/${surveyId}/ownerType/SC_SURVEY`,
    )
    .then(onSuccess);

export const updatePropertyFieldRule = (propertyFieldRule: BHPropertyFieldRulePatch) =>
  axios
    .patch<BHPropertyFieldRule>(
      `${propertyServiceBaseURL}propertyfieldrule/status`,
      propertyFieldRule,
    )
    .then(onSuccess);

export const updatePropertyFieldOrder = (
  propertyFieldRule: BHPropertyFieldRulePatch,
) =>
  axios
    .patch<BHPropertyFieldRule>(
      `${propertyServiceBaseURL}propertyfieldrule/reorder`,
      propertyFieldRule,
    )
    .then(onSuccess);
