import { invalidateSurveyPdfFiles, uploadPropertyMediaFile } from '../../services/media-service';
import { Survey } from '../../types';
import { MediaFile } from '../../types/media-file';
import { UseSurveyAPI } from './useSurveyAPI';

export type UseMediaAPIProps = {
  setSurvey: UseSurveyAPI['setSurvey'];
  survey: Survey;
};

export type UseMediaAPI = {
  invalidatePdf: () => void;
  updateMedia: (mediaType: string) => void;
}

export const mediaUpload = (files: any, surveyId: string) => new Promise<MediaFile>((resolve) => {
  const useremail = localStorage.getItem('email');
  const payloadMedia = {
    surveyId,
    user: useremail,
    mediaType: 'MARKETMAP',
  };
  const formData = new FormData();
  formData.append('file', files);
  formData.append('media', JSON.stringify(payloadMedia));
  const headers: any = {
    'Content-Type': 'application/json',
  };
  return uploadPropertyMediaFile(formData, headers).then((res: MediaFile) => {
    resolve(res);
  });
});

export default function useMediaAPI({
  setSurvey,
  survey,
}: UseMediaAPIProps): UseMediaAPI {

  const updateMedia = (mediaType: string) => {
    const triggerIndexFeatureImg = survey?.extraTrigger?.featureImg || 0;
    const triggerIndexMarketMap = survey?.extraTrigger?.marketMap || 0;
    if (mediaType === 'FEATUREIMAGE') {
      setSurvey(({
        ...survey,
        extraTrigger: {
          ...survey?.extraTrigger,
          featureImg: triggerIndexFeatureImg + 1,
        },
      }));
    } else if (mediaType === 'MARKETMAP') {
      setSurvey(({
        ...survey,
        extraTrigger: {
          ...survey?.extraTrigger,
          marketMap: triggerIndexMarketMap + 1,
        },
      }));
    }
    setSurvey(({
      ...survey,
      extraTrigger: {
        marketMap: triggerIndexMarketMap + 1,
      },
    }));
  };


  const invalidatePdf = async () => { 
    try {
      if (
        survey.surveyPDF
      ) {
        const id: string = survey.id;
        await invalidateSurveyPdfFiles(id);
        setSurvey({ ...survey, surveyPDF: undefined });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error calling invalidate API', error);
    }
  }

  return {
    invalidatePdf,
    updateMedia,
  };
}
