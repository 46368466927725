/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { parseInputChange } from '@cbreone/utilities';
import DynamicSectionsForm from '../PropertyDetail/DynamicSectionsForm'
import { Section } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formRoot: {
    backgroundColor: theme.palette.grey[100],
    padding: '16px 0px 16px 28px',
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1A1A1A',
    marginBottom: '16px',
  },
  textFields: {

    width: '25em',
    marginRight: '40px',
    '& .MuiInputBase-root': {
      color: '#212121',
      fontSize: '16px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #212121',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      border: '1px solid #212121',
    },
    '& .MuiFormLabel-root': {
      // color: '#6b6767'
    },

  },
  lastTextField: {
    width: '45%',
    '& .MuiInputBase-root': {
      color: '#212121',
      fontSize: '16px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #212121',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      border: '1px solid #212121',
    },
    '& .MuiInputLabel-outlined': {
      color: '#1A1A1A99',
    },
  },

  fieldGap: {
    marginBottom: '1rem',
    display: 'flex',
    // flexWrap: 'wrap'
  },
  headerTwo: {
    marginTop: '32px',
    marginBottom: '16px',
  },
  gridStyle: {
    width: '100%',
  },

}));

const AvailabilitiesInformation: FC<Props> = ({
  propertyData,
  newAddedAvailability,
  sections,
  onUpdateAvailability,
}: any) => {
  const stopAccordionPropagation = (e: React.MouseEvent | React.FocusEvent) =>
    e.stopPropagation()

  let debounced: any = null

  const handleUpdateAvailability = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = parseInputChange(event.target)
    const { propertyId, id } = newAddedAvailability
    let newValue: any = value?.toLocaleString()

    // refactor to switch case later

    onUpdateAvailability(
      {
        propertyId,
        id,
        name,
        value: newValue,
      },
      (res: any) => {},
    )
  }

  debounced = debounce((event) => {
    if (event) {
      handleUpdateAvailability(event);
    }
  }, 0);

  const onValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const args = {
      target: { name: event.target.name, value: event.target.value },
    }
    if (debounced) {
      debounced.cancel()
    }
    debounced(args)
  }

  return (
    <DynamicSectionsForm
      sections={sections}
      handlePropertyUpdate={onValueChange}
      property={propertyData}></DynamicSectionsForm>
  )
}

export type Props = {
  propertyData: any
  sections?: Section[]
  newAddedAvailability: any
  onUpdateAvailability: any
}

export default React.memo(AvailabilitiesInformation);
