import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Box,
  Link,
} from '@material-ui/core';
import { Typography } from '@cbreone/core-ui';
import React from 'react';
import { SinglePropertyPages } from './PropertyPreview';
import Header from '../PDFCommon';
import PropertyFeatureImage from '../PropertyFeatureImage';
import SurveyPDFPage from '../SurveyPDFPage';
import Footer from '../Footer';
import { PDFPaperSize } from '../../data/constants';
import { Survey } from '../../types';
import usePreviewStyles from './PreviewStyles';
import PropertyAvailabilityPreview from './PropertyAvailabilityPreview';

const GeneratePropertyPDFPreviewPages: React.FC<Props> = ({
  propertyPrev,
  propertyIndex,
  survey,
  order,
  anchore,
  pageMode,
}) => {
  const classes = usePreviewStyles();

  const extraInfoBlockStyle = (extraInfoBlock: any) => (extraInfoBlock.heading
    ? classes.commentSectionHeading
    : classes.commentSection
  );

  return (
  <>
  {propertyPrev.propertyDetailPages.map((page, index) => {
    let pageHeightClassName = classes.pageContainerLandscapeNoHeaderHeight;
    if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
      if (page.featurePage) {
        pageHeightClassName = classes.pageContainerPortraitHeaderHeight;
      } else {
        pageHeightClassName = classes.pageContainerPortraitNoHeaderHeight;
      }
    } else if (page.featurePage) {
      pageHeightClassName = classes.pageContainerLandscapeHeaderHeight;
    }
    const elementkey: string = `${propertyIndex}${index}`;
    return (
        <div id={`${order}`} style={anchore} key={elementkey}>
          <SurveyPDFPage mode={pageMode} key={elementkey} paperSize={survey.pdfPaperSize}>
            {page.featurePage
              && <Header title={page.title} subtitle={page.subtitle} order={page.order} />}
            <Box className={ `${classes.pageContainer} ${pageHeightClassName}`}>
              <Grid container alignItems="flex-start">
                <Grid
                  item sm={6} xs={12}
                  style={{ paddingRight: '8px' }}>
                  {page.featurePage
                    && <PropertyFeatureImage
                        propertyId={page.propertyId} survey={survey} size="medium"
                        mode={pageMode} />
                  }
                  {page.extraInfo.map((extraInfoBlock, extraIndex) => (
                    <div
                      key={`${elementkey}-extraInfo-${extraIndex}`}
                      className={ extraInfoBlockStyle(extraInfoBlock)}
                    >
                      <Typography
                        variant={extraInfoBlock.heading ? 'subtitle2' : 'caption'}
                      >
                        {extraInfoBlock.textBlock}
                      </Typography>
                    </div>
                  ))
                  }
                </Grid>
                <Grid
                  item sm={6} xs={12}
                  style={{ paddingLeft: '8px' }}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableBody>
                        {page.propertyDetails.map((propDetail, propertyDetailIndex) => (
                          <TableRow key={propDetail.detailName} className={`${propertyDetailIndex % 2 ? classes.evenRow : classes.oddRow}`}>
                            <TableCell className={classes.tableCell} component="th" key={propDetail.detailName}>
                              <Typography variant="caption">
                                {propDetail.detailName}
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} component="td" key={propDetail.detailValue}>
                            {(propDetail.detailName === 'Website' && propDetail.detailValue !== '') ?
                            <Link href={propDetail.detailValue} className={classes.link}>
                              {propDetail.detailValue}
                            </Link> :
                              <Typography variant="caption">
                                {propDetail.detailValue}
                              </Typography>
                            }
                            </TableCell>
                          </TableRow>))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
           { page.availabilities && page.availabilities.length > 0 && <Grid container alignItems="flex-start" style={{ paddingTop: page.availabilityHeader ? '20px' : '0' }}>

                <PropertyAvailabilityPreview
                availabilities={page.availabilities} header={page.availabilityHeader}>

                </PropertyAvailabilityPreview>
              </Grid>}
            </Box>
            <div className={classes.footer} ><Footer surveyCountry={survey.surveyCountry}></Footer></div>
          </SurveyPDFPage>
        </div>
    );
  })
  }
  </>
  );
};

export type Props = {
  propertyPrev: SinglePropertyPages,
  propertyIndex: number,
  survey: Survey,
  order: string,
  anchore?: object,
  pageMode?: string,
}

export default React.memo(GeneratePropertyPDFPreviewPages);
