import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Column,
  DataObject,
  Property,
  PropertyAvailabilitiesTypes,
  Section,
} from '../../types';
import FormCellRenderer from './FormCellRenderer';
import { INPUT_TYPE } from '../../data/constants';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
}));

const DynamicSectionForm: React.FC<Props> = ({
  property,
  handlePropertyUpdate,
  sections,
}) => {
  const classes = useStyles();

  return (
    <>
      {sections?.map((section: Section) => (
        <div key={section.sectionId}>
          <div className={classes.v_spacer} />
          <Typography variant="h6">{section.sectionName}</Typography>
          <div className={classes.v_spacer} />
          {
            <Grid container spacing={2}>
              {section.columns.map((column: Column) => (
                <Grid
                  item
                  sm={column.inputType === INPUT_TYPE.MULTI_LINE ? 12 : 6}
                  xs={12}
                  key={section.sectionName + column.field}>
                  <FormCellRenderer
                    property={property.data as DataObject}
                    name={column.field}
                    type={column.inputType}
                    label={column.columnHeader}
                    handlePropertyUpdate={handlePropertyUpdate}
                  />
                </Grid>
              ))}
            </Grid>
          }
        </div>
      ))}
    </>
  );
};

export type Props = {
  property: Property | PropertyAvailabilitiesTypes
  sections?: Section[]
  handlePropertyUpdate: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
}

DynamicSectionForm.displayName = 'DynamicSectionForm'
export default React.memo(DynamicSectionForm)
