import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import { Dispatch, SetStateAction, useState } from 'react';
import { appRootURL } from '../../config';
import { PDFPaperSize } from '../../data/constants';
import * as mediaService from '../../services/media-service';
import { reorderProperty } from '../../services/property-service';
import * as surveyContactService from '../../services/survey-contact-service';
import * as surveyCustomFieldService from '../../services/survey-customField-service';
import * as surveyPropertyFieldRuleService from '../../services/survey-propertyField-rule-service';
import { updatePropertyFieldOrder } from '../../services/survey-propertyField-rule-service';
import * as surveySectionService from '../../services/survey-section-service';
import * as surveyService from '../../services/survey-service';
import {
  MediaFile,
  Property,
  PropertyFiles,
  ReorderableSurveyAttributes,
  Survey,
  SurveyContact,
  SurveyContactPatch,
  SurveyContactPost,
  SurveyPatch,
  SurveySectionPatch,
  SurveySectionPost,
  SurveySectionPreview,
} from '../../types';
import { BHCustomField, BHCustomFieldPost } from '../../types/bh-custom-field';
import {
  BHPropertyFieldRule,
  BHPropertyFieldRulePatch,
} from '../../types/bh-property-field-rule';
import {
  flattenMediaFilesWithModifiedFiles,
  getNestedObjByString,
  isChangingMapSetting,
  loadNestedAttrByString,
} from '../../utilities';

export type DraggableLocation = {
  droppableId: string;
  index: number;
};
export type DragResult = {
  source: DraggableLocation;
  destination: DraggableLocation;
  draggableId: string;
};

export type UseSurveyAPI = {
  addCustomField: (post: BHCustomFieldPost) => void;
  addPropertyFiles: (post: PropertyFiles) => Promise<Survey>;
  addSurveyContact: (post: SurveyContactPost) => void;
  addSurveySection: (post?: SurveySectionPost) => void;
  deleteCustomField: (post: BHCustomField) => void;
  deleteSurveyContact: (id: string) => void;
  deleteSurveySection: (id: string) => void;
  reorderSurveyAttribute: (result: DragResult) => void;
  setSurvey: Dispatch<SetStateAction<Survey>>;
  survey: Survey;
  resetMarketMap: (callback: any) => void;
  updateCustomField: (patch: BHCustomField) => void;
  updateSurvey: (patch: SurveyPatch) => void;
  updateSurveyContact: (patch: SurveyContactPatch) => void;
  updateSurveySection: (patch: SurveySectionPatch) => void;
  updatePropertyFieldRule: (patch: BHPropertyFieldRulePatch) => void;
  generateSurveyPDF: () => Promise<any>;
};

// eslint-disable-next-line no-console
const logError = (message?: string) => (error: Error) =>
  // eslint-disable-next-line no-console
  console.error(message || 'Error', error);

export default function useSurveyAPI(
  resetMapStateCallback: Function,
): UseSurveyAPI {
  const [survey, setSurvey] = useState<Survey>({} as Survey);
  const resetMarketMap = (callback: any) => {
    const revert = { ...survey };
    const patch = {
      id: survey.id,
      marketMapBamData: '{"mapReset":true}',
    };
    surveyService
      .updateSurvey(patch)
      .then((res: any) => {
        if (res.updatedOn >= survey.updatedOn) {
          setSurvey(res);
          callback(res);
        }
      })
      .catch((error) => {
        logError('Failed to update Survey')(error);
        setSurvey(revert);
      });
  };

  const updateSurvey = (patchParam: SurveyPatch) => {
    let patch = {
      ...patchParam,
    };
    if (isChangingMapSetting(patchParam)) {
      patch = {
        ...patch,
        marketMapBamData: '{"mapReset":true}',
      };
    }
    const revert = { ...survey };
    setSurvey({
      ...survey,
      ...patch,
      updatedOn: new Date(Date.now()).toISOString(),
    } as Survey);
    surveyService
      .updateSurvey(patch)
      .then((res: any) => {
        if (res.updatedOn >= survey.updatedOn) {
          /**
           * When server is responding we will have to reconcile nested objects
           * contacts, customFields, sections, properties and property.customFields, property.availabilities
           */
          setSurvey(res);
        }
      })
      .catch((error) => {
        logError('Failed to update Survey')(error);
        setSurvey(revert);
      });
  };

  const addSurveyContact = (
    post: SurveyContactPost = {
      name: '',
      title: '',
      email: '',
      phoneNumber: '',
      order: 0,
    },
  ) => {
    const currentContacts = survey.contacts || [];
    const contact = {
      ...post,
      surveyId: survey.id,
      survey: {
        // TODO: depended on the BE refactoring, the nested survey object may not be needed anymore.
        surveyId: survey.id,
      },
    };
    setSurvey({
      ...survey,
      contacts: [...currentContacts, contact as SurveyContact],
    });
    surveyContactService
      .addContact(contact)
      .then((res) => {
        setSurvey({
          ...survey,
          contacts: [...currentContacts, res],
        });
      })
      .catch((error) => {
        setSurvey({
          ...survey,
          contacts: [...currentContacts],
        });
        logError('Failed to add Contact')(error);
      });
  };

  const deleteSurveyContact = (contactId: string) => {
    const currentContacts = survey.contacts || [];
    setSurvey({
      ...survey,
      contacts: survey.contacts.filter(
        (c: SurveyContact) => c.contactId !== contactId,
      ),
    });
    surveyContactService
      .deleteContact(contactId)
      .then(() => {
        setSurvey({
          ...survey,
          contacts: survey.contacts.filter(
            (c: SurveyContact) => c.contactId !== contactId,
          ),
        });
      })
      .catch((error) => {
        setSurvey({
          ...survey,
          contacts: [...currentContacts],
        });
        logError('Failed to delete Contact')(error);
      });
  };

  const updateSurveyContact = (patch: SurveyContactPatch) => {
    const currentContacts = survey.contacts || [];
    setSurvey({
      ...survey,
      contacts: currentContacts.map((contact: SurveyContact) => {
        if (contact.contactId === patch.contactId) {
          return { ...contact, ...patch };
        }
        return contact;
      }),
    });
    surveyContactService
      .updateContact(patch.contactId, patch)
      .then((res) => {
        setSurvey((current: Survey) => {
          if (current) {
            return {
              ...current,
              contacts: current.contacts.map((contact: SurveyContact) => {
                if (contact.contactId === res.contactId) {
                  return { ...contact, ...res };
                }
                return contact;
              }),
            };
          }
          return current;
        });
      })
      .catch((error) => {
        setSurvey({
          ...survey,
          contacts: [...currentContacts],
        });
        logError('Failed to update Contact')(error);
      });
  };

  const addSurveySection = (
    post: SurveySectionPost = {
      name: 'Untitled Section',
      surveyId: survey.id,
      isHidden: false,
      isCustom: true,
    },
  ) => {
    const section = {
      ...post,
      surveyId: survey.id,
    };
    surveySectionService
      .addSection(section)
      .then((res) => {
        setSurvey({
          ...survey,
          sections: [...survey.sections, res],
        });
      })
      .catch(logError('Failed to Add Section'));
  };

  const deleteSurveySection = (id: string) => {
    const revert = [...survey.sections];
    setSurvey({
      ...survey,
      sections: survey.sections.filter((s) => s.surveySectionId !== id),
    });
    surveySectionService.deleteSection(id).catch((error) => {
      logError('Failed to delete Section')(error);
      setSurvey({
        ...survey,
        sections: revert,
      });
    });
  };

  const updateSurveySection = (patch: SurveySectionPatch) => {
    const updated = new Date(Date.now()).toISOString();
    const revert = survey.sections.find(
      (s) => s.surveySectionId === patch.surveySectionId,
    );
    setSurvey({
      ...survey,
      updatedOn: updated,
      sections: survey.sections.map((section) =>
        section.surveySectionId !== patch.surveySectionId
          ? section
          : { ...section, ...patch, updatedOn: updated },
      ),
    });
    surveySectionService
      .updateSection(patch)
      .then((res) => {
        setSurvey((current) => ({
          ...current,
          sections: current.sections.map((section) =>
            section.surveySectionId !== res.surveySectionId ||
            section.updatedOn > res.updatedOn
              ? section
              : { ...section, ...res },
          ),
        }));
      })
      .catch((error) => {
        logError('Failed to update Section')(error);
        setSurvey((current) => ({
          ...current,
          sections: current.sections.map((section) =>
            section.surveySectionId === patch.surveySectionId && revert
              ? revert
              : section,
          ),
        }));
      });
  };

  const addCustomField = (post: BHCustomFieldPost) => {
    const currentCustomFields = survey.customFields;
    const addCustomFieldPromise = () =>
      new Promise<BHCustomField>((resolve, reject) => {
        surveyCustomFieldService
          .addCustomField(post)
          .then((res) => {
            setSurvey((current: Survey) => {
              if (current) {
                return {
                  ...current,
                  surveyPropertyFieldRule: current.surveyPropertyFieldRule
                    ? [
                        ...current.surveyPropertyFieldRule,
                        {
                          fieldKey: res.fieldKey,
                          fieldLabel: res.fieldLabel,
                          order: current.surveyPropertyFieldRule.length,
                          status: 'ACTIVE',
                        },
                      ]
                    : [],
                  customFields: [
                    ...currentCustomFields,
                    {
                      fieldKey: res.fieldKey,
                      fieldLabel: res.fieldLabel,
                    },
                  ],
                } as Survey;
              }
              return current;
            });
            resolve(res);
          })
          .catch((error) => {
            setSurvey({
              ...survey,
              customFields: [...currentCustomFields],
            });
            logError('Failed to add Custom field')(error);
            reject();
          });
      });

    addCustomFieldPromise().then((res) => getPropertyFieldRule(survey.id));
  };

  const updateCustomField = (patch: BHCustomField) => {
    const updatedOn = new Date(Date.now()).toISOString();
    const currentCustomFields = survey.customFields;
    setSurvey({
      ...survey,
      customFields: currentCustomFields.map((customField: BHCustomField) => {
        if (customField.fieldKey === patch.fieldKey) {
          return { ...customField, fieldLabel: patch.fieldLabel || '' };
        }
        return customField;
      }),
      updatedOn,
    });
    const updateCustomFieldPromise = () =>
      new Promise<BHCustomField>((resolve, reject) => {
        surveyCustomFieldService
          .updateCustomField(patch)
          .then((res) => {
            setSurvey({
              ...survey,
              surveyPropertyFieldRule: survey.surveyPropertyFieldRule?.map(
                (fieldRule: BHPropertyFieldRule) => {
                  if (fieldRule.fieldKey === patch.fieldKey) {
                    return {
                      ...fieldRule,
                      fieldLabel: res.fieldLabel,
                    };
                  }
                  return fieldRule;
                },
              ),
              customFields: currentCustomFields.map(
                (customField: BHCustomField) => {
                  if (customField.fieldKey === patch.fieldKey) {
                    return {
                      ...customField,
                      fieldLabel: res.fieldLabel,
                    };
                  }
                  return customField;
                },
              ),
            });
            resolve(res);
          })
          .catch((error) => {
            setSurvey({
              ...survey,
              customFields: [...currentCustomFields],
            });
            logError('Failed to update Custom field')(error);
            reject();
          });
      });
    updateCustomFieldPromise().then((res) => {});
  };

  const deleteCustomField = (customField: BHCustomField) => {
    const currentCustomFields = survey.customFields;
    setSurvey({
      ...survey,
      customFields: survey.customFields.filter(
        (field: BHCustomField) => field.fieldKey !== customField.fieldKey,
      ),
    });
    const deleteCustomFieldPromise = () =>
      new Promise<void>((resolve, reject) => {
        surveyCustomFieldService
          .deleteCustomField(customField)
          .then(() => {
            setSurvey({
              ...survey,
              surveyPropertyFieldRule: survey.surveyPropertyFieldRule?.filter(
                (fieldRule: BHPropertyFieldRule) =>
                  fieldRule.fieldKey !== customField.fieldKey,
              ),
              customFields: currentCustomFields?.filter(
                (field: BHCustomField) =>
                  field.fieldKey !== customField.fieldKey,
              ),
            });
            resolve();
          })
          .catch((error) => {
            setSurvey({
              ...survey,
              customFields: [...currentCustomFields],
            });
            logError('Failed to delete Custom field')(error);
            reject();
          });
      });
    deleteCustomFieldPromise().then(() => getPropertyFieldRule(survey.id));
  };

  const moveItemInList = <T extends {}>(
    list: T[],
    start: number,
    end: number,
  ) => {
    const array = Array.from(list);
    const [removed] = array.splice(start, 1);
    array.splice(end, 0, removed);
    return array.map((item, index) => ({
      ...item,
      order: index,
    }));
  };

  const reorderSurveyAttribute = ({
    source,
    destination,
    draggableId,
  }: DragResult) => {
    if (destination) {
      const attribute = source.droppableId as ReorderableSurveyAttributes;
      const currentAttributes = loadNestedAttrByString(
        survey,
        attribute.toString(),
      );
      const newList = moveItemInList<(typeof currentAttributes)[number]>(
        currentAttributes as any[],
        source.index,
        destination.index,
      );
      const newSurvey = {
        ...survey,
        ...getNestedObjByString(attribute.toString(), newList),
      };
      setSurvey(newSurvey);
      const attributeIdMap = {
        properties: { name: 'propertyId', path: 'property/reorder' },
        contacts: { name: 'contactId', path: 'contacts/reorder' },
        sections: { name: 'surveySectionId', path: 'section/reorder' },
        surveyPropertyFieldRule: {
          name: 'fieldId',
          path: 'survey/propertyfieldrule/reorder/',
        },
        surveyPropertyAvailabilityRule: {
          name: 'fieldId',
          path: 'survey/propertyfieldrule/reorder/',
        },
      };
      const { name, path } = loadNestedAttrByString(
        attributeIdMap,
        attribute.toString(),
      );
      if (name === 'propertyId') {
        reorderProperty({
          propertyId: draggableId,
          ownerId: survey.id,
          ownerType: 'SC_SURVEY',
          order: destination.index,
        })
          .then(async (res: any) => {
            /**
             * promise all response is an array of survey, customFields, propertyFieldRule, templateConfig 
             */
            const data = await surveyService.getSurveyConfig(survey.id);
            if (data && Array.isArray(data)) {
              const customFields = data[1] || [];
              const propertyFieldRule = data[2] || [];
              const templateConfig = data[3].data || [];
              const surveyVal = {
                ...data[0],
                customFields,
                templateConfig,
                surveyPropertyFieldRule: propertyFieldRule.propertyAttributes,
                surveyPropertyAvailabilityRule:
                  propertyFieldRule.availabilityAttributes,
                updatedOn: new Date(Date.now()).toISOString(),
                properties: res.map((p: Property) => ({
                  ...p,
                  isHidden: p.status === 'INACTIVE',
                })),
              };
              setSurvey(surveyVal);
              resetMapStateCallback(res);
            }
          })
          .catch(() => {
            logError('Failed to reorder');
            setSurvey((current) => ({
              ...current,
              ...getNestedObjByString(attribute.toString(), currentAttributes),
            }));
          });
      } else if (name === 'fieldId') {
        const newField = newList[destination.index];
        updatePropertyFieldOrder({
          ...newField,
          ownerId: survey.id,
          ownerType: 'SC_SURVEY',
          attributeType:
            attribute.toString() === 'surveyPropertyAvailabilityRule'
              ? 'Availability'
              : 'Property',
        }).then(() => {
          resetMapStateCallback(newSurvey);
        });
      } else {
        // TODO: the endpoint may get updated based on the API refactoring. (generic reordering api)
        surveyService
          .reorderPatch(path, {
            surveyId: survey.id,
            [name]: draggableId,
            order: destination.index,
          })
          .then(() => {
            if (path === 'section/reorder') {
              Analytics.send({
                event: 'Reorder Survey Sections',
                user_id: localStorage.getItem('employeeId'),
                jobTitle: localStorage.getItem('position'),
                officeName: localStorage.getItem('office'),
                email: localStorage.getItem('email'),
                functUnitName: localStorage.getItem('functUnitName'),
              });
            }
            surveyService.getSurvey(survey.id).then((res) => {
              resetMapStateCallback(res);
            });
          })
          .catch((error) => {
            logError('Failed to reorder')(error);
            setSurvey((current) => ({
              ...current,
              ...getNestedObjByString(attribute.toString(), currentAttributes),
            }));
          });
      }
    }
  };
  // TODO: US1285118
  const addPropertyFiles = (post: PropertyFiles) =>
    new Promise<Survey>((resolve, reject) =>
      surveyService
        .addProperties(post)
        .then((res: any) => {
          if (res.id) {
            setSurvey(res);
            resolve(res);
          }
        })
        .catch(() => {
          logError('Failed to upload properties');
          reject();
        }),
    );

  // TODO: US1285118
  /*
  const addPropertyFiles =  async (post: PropertyFiles) => {
    await surveyService
      .addProperties(post)
      .then((res) => {
        if (res.id) {
          setSurvey(res);
        }
      })
      .catch(logError('Failed to upload properties'));
  };
  */

  const updatePropertyFieldRule = (
    propertyFieldRule: BHPropertyFieldRulePatch,
  ) => {
    const currentPropertyFieldRules = survey.surveyPropertyFieldRule;
    surveyPropertyFieldRuleService
      .updatePropertyFieldRule(propertyFieldRule)
      .then((res) => {
        setSurvey((current: Survey) => {
          if (current) {
            return {
              ...current,
              surveyPropertyFieldRule:
                propertyFieldRule.attributeType !== 'Availability'
                  ? updateAttributes(
                      current.surveyPropertyFieldRule as BHPropertyFieldRule[],
                      res,
                    )
                  : current.surveyPropertyFieldRule,
              surveyPropertyAvailabilityRule:
                propertyFieldRule.attributeType === 'Availability'
                  ? updateAttributes(
                      current.surveyPropertyAvailabilityRule as BHPropertyFieldRule[],
                      res,
                    )
                  : current.surveyPropertyAvailabilityRule,
            };
          }
          return current;
        });
      })
      .catch((error) => {
        setSurvey((current: Survey) => {
          if (current) {
            return {
              ...current,
              surveyPropertyFieldRule: currentPropertyFieldRules,
              surveyPropertyAvailabilityRule:
                survey.surveyPropertyAvailabilityRule,
            };
          }
          return current;
        });
        logError('Failed to update property field rule')(error);
      });
  };

  const getPropertyFieldRule = (surveyId: string) => {
    const currentPropertyFieldRules = survey.surveyPropertyFieldRule;
    surveyPropertyFieldRuleService
      .getPropertyFieldRule(surveyId)
      .then((res) => {
        setSurvey((current: Survey) => {
          if (current) {
            return {
              ...current,
              surveyPropertyFieldRule: res.propertyAttributes,
              surveyPropertyAvailabilityRule: res.availabilityAttributes,
            };
          }
          return current;
        });
      })
      .catch((error) => {
        setSurvey((current: Survey) => {
          if (current) {
            return {
              ...current,
              surveyPropertyFieldRule: currentPropertyFieldRules,
              surveyPropertyAvailabilityRule:
                current.surveyPropertyAvailabilityRule,
            };
          }
          return current;
        });
        logError('Failed to update property field rule')(error);
      });
  };

  const getPropertyLink = (property: Property) =>
    new Promise<any>((resolve, reject) => {
      let links: any[] = [];
      if (survey.isIncludeFlyers) {
        mediaService
          .getMediaFilesForProperty(survey.id, property.id, 'FLIERS')
          .then((response) => {
            if (response && response.media && response.media.length > 0) {
              const resp = flattenMediaFilesWithModifiedFiles(
                response.media.filter((media: MediaFile) => !media.isHidden),
              );
              resp.forEach((media: MediaFile) => {
                const flyerLink = {
                  name: 'property flyer',
                  link: media.cloudfrontUrl,
                };
                links = [...links, flyerLink];
              });
            }
            resolve(links);
          });
      } else {
        resolve(links);
      }
    });

  const getFloorplanPropertyLink = (property: Property) =>
    new Promise<any>((resolve, reject) => {
      let links: any[] = [];
      const propertyLink = {
        name: `${property.name}`,
        link: `${appRootURL}/${survey.id}/preview/properties/${property.id}`,
      };
      links = [propertyLink];
      if (survey.isIncludeSitePlans) {
        mediaService
          .getMediaFilesForProperty(survey.id, property.id, 'FLOORPLANS')
          .then((response) => {
            if (response && response.media && response.media.length > 0) {
              response.media
                .filter((media: MediaFile) => !media.isHidden)
                .forEach((media: MediaFile, index: number) => {
                  const floorplanLink = {
                    name: 'property floorplan',
                    link: !media.displayName.includes('.pdf')
                      ? `${appRootURL}/${survey.id}/preview/properties/${
                          property.id
                        }/floorPlanImage/${index + 1}`
                      : media.cloudfrontUrl,
                  };
                  links = [...links, floorplanLink];
                });
            }
            resolve(links);
          });
      } else {
        resolve(links);
      }
    });

  const getPropertyLinks = (properties: Property[]) => {
    const promiseCalls: any[] = [];
    properties
      .filter((item) => !item.isHidden && !item.isCurrentLocation)
      .forEach((item, index) => {
        promiseCalls.push(getFloorplanPropertyLink(item));
        promiseCalls.push(getPropertyLink(item));
      });
    return promiseCalls;
  };

  const generateSurveyPDF = async (): Promise<any> => {
    const marketMapIndex = survey.sections.findIndex(
      (item: { name: string }) => item.name === 'market map',
    );
    const propertyComparisonIndex = survey.sections.findIndex(
      (item: { name: string }) => item.name === 'property comparison',
    );
    const propertyIndex = survey.sections.findIndex(
      (item: { name: string }) => item.name === 'properties',
    );
    const tourSchedule = survey.sections.findIndex(
      (item: { name: string }) => item.name === 'tour schedule',
    );
    const hasCustomFields = survey.customFields.length > 0;
    Analytics.send({
      event: 'surveyDownloadClick',
      SurveyID: survey.id,
      SurveyName: survey.name,
      Cover: !survey.sections[0].isHidden,
      BackgroundImage: survey.coverBackgroundImage.displayName,
      SurveyTitle: survey.coverTitle,
      SurveySubtitle: survey.coverSubTitle,
      ClientName: survey.clientName,
      MarketMap: !survey.sections[marketMapIndex].isHidden,
      PropertyComparison: !survey.sections[propertyComparisonIndex].isHidden,
      TourSchedule: !survey.sections[tourSchedule].isHidden,
      Properties: !survey.sections[propertyIndex].isHidden,
      NumberOfProperties: survey.properties.length,
      PropertyCustomFields: hasCustomFields,
      ProjectID: survey.projectId,
      TeamID: survey.teamId,
      SurveyCreationDate: survey.createdOn,
      Origin: survey.origin,
      ProjectCreationDate: survey.projectCreatedAt,
      SiteIQUserID: survey.user ? survey.user.userIdList : [],
      CustomLegends: survey.customLegends,
    });
    const propertySection: SurveySectionPreview = {
      isLandscape: survey.pdfPaperSize === PDFPaperSize.Landscape,
      name: survey.name.replace(/[^a-zA-Z0-9.\-_ ]/g, ''),
      appId: 2,
      sectionInfoList: survey.sections
        .filter((item) => !item.isCustom && !item.isHidden)
        .map((items, index) => ({
          name: items.name,
          link: `${appRootURL}/${survey.id}/preview/${items.name.replace(
            /\s/g,
            '',
          )}`,
        })),
      surveyId: survey.id,
    };
    const propertiesIndex = propertySection.sectionInfoList.findIndex(
      (item: { name: string }) => item.name === 'properties',
    );
    if (propertiesIndex !== -1) {
      let links: any[] = [];
      const chunk1 = propertySection.sectionInfoList.slice(0, propertiesIndex);
      const chunk2 = propertySection.sectionInfoList.slice(
        propertiesIndex + 1,
        propertySection.sectionInfoList.length,
      );
      return Promise.all(getPropertyLinks(survey.properties)).then((items) => {
        if (items && items.length > 0) {
          items.forEach((item) => {
            links = [...links, ...item];
          });
        }
        propertySection.sectionInfoList = [...chunk1, ...links, ...chunk2];
        return mediaService.getSurveyPDFAsync(propertySection);
      });
    }
    return mediaService.getSurveyPDFAsync(propertySection);
  };

  const updateAttributes = (
    rules: BHPropertyFieldRule[],
    patch: BHPropertyFieldRule,
  ) =>
    rules.map((p) =>
      p.fieldKey !== patch.fieldKey
        ? p
        : {
            ...patch,
          },
    );

  return {
    addCustomField,
    addPropertyFiles,
    addSurveyContact,
    addSurveySection,
    deleteCustomField,
    deleteSurveyContact,
    deleteSurveySection,
    reorderSurveyAttribute,
    setSurvey,
    survey,
    resetMarketMap,
    updateCustomField,
    updateSurvey,
    updateSurveyContact,
    updateSurveySection,
    updatePropertyFieldRule,
    generateSurveyPDF,
  };
}
