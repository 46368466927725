import { axios } from '../internal-lib';
import onSuccess from './onSuccess';
import { mediaServiceBaseURL, mediaFileGenerationURL } from '../config';
import {
  MediaFilePatch, MediaFileDelete, SurveySectionPreview, MediaOwner, RegisterMedia,
} from '../types';

const mediaBlobHeader: any = {
  responseType: 'blob',
};

export const getMediaFilesForProperty = (surveyId: string, propertyId: string, mediaType: string) => axios.get<any>(`${mediaServiceBaseURL}${surveyId}/${propertyId}/${mediaType}`).then(onSuccess);

export const getActiveMediaFilesForSurvey = (ids: any) => axios.post<any>(`${mediaServiceBaseURL}getMediaByIds`, ids).then(onSuccess);

export const updatePropertyMediaFile = (mediaFilePatch: MediaFilePatch) => axios.patch<any>(`${mediaServiceBaseURL}`, mediaFilePatch).then(onSuccess);

export const reorderPropertyMediaFile = (mediaFilePatch: MediaFilePatch) => axios.patch<any>(`${mediaServiceBaseURL}reorder`, mediaFilePatch).then(onSuccess);

export const updatePropertyMediaStatus = (mediaFilePatch: MediaFilePatch) => axios.patch<any>(`${mediaServiceBaseURL}status`, mediaFilePatch).then(onSuccess);

export const deletePropertyMediaFile = (mediaFileDelete: MediaFileDelete) => axios.delete<any>(`${mediaServiceBaseURL}`, mediaFileDelete).then(onSuccess);

export const uploadPropertyMediaFile = (mediaFileFormData: FormData, headers: any) => axios.post<any>(`${mediaServiceBaseURL}uploadFile`, mediaFileFormData, headers).then(onSuccess);

export const getSurveyPDF = (surveySectionPreview: SurveySectionPreview) => axios.post<any>(`${mediaServiceBaseURL}processPdf`, surveySectionPreview, mediaBlobHeader).then(onSuccess);

export const getSurveyPDFAsync = (surveySectionPreview: SurveySectionPreview) => axios.post<any>(`${mediaFileGenerationURL}fileGeneration/processPdfAsync`, surveySectionPreview, mediaBlobHeader).then(onSuccess);

export const getPdfUrl = (surveyId: string) => axios .get<any>(`${mediaFileGenerationURL}fileGeneration/latestForSurvey/${surveyId}`).then(onSuccess);

export const getSurveyCoverImage = (mediaType?: string) => axios.get<any>(`${mediaServiceBaseURL}${mediaType}`).then(onSuccess);

export const getSurveyMediaFile = (mediaType?: string, surveyId?: string) => axios.get<any>(`${mediaServiceBaseURL}${surveyId}/${mediaType}/active`).then(onSuccess);

export const exportMarketMap = (payload: any) => axios.post<any>(`${mediaServiceBaseURL}exportMap`, payload).then(onSuccess);

export const reduceMedia = (mediaId: string) => axios.get<any>(`${mediaServiceBaseURL}reducePdf/${mediaId}`).then(onSuccess);

export const invalidateSurveyPdfFiles = (surveyId: string) =>
  axios
    .patch<any>(
      `${mediaFileGenerationURL}fileGeneration/survey/${surveyId}/invalidate`,
    )
    .then(onSuccess);