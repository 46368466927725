/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popOverMenuItem: {
      cursor: 'pointer',
      fontFamily: 'helvetica',
      fontSize: '14px',
      lineHeight: '28px',
      position: 'relative',
      transition: '.2s ease all',
      textAlign: 'left',
      padding: '5px 15px 5px 5px',
      '& img': {
        maxHeight: '20px',
        maxWidth: '20px',
        width: '17px',
        marginRight: '20px',
        marginLeft: '10px',
        position: 'relative',
        top: '5px',
      },
      '&:hover': {
        background: '#00B2DD',
        color: '#FFF',
        '& img': {
          filter: 'brightness(0) invert(1)',
        },
      },
    },
    popOverMenuDiv: {
      background: '#fff',
      color: 'deepskyblue',
      border: '0.5px solid #CCCCCC',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      minWidth: '185px',
    },
    threeDots: {
      position: 'relative',
      top: 0,
      left: 0,
    },
    popOverToggle: {
      transform: 'rotate(-90deg)',
      color: 'deepskyblue',
      fontWeight: 600,
      background: '#fff',
      height: '21px',
      width: '24px',
      borderRadius: '50%',
      paddingBottom: '3px',
      textAlign: 'center',
      fontSize: '14px',
      letterSpacing: '1px',
      cursor: 'pointer',
    },
    popOverWrapperDiv: {
      display: 'flex',
      width: 'auto',
      fontWeight: 100,
      position: 'absolute',
      top: '2px',
      left: '5px',
      zIndex: 1000,
    },
  }),
);
export interface PopOverItem {
  icon: any;
  overIcon?: any;
  name: string;
  clickHandler: Function;
}

export interface PopOverLocation {
  top?: number;
  left?: number;
}

export interface PopOverProps {
  index?: number;
  handlePopUpAction?: boolean;
  inactive?: boolean;
  updatepopover?: any;
  popoverEnabled?: boolean;
  options?: PopOverItem[];
  borderColor?: string;
  location?: PopOverLocation;
}

type Props = PopOverProps & PopOverLocation;

const PopOver: FC<Props> = (props) => {
  // isActive determines whether the popover menu is visible
  const [isActive, setIsActive] = useState(props.popoverEnabled);

  const { index, handlePopUpAction, options } = props;

  const classes = useStyles();

  const togglePopover = () => {
    props.updatepopover(props.index);
  };

  const selectPopOverOption = (option: PopOverItem) => {
    option.clickHandler(
      index !== undefined && index > -1 ? index : option.name,
    );
    if (handlePopUpAction) {
      togglePopover();
    }
  };

  useEffect(() => {
    if (isActive !== props.popoverEnabled) {
      setIsActive(props.popoverEnabled);
    }
  }, [isActive, props.popoverEnabled]);

  return (
    <div className={classes.popOverWrapperDiv} {...props}>
      {handlePopUpAction && (
        <span
          role="none"
          className={classes.popOverToggle}
          onClick={togglePopover}>
          <span className={classes.threeDots}> ... </span>
        </span>
      )}
      {isActive && (
        <div className={classes.popOverMenuDiv}>
          {options &&
            options.map((option: PopOverItem, idx: number) => (
              <p
                role="none"
                className={classes.popOverMenuItem}
                {...option}
                onClick={() => {
                  selectPopOverOption(option);
                }}
                key={`popOverOption${idx}`}>
                {option.icon}
                {!props.inactive && option.name === 'Inactive'
                  ? 'Active'
                  : option.name}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(PopOver);
