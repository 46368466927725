import React, { useEffect, useState } from 'react';
import { Theme, makeStyles, createStyles, Box } from '@material-ui/core';
import { useResizeDetector } from 'react-resize-detector';
import SurveyPDFPage from '../SurveyPDFPage';
import { Property, Survey, SurveyPatch } from '../../types';
import Header from '../PDFCommon';
import * as mapUtil from '../../utilities/map';
import Footer from '../Footer';
import { getSurveyMediaFile } from '../../services/media-service';
import MarketMapLegends from '../MarketMapPreview/MarketMapLegends';
import { PDFPaperSize } from '../../data/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: '100%',
      backgroundColor: '#cccccc',
      height: '0.5in',
      position: 'absolute',
      bottom: '0',
    },
  }),
);

const MarketMapPDFPreview: React.FC<Props> = ({
  survey,
  pageMode,
  index,
  order,
  anchore,
  properties,
  legendHight,
  updateLegendHeight,
}) => {
  const classes = useStyles();
  const [imgUrl, setImgUrl] = useState<string>('');
  const [mapSize, setMapSize] = useState<[number, number]>([0, 0]);
  const [boxSX, setBoxSX] = useState<Object>();
  const { height, ref } = useResizeDetector();

  useEffect(() => {
    if (survey.pdfPaperSize === PDFPaperSize.Landscape) {
      setBoxSX({ display: 'flex' });
    } else if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
      setBoxSX({ flexDirection: 'row' });
    }
    getSurveyMediaFile(
      'MARKETMAP', survey.id,
    ).then((res) => {
      const latestImg = res.media.pop();
      const url = latestImg ? latestImg.cloudfrontUrl : '';
      setImgUrl(url);
    });
  }, [survey]);

  useEffect(() => {
    const curMapSize = mapUtil.default.getMapSize({
      ...survey,
      legendHeight: index === 0 ? height : legendHight,
    });
    setMapSize(curMapSize);
    if (index === 0 && updateLegendHeight) {
      updateLegendHeight(height);
    }
  }, [height, index, legendHight, survey, updateLegendHeight]);

  const mapSizeWidth = () => {
    if (mapSize) {
      return mapSize[0];
    }
    return 0;
  };

  return (
    <div id={`${order}`} style={anchore}>
      <SurveyPDFPage mode={pageMode} paperSize={survey.pdfPaperSize}>
        {survey.isIncludeHeader && <Header title={'Market Map'} />}
        <Box sx={boxSX}>
          {survey.pdfPaperSize === PDFPaperSize.Landscape &&
            <Box
              sx={{ flexGrow: 1 }}
              width={
                mapSize ? 1056 - mapSize[0] : 0
              }
            >
              <MarketMapLegends
                survey={survey}
                properties={properties as Property[]}
              />
            </Box>
          }
          <Box
            sx={{ flexShrink: 1 }}
            width={mapSize ? mapSize[0] : 0}
            height={mapSize ? mapSize[1] : 0}
          >
            {
              imgUrl?.length < 1 ? (
                <h1 style={{ margin: '20% 42%' }}>Loading...</h1>
              ) : (
                <img src={imgUrl} alt='marketmap' width={mapSizeWidth()} />
              )
            }
          </Box>
          {survey.pdfPaperSize === PDFPaperSize.Portrait && survey.isIncludeLegend &&
            <div
              ref={ref}>
              <Box
                sx={{ flexShrink: 1 }}
              >
                <MarketMapLegends
                  survey={survey}
                  properties={properties as Property[]}
                />
              </Box>
            </div>
          }
        </Box>
        <div className={classes.footer}>
          <Footer surveyCountry={survey.surveyCountry}></Footer>
        </div>
      </SurveyPDFPage>
    </div>
  );
};

export type Props = {
  survey: Survey;
  pageMode?: string;
  order: string;
  anchore?: object;
  index?: number;
  updateSurvey: (survey: SurveyPatch) => void;
  setMarketMapEle?: (map: any) => void;
  properties?: Property[];
  legendHight?: number;
  updateLegendHeight?: (number: any) => void;
};

MarketMapPDFPreview.displayName = 'MarketMapPDFPreview';
export default React.memo(MarketMapPDFPreview);
