/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';

import { AVAILABILITY_NAME_KEY, MULTI_FLOOR_SUITE_FIELD, PDFPaperSize, excludedTableFields } from '../../data/constants';
import { DataObject, Property, Survey } from '../../types';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import { getFullAddressStr } from '../../utilities';
import {
  getAvailabilityFieldValues,
  getAvailabilityNameValue,
  getPropertyValueForDisplay,
} from '../../utilities/property';
import GeneratePropertyPDFPreviewPages from './GeneratePropertyPDFPreviewPages';

interface DisplayPropertyDetail {
  detailName: string;
  detailValue: string;
}

interface ExtraInfoBlock {
  heading?: boolean;
  textBlock: string;
}

interface PropertyDetailPage {
  title: string;
  subtitle: string;
  order: string;
  propertyDetails: DisplayPropertyDetail[];
  featurePage: boolean;
  featureImage: string;
  extraInfo: ExtraInfoBlock[]; // this is area for Comments and Amenities
  availabilityHeader?: string;
  surveyId: string;
  propertyId: string;
  remainingRows: number;
  availabilities: any[];
  isCESurvey: boolean;
}

// Data for Visual Representation for all the pages for a Single Property
export interface SinglePropertyPages {
  propertyDetailPages: PropertyDetailPage[];
}

const PropertyPreview: React.FC<Props> = ({
  survey,
  property,
  pageMode,
  propertyOrder,
  order,
  anchore,
  isDrawerOpen,
}) => {
  let attrValue = '-';
  let maxPropertyDetailRowsPerPage = 20;
  let propertyDetailRowsPerFeaturePage = 17;
  let maxCommentRowsFeaturePage = 10;
  let maxCommentRowsExtraPage = 25;
  let maxCharactersPerCommentRow = 80;
  let maxCharsPerPropAttrValueRow = 45;
  let maxCharectersPerProposedSpace = 36;
  let maxCharectersPerOtherAvailabilityColumns = 20;
  let maxAvailabilityRowsPerPage = 17;
  let imageConsumedRows = 11;
  // let headerRows = 1;

  if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
    maxPropertyDetailRowsPerPage = 26;
    maxAvailabilityRowsPerPage = 27;
    propertyDetailRowsPerFeaturePage = 24;
    maxCommentRowsFeaturePage = 18;
    maxCommentRowsExtraPage = 37;
    maxCharactersPerCommentRow = 67;
    maxCharsPerPropAttrValueRow = 30;
    imageConsumedRows = 11;
    maxCharectersPerProposedSpace = 21;
    //    headerRows = 2;
    maxCharectersPerOtherAvailabilityColumns = 12;
  }
  const [propertiesPreview, setPropertiesPreview] = useState<
    SinglePropertyPages[]
  >([]);

  //  findout how many lines are needed for a given text

  const analyzeAttributeText = (textToAnalyze: string, maxChars = maxCharsPerPropAttrValueRow ) => {
    let numberOfLines = 0;
    const lineBreakSplit = textToAnalyze.split('\n');
    lineBreakSplit.forEach((line) => {
        if (line.length < maxChars) {
          numberOfLines += 1;
        } else {
          const numRowsUsedUp = Math.ceil(
            line.length / maxChars,
          );
          numberOfLines += numRowsUsedUp;
        }
    });
    return numberOfLines;
  }

  const pageCommentsSectionHeaderBuilder = (
    propertyPage: PropertyDetailPage,
    currentProperty: Property,
    propertyCommentsRemaining: string,
    propertyAmenitiesRemaining: string,
  ) => {
    if (propertyCommentsRemaining && propertyCommentsRemaining.length > 0) {
      const commentsHeader: ExtraInfoBlock = {
        heading: true,
        textBlock: 'Comments, continued',
      };
      propertyPage.extraInfo.push(commentsHeader);
    } else if (
      propertyAmenitiesRemaining &&
      propertyAmenitiesRemaining.length < currentProperty.amenities.length
    ) {
      const amenitiesHeader: ExtraInfoBlock = {
        heading: true,
        textBlock: 'Amenities, continued',
      };
      propertyPage.extraInfo.push(amenitiesHeader);
    }
  };

  const getRowLayoutForText = (
    textToAnalyze: string,
    rowSpaceRemaining: number,
  ) => {
    const lineBreakSplit = textToAnalyze.split('\n');
    let charSplitCount = 0;
    let remainingRows = rowSpaceRemaining;
    lineBreakSplit.forEach((line) => {
      if (remainingRows > 0) {
        if (line.length < maxCharactersPerCommentRow) {
          remainingRows -= 1;
          charSplitCount += line.length + 1;
        } else {
          const numRowsUsedUp = Math.ceil(
            line.length / maxCharactersPerCommentRow,
          );

          if (rowSpaceRemaining - numRowsUsedUp >= 0) {
            charSplitCount += line.length + 1;
          } else {
            charSplitCount +=
              rowSpaceRemaining * (maxCharactersPerCommentRow + 1);
          }
          remainingRows -= numRowsUsedUp;
        }
      }
    }); // for each line
    return {
      charSplitCount,
      remainingRows,
    };
  };

  const getHeaderRowsCount = (
    rowsRemaining: number,
    fieldsToConsider: BHPropertyFieldRule[],
  ) => {
    let headerRowsLength = 0;
    fieldsToConsider.forEach((field) => {
      const tempRowsLength = Math.ceil(
        field.fieldLabel.length /
          (field.fieldKey === 'availabilityName'
            ? maxCharectersPerProposedSpace
            : maxCharectersPerOtherAvailabilityColumns),
      );

      if (Math.ceil(tempRowsLength) > headerRowsLength) {
        headerRowsLength = tempRowsLength;
      }
    });

    rowsRemaining -= headerRowsLength;
    return rowsRemaining;
  };

  const getAvailabilitiesForPage = (
    availabilities: any[],
    availabilityIndex: number,
    rowsRemaining: number,
    fieldsToConsider: BHPropertyFieldRule[],
    isCESurvey: boolean
  ) => {
    let extraRows = 0;

    while (
      rowsRemaining >= 1 &&
      availabilityIndex <= availabilities.length - 1
    ) {
      const availability: any = availabilities[availabilityIndex];
      let rowsTaken: number;

      const proposedSpace = ( isCESurvey ? getAvailabilityNameValue(availability)?.trim() :
        getAvailabilityFieldValues(AVAILABILITY_NAME_KEY, availability)?.trim())
      
      rowsTaken = analyzeAttributeText(proposedSpace, maxCharectersPerProposedSpace) + 1;
      
      fieldsToConsider.forEach((field) => {
        const tempRowsLength =
          Math.floor(
            (
              getAvailabilityFieldValues(
                field.fieldKey,
                availability,
              )?.trim() || '-'
            ).length / maxCharectersPerOtherAvailabilityColumns,
          ) + 1;

        if (Math.floor(tempRowsLength) > rowsTaken) {
          rowsTaken = tempRowsLength;
        }
      });

      rowsRemaining -= 1;
      extraRows = extraRows + rowsTaken - 1;
      if (rowsRemaining !=1 && rowsRemaining - Math.floor(extraRows * 0.75) > 0) {
        availabilityIndex += 1;
      } else {
        rowsRemaining = 0;
      }
    }
    if (availabilityIndex >= availabilities.length - 1 && rowsRemaining > 0) {
      rowsRemaining -= Math.floor(extraRows * 0.75);
    }
    return { availabilitiesForPage: availabilityIndex, rowsRemaining };
  };
  useEffect(() => {
    if (property && !isDrawerOpen) {
      createDisplayPDFPreviewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    survey.surveyPropertyFieldRule,
    survey.surveyPropertyAvailabilityRule,
    property,
    isDrawerOpen,
  ]);

  async function createDisplayPDFPreviewData() {
    let isCESurvey = false;
    const displayPropertyDetails =
      survey.surveyPropertyFieldRule?.filter(
        (rule: any) =>
          rule.status === 'ACTIVE' &&
          !excludedTableFields.includes(rule.fieldLabel),
      ) || [];

    const displayAvailabilityDetails =
      survey.surveyPropertyAvailabilityRule
        ?.filter(
          (rule: any) => {
            if (MULTI_FLOOR_SUITE_FIELD === rule.fieldKey && rule.status === 'ACTIVE') {
              isCESurvey = true;
            }
          return   rule.status === 'ACTIVE' && rule.fieldKey !== AVAILABILITY_NAME_KEY;
          },
        )
        .sort((first: any, second: any) => first.order - second.order) || [];
    
    

    const isCommentVisible = survey.surveyPropertyFieldRule?.find(
      (rule: any) => rule.status === 'ACTIVE' && rule.fieldKey === 'comments',
    );

    const isAmenitiesVisible = survey.surveyPropertyFieldRule?.find(
      (rule: any) => rule.status === 'ACTIVE' && rule.fieldKey === 'amenities',
    );

    let propertyCommentsRemaining = isCommentVisible ? property.comments : '';
    let propertyAmenitiesRemaining = isAmenitiesVisible
      ? property.amenities
      : '';

    // get field values of availabilities

    // calculate total number of lines due to text wrapping
    const getPropertyFieldValue = (pfIdx: number) => {
      const attrKey = displayPropertyDetails[pfIdx].fieldKey;
      type TempType = Property & { [x: string]: any };
      const data = property as TempType;
      const { customFields } = data;
      let customField = null;
      if (Array.isArray(customFields) && customFields.length > 0) {
        customField = customFields.find((field) => field.fieldKey === attrKey);
      }
      if (customField) {
        attrValue = customField?.value || '-';
      } else {
        attrValue =
          getPropertyValueForDisplay(attrKey, data) ||
          getPropertyValueForDisplay(attrKey, data.data as DataObject) ||
          '-';
      }
      if (!attrValue) {
        attrValue = '';
      }
      return attrValue;
    };

    const totalLinesforPropertyDetails =
      displayPropertyDetails?.map(
        (detail, index) =>
          analyzeAttributeText(
            getPropertyFieldValue(index),
            maxCharsPerPropAttrValueRow
          ),
      ) || [];

    const buildDetails = () => {
      const allPropertyPages: SinglePropertyPages[] = [];
      const propertyPages: SinglePropertyPages = {
        propertyDetailPages: [],
      };

      return new Promise((resolve, reject) => {
        // adjust rows per page based on total lines data
        let linesUsedUp = 0;
        const rowsForPageNumber: number[] = [];
        const linesConsumed: number[] = []; // there is now a dynamic number of rows per page to accomodate wrapped lines
        let lineStartForThisPage = 0;
        let numPages = 0;

        for (
          let idxLines = 0;
          idxLines <= totalLinesforPropertyDetails.length;
          idxLines += 1
        ) {
          const maxLinesForThisPage =
            numPages > 0
              ? maxPropertyDetailRowsPerPage
              : propertyDetailRowsPerFeaturePage;

          let linesToAdd = totalLinesforPropertyDetails[idxLines] || 0;
          // this adjustment is done because multi-line rows do not take up the space of
          // equivalent number of single line rows
          if (linesToAdd > 1) {
            linesToAdd = Math.floor(linesToAdd * 0.75);
          }

          linesUsedUp += linesToAdd;

          if (linesUsedUp >= maxLinesForThisPage) {
            rowsForPageNumber.push(idxLines - lineStartForThisPage); // sets the number of rows for this page
            lineStartForThisPage = idxLines;
            linesConsumed.push(linesUsedUp);
            linesUsedUp = 0;
            numPages += 1;
          } else if (idxLines === totalLinesforPropertyDetails.length) {
            // last line, put any newly accrued rows on new page
            if (idxLines > lineStartForThisPage) {
              rowsForPageNumber.push(idxLines - lineStartForThisPage);
              linesConsumed.push(linesUsedUp);
              numPages += 1;
            }
          }
        }

        let propertyDetailIndex = 0;
        const addressDisplay: string = getFullAddressStr(
          property.address1,
          property.city,
          property.state,
          property.postalCode,
        );

        for (
          let iNumPropertyDetailPages = 0;
          iNumPropertyDetailPages < numPages;
          iNumPropertyDetailPages += 1
        ) {
          const propertyPage: PropertyDetailPage = {
            subtitle: addressDisplay || '',
            title: property.name,
            // eslint-disable-next-line no-nested-ternary
            order: propertyOrder,
            propertyDetails: [],
            featurePage: false,
            featureImage: '',
            extraInfo: [],
            surveyId: property.surveyId,
            remainingRows: 0,
            propertyId: property.id,
            availabilities: [],
            isCESurvey: isCESurvey,
          };
          const numRowsPerPage = rowsForPageNumber[iNumPropertyDetailPages];
          const consumedLines = linesConsumed[iNumPropertyDetailPages];
          let rowSpaceRemaining = maxCommentRowsExtraPage;

          // First Page for a Property Only
          if (iNumPropertyDetailPages === 0) {
            propertyPage.featurePage = true;
            rowSpaceRemaining = maxCommentRowsFeaturePage;
            // Comments/Amenities
            if (propertyCommentsRemaining) {
              const commentsHeader: ExtraInfoBlock = {
                heading: true,
                textBlock: 'Comments',
              };
              propertyPage.extraInfo.push(commentsHeader);
            }
          } else {
            // Comments/Amenities - extra Page CommentSection Header
            pageCommentsSectionHeaderBuilder(
              propertyPage,
              property,
              propertyCommentsRemaining,
              propertyAmenitiesRemaining,
            );
          }

          if (propertyCommentsRemaining) {
            // analyze and adjust initialSplit for line breaks
            let charSplitCount = 0;
            const textAnalysis = getRowLayoutForText(
              propertyCommentsRemaining,
              rowSpaceRemaining,
            );
            charSplitCount = textAnalysis.charSplitCount;
            rowSpaceRemaining = textAnalysis.remainingRows;

            if (propertyCommentsRemaining.length > charSplitCount) {
              // get first space after block limit
              let splitIdx = propertyCommentsRemaining.indexOf(
                ' ',
                charSplitCount,
              );
              // if there are no spaces or the last line before split is blank
              if (
                splitIdx === -1 ||
                propertyAmenitiesRemaining.charAt(charSplitCount - 1) === '\n'
              ) {
                splitIdx = charSplitCount;
              } else if (
                charSplitCount + maxCharactersPerCommentRow <
                propertyCommentsRemaining.length
              ) {
                // break on the last space on the last line.
                const lastIdxOfSpaceOnLine = propertyCommentsRemaining
                  .substring(0, splitIdx + maxCharactersPerCommentRow)
                  .lastIndexOf(' ');
                splitIdx = lastIdxOfSpaceOnLine;
              }

              const pageComments: ExtraInfoBlock = {
                heading: false,
                textBlock: propertyCommentsRemaining.substring(0, splitIdx),
              };
              propertyPage.extraInfo.push(pageComments);
              propertyCommentsRemaining =
                propertyCommentsRemaining.substring(splitIdx);
            } else {
              const pageComments: ExtraInfoBlock = {
                heading: false,
                textBlock: propertyCommentsRemaining,
              };
              propertyPage.extraInfo.push(pageComments);
              propertyCommentsRemaining = '';
            }
          }

          if (rowSpaceRemaining > 3) {
            // room for Amenities
            if (propertyAmenitiesRemaining) {
              if (
                propertyAmenitiesRemaining.length === property.amenities.length
              ) {
                const amenitiesHeader: ExtraInfoBlock = {
                  heading: true,
                  textBlock: 'Amenities',
                };
                propertyPage.extraInfo.push(amenitiesHeader);
              }

              let charSplitCount = 0;
              const textAnalysis = getRowLayoutForText(
                propertyAmenitiesRemaining,
                rowSpaceRemaining - 1,
              ); // adjusted because of Amenities header
              charSplitCount = textAnalysis.charSplitCount;
              rowSpaceRemaining = textAnalysis.remainingRows;

              if (propertyAmenitiesRemaining.length > charSplitCount) {
                // get space after block limit
                let splitIdx = propertyAmenitiesRemaining.indexOf(
                  ' ',
                  charSplitCount,
                );
                // if there are no spaces or the last line before split is blank
                if (
                  splitIdx === -1 ||
                  propertyAmenitiesRemaining.charAt(charSplitCount - 1) === '\n'
                ) {
                  splitIdx = charSplitCount;
                } else if (
                  charSplitCount + maxCharactersPerCommentRow <
                  propertyAmenitiesRemaining.length
                ) {
                  // break on the last space on the last line.
                  const lastIdxOfSpaceOnLine = propertyAmenitiesRemaining
                    .substring(0, splitIdx + maxCharactersPerCommentRow)
                    .lastIndexOf(' ');
                  splitIdx = lastIdxOfSpaceOnLine;
                }
                const pageAmenities: ExtraInfoBlock = {
                  heading: false,
                  textBlock: propertyAmenitiesRemaining.substring(0, splitIdx),
                };
                propertyPage.extraInfo.push(pageAmenities);
                propertyAmenitiesRemaining =
                  propertyAmenitiesRemaining.substring(splitIdx);
              } else {
                const pageAmenities: ExtraInfoBlock = {
                  heading: false,
                  textBlock: propertyAmenitiesRemaining,
                };
                propertyPage.extraInfo.push(pageAmenities);
                propertyAmenitiesRemaining = '';
              }
            }
          }

          // build property detail rows for this property
          for (
            let iPagePropertyDetailRow = 0;
            iPagePropertyDetailRow < numRowsPerPage;
            iPagePropertyDetailRow += 1
          ) {
            if (propertyDetailIndex < displayPropertyDetails.length) {
              const attrKey =
                displayPropertyDetails[propertyDetailIndex].fieldKey;
              type TempType = Property & { [x: string]: any };
              const data = property as TempType;
              const { customFields } = data;
              let customField = null;
              if (Array.isArray(customFields) && customFields.length > 0) {
                customField = customFields.find(
                  (field) => field.fieldKey === attrKey,
                );
              }
              if (customField) {
                attrValue = customField?.value || '-';
              } else {
                attrValue =
                  getPropertyValueForDisplay(attrKey, data) ||
                  getPropertyValueForDisplay(
                    attrKey,
                    data.data as DataObject,
                  ) ||
                  '-';
              }
              const propertyDetail: DisplayPropertyDetail = {
                detailName:
                  displayPropertyDetails[propertyDetailIndex].fieldLabel,
                detailValue: attrValue,
              };
              propertyPage.propertyDetails.push(propertyDetail);
              propertyDetailIndex += 1;
            }
          }

          const limit = propertyPage.featurePage
            ? propertyDetailRowsPerFeaturePage
            : maxAvailabilityRowsPerPage;

          if (
            !propertyPage.featurePage &&
            rowSpaceRemaining + consumedLines > limit
          ) {
            if (rowSpaceRemaining === maxCommentRowsExtraPage) {
              propertyPage.remainingRows = limit - consumedLines;
            } else if (
              Math.floor(rowSpaceRemaining * 0.75) >
              limit - consumedLines
            ) {
              propertyPage.remainingRows = limit - consumedLines;
            } else {
              propertyPage.remainingRows = Math.floor(rowSpaceRemaining * 0.75);
            }
          } else {
            propertyPage.remainingRows =
              Math.floor(rowSpaceRemaining * 0.75) > limit - consumedLines
                ? limit - consumedLines
                : Math.floor(rowSpaceRemaining * 0.75);
          }
          // propertyPage.remainingRows = Math.floor(rowSpaceRemaining * 0.75);
          propertyPages.propertyDetailPages.push(propertyPage);
        }

        while (
          (propertyCommentsRemaining && propertyCommentsRemaining.length > 0) ||
          (propertyAmenitiesRemaining && propertyAmenitiesRemaining.length > 0)
        ) {
          // additional pages still needed.
          const propertyPage: PropertyDetailPage = {
            subtitle: addressDisplay || '',
            title: property.name,
            order: propertyOrder,
            propertyDetails: [],
            featurePage: false,
            featureImage: '',
            extraInfo: [],
            availabilities: [],
            surveyId: property.surveyId,
            remainingRows: 0,
            propertyId: property.id,
            isCESurvey: isCESurvey,
          };

          let rowSpaceRemaining = maxCommentRowsExtraPage;

          // extra page Comment Section Header
          pageCommentsSectionHeaderBuilder(
            propertyPage,
            property,
            propertyCommentsRemaining,
            propertyAmenitiesRemaining,
          );

          if (propertyCommentsRemaining) {
            let charSplitCount = 0;
            const textAnalysis = getRowLayoutForText(
              propertyCommentsRemaining,
              rowSpaceRemaining,
            );
            charSplitCount = textAnalysis.charSplitCount;
            rowSpaceRemaining = textAnalysis.remainingRows;

            if (propertyCommentsRemaining.length > charSplitCount) {
              // get first space after block limit
              let splitIdx = propertyCommentsRemaining.indexOf(
                ' ',
                charSplitCount,
              );

              // if there are no spaces or the last line before split is blank
              if (
                splitIdx === -1 ||
                propertyAmenitiesRemaining.charAt(charSplitCount - 1) === '\n'
              ) {
                splitIdx = charSplitCount;
              } else if (
                charSplitCount + maxCharactersPerCommentRow <
                propertyCommentsRemaining.length
              ) {
                // break on the last space on the last line.
                const lastIdxOfSpaceOnLine = propertyCommentsRemaining
                  .substring(0, splitIdx + maxCharactersPerCommentRow)
                  .lastIndexOf(' ');
                splitIdx = lastIdxOfSpaceOnLine;
              }
              const pageComments: ExtraInfoBlock = {
                heading: false,
                textBlock: propertyCommentsRemaining.substring(0, splitIdx),
              };
              propertyPage.extraInfo.push(pageComments);
              propertyCommentsRemaining =
                propertyCommentsRemaining.substring(splitIdx);
            } else {
              const pageComments: ExtraInfoBlock = {
                heading: false,
                textBlock: propertyCommentsRemaining,
              };
              propertyPage.extraInfo.push(pageComments);
              propertyCommentsRemaining = '';
            }
          }

          if (rowSpaceRemaining > 3) {
            if (propertyAmenitiesRemaining) {
              let charSplitCount = 0;
              const textAnalysis = getRowLayoutForText(
                propertyAmenitiesRemaining,
                rowSpaceRemaining - 1,
              ); // adjusted because of Amenities header
              charSplitCount = textAnalysis.charSplitCount;
              rowSpaceRemaining = textAnalysis.remainingRows;

              if (
                propertyAmenitiesRemaining.length === property.amenities.length
              ) {
                const amenitiesHeader: ExtraInfoBlock = {
                  heading: true,
                  textBlock: 'Amenities',
                };
                propertyPage.extraInfo.push(amenitiesHeader);
              }

              if (propertyAmenitiesRemaining.length > charSplitCount) {
                // get first space after block limit
                let splitIdx = propertyAmenitiesRemaining.indexOf(
                  ' ',
                  charSplitCount,
                );
                // if there are no spaces or the last line before split is blank
                if (
                  splitIdx === -1 ||
                  propertyAmenitiesRemaining.charAt(charSplitCount - 1) === '\n'
                ) {
                  splitIdx = charSplitCount;
                } else if (
                  charSplitCount + maxCharactersPerCommentRow <
                  propertyAmenitiesRemaining.length
                ) {
                  // break on the last space on the last line.
                  const lastIdxOfSpaceOnLine = propertyAmenitiesRemaining
                    .substring(0, splitIdx + maxCharactersPerCommentRow)
                    .lastIndexOf(' ');
                  splitIdx = lastIdxOfSpaceOnLine;
                }

                const pageAmenities: ExtraInfoBlock = {
                  heading: false,
                  textBlock: propertyAmenitiesRemaining.substring(0, splitIdx),
                };
                propertyPage.extraInfo.push(pageAmenities);
                propertyAmenitiesRemaining =
                  propertyAmenitiesRemaining.substring(splitIdx);
              } else {
                const pageAmenities: ExtraInfoBlock = {
                  heading: false,
                  textBlock: propertyAmenitiesRemaining,
                };
                propertyPage.extraInfo.push(pageAmenities);
                propertyAmenitiesRemaining = '';
              }
            }
          }
          propertyPage.remainingRows =
            maxAvailabilityRowsPerPage -
            Math.floor((maxCommentRowsExtraPage - rowSpaceRemaining) * 0.7) -
            1;
          propertyPages.propertyDetailPages.push(propertyPage);
        }

        /**
         * this is to build availabilities first level attributes saying first six
         */
        let lastPage = propertyPages.propertyDetailPages.slice(-1)[0];

        let availabilitiesIndex = 0;

        let fieldsIndex = 0;
        const remainingAvailabilities = [
          ...property.availabilities
            .filter((availability: any) => availability.status === 'ACTIVE')
            .sort((first: any, second: any) => first.order - second.order),
        ];

        // availabilities page builder
        const buildAvailabilities = (
          fieldsToConsider: any[],
          firstPage: boolean,
        ) => {
          while (availabilitiesIndex < remainingAvailabilities.length) {
            const { availabilitiesForPage, rowsRemaining } =
              getAvailabilitiesForPage(
                remainingAvailabilities,
                availabilitiesIndex,
                getHeaderRowsCount(
                  maxAvailabilityRowsPerPage,
                  fieldsToConsider,
                ) - 1,
                fieldsToConsider,
                isCESurvey
              );

            const propertyPage: PropertyDetailPage = {
              subtitle: '',
              title: '',
              order: '',
              propertyDetails: [],
              featurePage: false,
              featureImage: '',
              extraInfo: [],
              availabilityHeader: firstPage
                ? 'Availabilities'
                : 'Availabilities, Continued',
              availabilities: [
                {
                  availabiliites: remainingAvailabilities.slice(
                    availabilitiesIndex,
                    availabilitiesForPage,
                  ),
                  fields: fieldsToConsider,
                },
              ],
              surveyId: property.surveyId,
              remainingRows: rowsRemaining,
              propertyId: property.id,
              isCESurvey: isCESurvey,
            };
            firstPage = false;
            availabilitiesIndex = availabilitiesForPage;
            propertyPages.propertyDetailPages.push(propertyPage);
            lastPage = propertyPage;
          }
        };

        /**
         * here the logic begin to add availabilities and availabilities attributes to the pages
         */

        if (remainingAvailabilities.length > 0) {
          /**
           * this is to show availabilities first level attributes like first six
           */

          if (lastPage.featurePage) {
            const usedRows =
              propertyDetailRowsPerFeaturePage - lastPage.remainingRows;
            lastPage.remainingRows =
              usedRows > imageConsumedRows
                ? lastPage.remainingRows
                : propertyDetailRowsPerFeaturePage - imageConsumedRows;
          }
          lastPage.remainingRows =
            getHeaderRowsCount(
              lastPage.remainingRows,
              displayAvailabilityDetails.slice(0, 6),
            ) - (lastPage.featurePage ? 2 : 1);
          if (lastPage.remainingRows >= 2) {
            const { availabilitiesForPage, rowsRemaining } =
              getAvailabilitiesForPage(
                remainingAvailabilities,
                availabilitiesIndex,
                lastPage.remainingRows,
                displayAvailabilityDetails.slice(0, 6),
                isCESurvey
              );
            if (availabilitiesForPage !== 0) {
              lastPage.availabilities.push({
                availabiliites: remainingAvailabilities.slice(
                  availabilitiesIndex,
                  availabilitiesForPage,
                ),
                fields: displayAvailabilityDetails.slice(0, 6),
              });
              availabilitiesIndex = availabilitiesForPage;

              lastPage.availabilityHeader = 'Availabilities';
              lastPage.remainingRows = rowsRemaining;
            } 

            while (availabilitiesIndex < remainingAvailabilities.length) {
              buildAvailabilities(
                displayAvailabilityDetails.slice(0, 6),
                availabilitiesForPage == 0,
              );
            }
          } else {
            buildAvailabilities(displayAvailabilityDetails.slice(0, 6), true);
          }

          /**
           * this is to show extra attributes of availabilities
           */
          // eslint-disable-next-line prefer-destructuring
          lastPage = propertyPages.propertyDetailPages.slice(-1)[0];

          fieldsIndex = 6;

          if (
            lastPage.remainingRows > 3 &&
            fieldsIndex <= displayAvailabilityDetails.length - 1
          ) {
            while (fieldsIndex <= displayAvailabilityDetails.length - 1) {
              availabilitiesIndex = 0;
              if (
                getHeaderRowsCount(
                  lastPage.remainingRows,
                  displayAvailabilityDetails.slice(
                    fieldsIndex,
                    fieldsIndex + 6,
                  ),
                ) < 2
              ) {
                const propertyPage: PropertyDetailPage = {
                  subtitle: '',
                  title: '',
                  order: '',
                  propertyDetails: [],
                  featurePage: false,
                  featureImage: '',
                  extraInfo: [],
                  availabilityHeader: 'Availabilities, Continued',
                  availabilities: [],
                  surveyId: property.surveyId,
                  remainingRows: maxAvailabilityRowsPerPage,
                  propertyId: property.id,
                  isCESurvey: isCESurvey,
                };
                propertyPages.propertyDetailPages.push(propertyPage);
                lastPage = propertyPage;
              }

              const { availabilitiesForPage, rowsRemaining } =
                getAvailabilitiesForPage(
                  remainingAvailabilities,
                  availabilitiesIndex,
                  getHeaderRowsCount(
                    lastPage.remainingRows,
                    displayAvailabilityDetails.slice(
                      fieldsIndex,
                      fieldsIndex + 6,
                    ),
                  ),
                  displayAvailabilityDetails.slice(
                    fieldsIndex,
                    fieldsIndex + 6,
                  ),
                  isCESurvey
                );

              lastPage.availabilities.push({
                availabiliites: remainingAvailabilities.slice(
                  availabilitiesIndex,
                  availabilitiesForPage,
                ),
                fields: displayAvailabilityDetails.slice(
                  fieldsIndex,
                  fieldsIndex + 6,
                ),
              });
              availabilitiesIndex = availabilitiesForPage;
              lastPage.remainingRows = rowsRemaining;

              buildAvailabilities(
                displayAvailabilityDetails.slice(fieldsIndex, fieldsIndex + 6),
                false,
              );

              fieldsIndex += 6;
            }
          } else if (fieldsIndex <= displayAvailabilityDetails.length - 1) {
            const propertyPage: PropertyDetailPage = {
              subtitle: '',
              title: '',
              order: '',
              propertyDetails: [],
              featurePage: false,
              featureImage: '',
              extraInfo: [],
              availabilityHeader: 'Availabilities, Continued',
              availabilities: [],
              surveyId: property.surveyId,
              remainingRows: maxAvailabilityRowsPerPage,
              propertyId: property.id,
              isCESurvey: isCESurvey,
            };
            propertyPages.propertyDetailPages.push(propertyPage);
            lastPage = propertyPage;

            availabilitiesIndex = 0;

            while (fieldsIndex <= displayAvailabilityDetails.length - 1) {
              availabilitiesIndex = 0;
              const { availabilitiesForPage, rowsRemaining } =
                getAvailabilitiesForPage(
                  remainingAvailabilities,
                  availabilitiesIndex,
                  getHeaderRowsCount(
                    lastPage.remainingRows,
                    displayAvailabilityDetails.slice(
                      fieldsIndex,
                      fieldsIndex + 6,
                    ),
                  ),
                  displayAvailabilityDetails.slice(
                    fieldsIndex,
                    fieldsIndex + 6,
                  ),
                  isCESurvey
                );

              lastPage.availabilities.push({
                availabiliites: remainingAvailabilities.slice(
                  availabilitiesIndex,
                  availabilitiesForPage,
                ),
                fields: displayAvailabilityDetails.slice(
                  fieldsIndex,
                  fieldsIndex + 6,
                ),
              });

              availabilitiesIndex = availabilitiesForPage;
              lastPage.remainingRows = rowsRemaining;
              buildAvailabilities(
                displayAvailabilityDetails.slice(fieldsIndex, fieldsIndex + 6),
                false,
              );

              fieldsIndex += 6;
            }
          }
        }
        allPropertyPages.push(propertyPages);

        // }

        resolve(allPropertyPages);
      });
    };
    const allPropertyPages = await buildDetails();
    setPropertiesPreview(allPropertyPages as any);
  }

  return (
    <>
      {propertiesPreview.length > 0 &&
        propertiesPreview.map((propertyPrev, index) => (
          <GeneratePropertyPDFPreviewPages
            key={`${propertyPrev?.propertyDetailPages[0]?.propertyId}-preview-${index}`}
            propertyPrev={propertyPrev}
            propertyIndex={index}
            survey={survey}
            anchore={anchore}
            pageMode={pageMode}
            order={order}
          />
        ))}
    </>
  );
};

export type Props = {
  survey: Survey;
  property: Property;
  pageMode?: string;
  propertyOrder: string;
  order: string;
  anchore?: object;
  isDrawerOpen: boolean;
};

PropertyPreview.displayName = 'PropertyPreview';
export default React.memo(PropertyPreview);
