/*eslint-disable*/
import { isFinite } from 'lodash';
import { DataObject, Property } from '../../types';
import { AVAILABILITY_NAME_KEY } from '../../data/constants';

/// //////// sub utility ///////////
function checkValidNumber(value: string) {
  const fvalue = parseFloat(value);
  if (!isNaN(fvalue) && isFinite(fvalue)) {
    return true;
  }
  return false;
}

function getDecorator(attrKey: string) {
  let prefix = '';
  let suffix = '';
  let possibleInteger = true;

  if (['askingRent', 'askingPrice'].includes(attrKey)) {
    prefix = '$';
    suffix = '/SF';
    possibleInteger = false;
  } else if (
    [
      'minTotalAvailableSpace',
      'maxTotalAvailableSpace',
      'totalAvailableSpace',
      'propertySize',
      'netRentableArea',
      'typicalFloorSize',
      'vacantSpace',
    ].includes(attrKey)
  ) {
    suffix = ' SF';
  } else if (['vacantPercentage'].includes(attrKey)) {
    suffix = '%';
    possibleInteger = false;
  } else if (['opex'].includes(attrKey)) {
    prefix = '$';
    possibleInteger = false;
  } else {
    return;
  }

  return {
    prefix,
    suffix,
    possibleInteger,
    fractionDigits: possibleInteger ? 1 : 2,
  };
}

function refineNumberString(
  value: string,
  possibleInteger: boolean,
  fractionDigits: number,
): string {
  const fvalue = parseFloat(value);

  if (possibleInteger) {
    const nvalue = parseInt(value, 10);
    if (fvalue === nvalue) {
      return nvalue.toLocaleString('en-US');
    }
  }

  const localeStringOpts = {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };
  return fvalue.toLocaleString('en-US', localeStringOpts);
}

export function getValidNumberString(
  _value: string,
  possibleInteger: boolean,
  fractionDigits: number,
): string {
  const value = (_value || '').trim();

  const others = value.match(/[^0-9.,]/g) || [];
  if (others.length > 0) {
    return '';
  }

  const svalue = value.replace(/[^0-9.,]/g, '');
  if (!checkValidNumber(svalue)) {
    return '';
  }
  return refineNumberString(svalue, possibleInteger, fractionDigits);
}

const getSingletonValue = (attrKey: string, value: string) => {
  const decorator = getDecorator(attrKey);
  if (!decorator) {
    return value;
  }
  const { prefix, suffix, possibleInteger, fractionDigits } = decorator;

  if (value) {
    // value = value ? value?.replace(/,/g, '') : '';
    const svalue = getValidNumberString(
      value ? value?.replace(/,/g, '') : '',
      possibleInteger,
      fractionDigits,
    );
    if (svalue) {
      return `${prefix}${svalue}${suffix}`;
    }
    return value;
  }

  return '';
};

export const getCommas = (data: string, isPossibleFloat?: boolean) => {
  const options = {
    minimumFractionDigits: isPossibleFloat ? 2 : 0,
    maximumFractionDigits: isPossibleFloat ? 2 : 0,
  };
  if (data?.trim() && data.match(/^[0-9]+$/) != null) {
    return parseFloat(data)?.toLocaleString('en-US', options);
  }
  return data || '';
};

export const getCommasAndSymbol = (data: string) => {
  if (data?.trim() && data.match(/^[0-9.]+$/) != null) {
    return `$${parseFloat(data)?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
  return data || '';
};

export function getAreaValueWithSF(value: any, unit: string) {
  return !isNaN(value?.trim()?.replace(/,/g, ''))
    ? `${getCommas(value)?.trim()} ${value?.trim() ? unit || 'SF' : ''}`
    : value?.trim() || '';
}

export function getAvailableSFValue(min: any, max: any) {
  return (
    (getCommas(min)?.trim() || '') +
    (min && max ? ' - ' : '') +
    (max ? getCommas(max)?.trim() : '') +
    ((!isNaN(min?.trim()?.replace(/,/g, '')) || max?.trim()) &&
    (min?.trim() || max?.trim())
      ? ' SF'
      : '')
  );
}

export function getAskingPriceValue(min: any, max: any, units: string) {
  return units?.trim() && units?.trim() === '$'
    ? (min?.trim() ? getCommasAndSymbol(min?.trim()) : '') +
        (min?.trim() && max?.trim() ? ' - ' : '') +
        (max?.trim() ? `${getCommasAndSymbol(max?.trim())} ` : '')
    : (min?.trim() && units?.trim()
        ? getCommasAndSymbol(min?.trim())
        : getCommas(min?.trim(), true)) +
        (min?.trim() && max?.trim() ? ' - ' : '') +
        (max?.trim() && units?.trim()
          ? getCommasAndSymbol(max?.trim())
          : getCommas(max?.trim(), true)) +
        ((!isNaN(min?.trim()) || max?.trim()) && units?.trim() ? '/SF' : '');
}

export function getAskingRentValue(
  min: any,
  max: any,
  services: string,
  units: string,
) {
  return (
    (min?.trim() && units?.trim()
      ? getCommasAndSymbol(min?.trim())
      : getCommas(min?.trim(), true)) +
    (min?.trim() && max?.trim() ? ' - ' : '') +
    (max?.trim() && units?.trim()
      ? getCommasAndSymbol(max?.trim())
      : getCommas(max?.trim(), true)) +
    ((min?.trim() || max?.trim()) && units?.trim() ? ` ${units} ` : '') +
    ((min?.trim() || max?.trim()) && services?.trim() ? ` ${services}` : '')
  );
}

export function getTotalAvailableValue(
  min: string,
  max: string,
  value: string,
) {
  const attrKey = 'totalAvailableSpace';

  let svalue = getSingletonValue(attrKey, value);
  if (svalue) {
    return svalue;
  }

  // formula based generation
  const decorator = getDecorator(attrKey)!;
  const { prefix, suffix, possibleInteger, fractionDigits } = decorator;
  min = min ? min?.replace(/,/g, '') : '';
  max = max ? max?.replace(/,/g, '') : '';

  const smin = getValidNumberString(min, possibleInteger, fractionDigits);
  const smax = getValidNumberString(max, possibleInteger, fractionDigits);

  svalue = '';

  if (smin) {
    if (smax) {
      svalue = `${smin} - ${smax}`;
    } else {
      svalue = `${smin}`;
    }
  } else if (smax) {
    svalue = `${smax}`;
  }

  if (svalue) {
    return `${prefix}${svalue}${suffix}`;
  }
  return '';
}

export function getLocaleStringForValue(
  value: string,
  possibleInteger: boolean,
  fractionDigits: number,
): string {
  const tempVal = value ? value?.replace(/,/g, '') : '';
  const refinedString = getValidNumberString(
    tempVal,
    possibleInteger,
    fractionDigits,
  );
  return refinedString || value;
}


/// ////////
export function getPropertyValueForDisplay(
  attrKey: string,
  property: DataObject,
) {
  if (!property) return '';
  const value = property[attrKey];
  return value;
}

export function getPropertyValueForDisplay2(
  attrKey: string,
  property: Property | DataObject,
) {
  return getPropertyValueForDisplay(attrKey, property);
}

export function getAvailabilityNameValue(availability: any) {
    return availability[AVAILABILITY_NAME_KEY]?.replace(/, /g, ',\r\n') || '-';
}

export function getAvailabilityFieldValues(
  fieldKey: string,
  availability: any,
) {
  return availability[fieldKey] || availability.data?.[fieldKey] || '-';
}

export function addNewLine(value: string) {
  if (!value) return '';
  const match: any = value.match(/, \d/);
  if (match && match.index !== -1 && value.endsWith('SF')) {
    return `${value.substring(0, match.index + 1)}\r\n${value.substring(match.index + 1, value.length)}`;
  }
  return value;
}

export function dateFormatter(data: any) {
  if (data?.includes('T') && data?.includes('Z')) {
    const Value = data?.slice(0, data.indexOf('T'));
    const dateParts = Value?.split('-');
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    const dateFormat = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    data = dateFormat;
    return data;
  }

  // return strings those are not date strings
  return data;
}
