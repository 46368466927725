import { debounce } from 'lodash';
import { MARKETMAP_ACTION } from '../types/actionTypes';
import { MarketMapAction, MarketMapState } from '../types/dataTypes';
import * as mapUtil from '../../utilities/map';
import { isEmptyObjectFromStr } from '../../utilities';

const initialMarketMapState = {} as MarketMapState;

// BH Update: disable the auto map image generation.
const processMapImgHandler = debounce(mapUtil.default.processMapImg, 2000);

// const processMapImgHandler = debounce((mapState: any) => {}, 2000);
const marketMapReducer = (
  state: MarketMapState = initialMarketMapState,
  action: MarketMapAction,
) => {
  switch (action.type) {
    case MARKETMAP_ACTION.SET_MARKETMAP: {
      const newMapState = {
        ...state,
        ...action.marketMapState,
      };
      const mapSize = mapUtil.default.getMapSize(newMapState);
      const mapCount = mapUtil.default.getMapCount(
        newMapState.isIncludeLegend,
        newMapState.isIncludeHeader,
        newMapState.properties,
      );
      [...Array(mapCount)].forEach((_, i) => {
        mapUtil.default.resizeMap('marketmap', i, mapSize).then(() => {
          const mapEle = mapUtil.default.getMapInstance('marketmap', i);
          if (mapEle && mapEle.zoomToFit) {
            mapEle.zoomToFit().then(() => {
              if (i === 0) {
                mapUtil.default
                  .getMapState('marketmap', i)
                  .then((mapData: any) => {
                    if (mapData.mapCentroid || mapData.mapZoomLevel) {
                      const mapState = {
                        ...state,
                        marketMapBamData: JSON.stringify(mapData),
                      };
                      processMapImgHandler(mapState);
                    }
                  });
              }
            });
          }
        });
      });

      return {
        ...newMapState,
        mapSize,
        mapCount,
      };
    }
    case MARKETMAP_ACTION.RESET_MARKETMAP: {
      return {
        ...state,
      };
    }
    case MARKETMAP_ACTION.UPDATE_PDF_PAPER_SIZE: {
      const newMapState = {
        ...state,
        ...action.marketMapState,
        mapLoaded: false,
      };
      const mapSize = mapUtil.default.getMapSize(newMapState);
      const mapCount = mapUtil.default.getMapCount(
        newMapState.isIncludeLegend,
        newMapState.isIncludeHeader,
        newMapState.properties,
      );
      [...Array(mapCount)].forEach((_, i) => {
        mapUtil.default.resizeMap('marketmap', i, mapSize).then(() => {
          const mapEle = mapUtil.default.getMapInstance('marketmap', i);
          if (mapEle && mapEle.zoomToFit) {
            mapEle.zoomToFit().then(() => {
              if (i === 0) {
                mapUtil.default
                  .getMapState('marketmap', i)
                  .then((mapData: any) => {
                    if (mapData.mapCentroid || mapData.mapZoomLevel) {
                      const mapState = {
                        ...state,
                        marketMapBamData: JSON.stringify(mapData),
                      };
                      processMapImgHandler(mapState);
                    }
                  });
              }
            });
          }
        });
      });
      return {
        ...newMapState,
        mapSize,
        mapCount,
      };
    }
    case MARKETMAP_ACTION.UPDATE_MARKETMAP_ACTIVE_INDEX: {
      return {
        ...state,
        activeMapIndex: action.marketMapState.activeMapIndex,
      };
    }
    case MARKETMAP_ACTION.UPDATE_MARKETMAPLIST:
      processMapImgHandler(state);
      return {
        ...state,
        mapCount: action.marketMapState.mapCount,
      };
    case MARKETMAP_ACTION.UPDATE_MARKETMAP_SIZE:
      processMapImgHandler(state);
      return {
        ...state,
        legendHeight: action.marketMapState.legendHeight,
      };
    case MARKETMAP_ACTION.UPDATE_MARKETMAP_LEGEND_HEIGHT: {
      const newMapState = {
        ...state,
        ...action.marketMapState,
      };
      const mapSize = mapUtil.default.getMapSize(newMapState);
      const mapCount = mapUtil.default.getMapCount(
        newMapState.isIncludeLegend,
        newMapState.isIncludeHeader,
        newMapState.properties,
      );
      [...Array(mapCount)].forEach((_, i) => {
        mapUtil.default.resizeMap('marketmap', i, mapSize).then(() => {
          const mapEle = mapUtil.default.getMapInstance('marketmap', i);
          if (mapEle && mapEle.zoomToFit && newMapState.resettingMap) {
            mapEle.zoomToFit().then(() => {
              if (i === 0) {
                mapUtil.default
                  .getMapState('marketmap', i)
                  .then((mapData: any) => {
                    if (mapData.mapCentroid || mapData.mapZoomLevel) {
                      const mapState = {
                        ...state,
                        marketMapBamData: JSON.stringify(mapData),
                      };
                      processMapImgHandler(mapState);
                    }
                  });
              }
            });
          } else {
            // eslint-disable-next-line no-lonely-if
            if (i === 0) {
              mapUtil.default
                .getMapState('marketmap', i)
                .then((mapData: any) => {
                  if (mapData.mapCentroid || mapData.mapZoomLevel) {
                    const mapState = {
                      ...state,
                      marketMapBamData: JSON.stringify(mapData),
                    };
                    processMapImgHandler(mapState);
                  }
                });
            }
          }
        });
      });
      return {
        ...newMapState,
        mapSize,
        mapCount,
      };
    }
    case MARKETMAP_ACTION.UPDATE_MARKETMAP_ZOOM_CENTER: {
      const newMapState = {
        ...state,
        ...action.marketMapState,
      };
      const mapSize = mapUtil.default.getMapSize(newMapState);
      const mapCount = mapUtil.default.getMapCount(
        newMapState.isIncludeLegend,
        newMapState.isIncludeHeader,
        newMapState.properties,
      );
      if (
        newMapState.marketMapBamData &&
        typeof JSON.parse(newMapState.marketMapBamData) === 'object' &&
        !isEmptyObjectFromStr(newMapState.marketMapBamData)
      ) {
        const mapData = JSON.parse(newMapState.marketMapBamData);
        [...Array(mapCount)].forEach((_, i) => {
          if (i === 0) {
            mapUtil.default
              .getMapState('marketmap', i)
              .then((mapDataRes: any) => {
                if (mapDataRes.mapCentroid || mapDataRes.mapZoomLevel) {
                  const mapState = {
                    ...state,
                    marketMapBamData: JSON.stringify(mapDataRes),
                  };
                  processMapImgHandler(mapState);
                }
              });
          } else if (mapData.mapReset) {
            mapUtil.default.recenterMap('marketmap', i);
          } else {
            mapUtil.default.updateMapZoom('marketmap', i, mapData.mapZoomLevel);
            mapUtil.default.updateMapCenter(
              'marketmap',
              i,
              mapData.mapCentroid,
            );
          }
        });
      }
      return {
        ...newMapState,
        mapSize,
        mapCount,
        mapLoaded: true,
      };
    }
    case MARKETMAP_ACTION.UPDATE_MARKETMAP_STYLE: {
      const newMapState = {
        ...state,
        ...action.marketMapState,
      };
      [...Array(newMapState.mapCount)].forEach((_, i) => {
        mapUtil.default
          .updateMapStyle('marketmap', i, newMapState.marketMapStyle)
          .then(() => {
            if (i === 0) {
              processMapImgHandler(state);
            }
          });
      });
      return {
        ...newMapState,
      };
    }
    case MARKETMAP_ACTION.UPDATE_MARKETMAP_POTINS: {
      const newMapState = {
        ...state,
        points: action.marketMapState.points || [],
        properties: action.marketMapState.properties || [],
      };
      const mapCount = mapUtil.default.getMapCount(
        newMapState.isIncludeHeader,
        newMapState.isIncludeLegend,
        newMapState.properties,
      );
      [...Array(newMapState.mapCount)].forEach((_, i) => {
        if (
          action.marketMapState.resettingMap !== undefined &&
          !action.marketMapState.resettingMap
        ) {
          mapUtil.default.addMapPotinsOnly(
            'marketmap',
            i,
            newMapState.points,
            action.patch,
          );
        } else {
          mapUtil.default.addMapPotins(
            'marketmap',
            i,
            newMapState.points,
            state.properties,
          );
        }
      });
      return {
        ...newMapState,
        mapCount,
      };
    }
    default:
      return {
        ...state,
        ...action.marketMapState,
      };
  }
};

export default marketMapReducer;
