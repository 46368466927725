import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  COUNTRY_FOOTER_TEXT_MAP,
  DEFAULT_FOOTER_TEXT,
} from '@marketiq/marketiq-core-data';

type Props = {
  surveyCountry: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: '#F5F7F7',
    },
    cbreLogo: {
      height: '11.93px',
      color: 'green',
      marginBottom: '54px',
      paddingBottom: '17px',
      paddingRight: '5px',
      position: 'absolute',
      left: '92.68%',
      right: '1.26%',
      top: '20%',
      bottom: '30.36%',
    },
    footerText: {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '10px',
      paddingRight: '88px',
      fontFamily: 'Calibre-R',
      fontSize: '7.5px',
      lineHeight: '107%',
      letterSpacing: '0em',
      textAlign: 'left',
      color: 'rgba(26, 26, 26, 0.6)',
      height: '30px',
      left: '10px',
      top: '18px',
      borderRadius: 'nullpx',
      backgroundColor: '#F5F7F7',
    },
  }),
);

const Footer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const footerText =
    COUNTRY_FOOTER_TEXT_MAP[props.surveyCountry] || DEFAULT_FOOTER_TEXT;
  return (
    <div className={classes.container}>
      <Box display="flex" className={classes.footerText}>
        {footerText}
      </Box>
      <Box p={1} className={classes.cbreLogo}>
        <img src="/assets/logo/CBRE_green.svg" alt="cbre logo"></img>
      </Box>
    </div>
  );
};

export default Footer;
