import React, { useState, useEffect } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Property, GalleryFile, MediaFile } from '../../types';
import { MediaState } from '../../redux/types/dataTypes';
import {
  getArrayChunks,
  getFullAddressStr,
  mediaFileToGalleryFile,
} from '../../utilities';
import SurveyPDFPage from '../SurveyPDFPage';
import Header from '../PDFCommon';
import PropertyImages from '../PropertyImages/PropertyImages';
import { PDFPaperSize } from '../../data/constants';
import Footer from '../Footer';
import usePreviewStyles from './PreviewStyles';

interface PropertyImagesPage {
  title: string;
  subtitle: string;
  order: string;
  PropertyImages: string[];
}

const PropertyImagePreview: React.FC<Props> = ({
  property,
  mediaState,
  surveyCountry,
  pdfPaperSize,
  propertyOrder,
  pageMode,
  isVisible,
}) => {
  const classes = usePreviewStyles();
  const [propertiesPreview, setPropertiesPreview] = useState<
    PropertyImagesPage[]
  >([]);
  const mediaList = useSelector((state: any) => state.media, shallowEqual);
  const [numberOfPicturesPerProperty, setNumberOfPicturesPerProperty] = useState<boolean>();
  const getPropertyImages = (propertyParamId: string) => {
    const fileState = mediaList[propertyParamId] || {};
    const { images } = fileState;
    // eslint-disable-next-line arrow-body-style
    return (
      images
        ?.filter(
          (media: MediaFile) => media.isHidden === undefined || media.isHidden === false,
        )
        .map((media: MediaFile) => {
          const newMedia = mediaFileToGalleryFile(media);
          // eslint-disable-next-line no-return-assign
          return {
            ...newMedia,
            url: newMedia.origin && newMedia.origin.toLowerCase() === 'siteiq' && !newMedia.url.includes('edp?') ?
              newMedia.url += 'size=standard' : newMedia.url,
          };
        }) || []
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-await-in-loop
    if (property.id && Object.keys(mediaList).length > 0 && isVisible) {
      const imageProp: GalleryFile[] = getPropertyImages(property.id);
      const addressDisplay: string = getFullAddressStr(
        property.address1,
        property.city,
        property.state,
        property.postalCode,
      );

      const propertyImages = imageProp
        .filter((img) => img.active)
        .map((propertyImage: GalleryFile) => propertyImage.url).slice(1);
      const imagesChunks = getArrayChunks(propertyImages, 4) || [];
      const imagesPages: PropertyImagesPage[] = imagesChunks.map(
        (urls: string[]) => ({
          title: property.name,
          subtitle: addressDisplay || '',
          order: propertyOrder,
          PropertyImages: urls,
        }),
      );
      setPropertiesPreview(imagesPages);
    } else {
      setPropertiesPreview([]);
    }
  }, [property.id, mediaList, isVisible]);
  const getPropertyWithImages = propertiesPreview && propertiesPreview[0];
  const numberOfPropImages = getPropertyWithImages && getPropertyWithImages.PropertyImages.length;
  useEffect(() => {
    if (numberOfPropImages === 0) {
      setNumberOfPicturesPerProperty(false);
    } else {
      setNumberOfPicturesPerProperty(true);
    }
  }, [numberOfPropImages]);

  return (
    <>
      {/* this section generates the pages for properties' images */}
      {numberOfPicturesPerProperty && isVisible && propertiesPreview.map((propertyImagesPage, index) => {
        const elementkey: string = `${property.id}${index}img`;
        let pageHeightClassName = classes.pageContainerLandscapeHeaderHeight;
        if (pdfPaperSize === PDFPaperSize.Portrait) {
          pageHeightClassName = classes.pageContainerPortraitHeaderHeight;
        }
        return (
          <SurveyPDFPage
            mode={pageMode}
            key={elementkey}
            paperSize={pdfPaperSize}
          >
            <Header
              title={propertyImagesPage.title}
              subtitle={propertyImagesPage.subtitle}
              order={propertyImagesPage.order}
            />
            <Box
              className={`${classes.propertyImg} ${pageHeightClassName}`}
              p={3}
            >
              <PropertyImages imgList={propertyImagesPage.PropertyImages} />
            </Box>
            <div className={classes.footer}>
              <Footer surveyCountry={surveyCountry}></Footer>
            </div>
          </SurveyPDFPage>
        );
      })}
    </>
  );
};

export type Props = {
  property: Property;
  propertyOrder: string;
  pageMode?: string;
  pdfPaperSize: string;
  mediaState: MediaState;
  surveyCountry: string;
  isVisible: boolean;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  surveyState: state.survey,
  mediaState: state.media,
  ...ownProps,
});

PropertyImagePreview.displayName = 'PropertyImagePreview';
export default React.memo(connect(mapStateToProps)(PropertyImagePreview));
