import { propertyServiceBaseURL } from '../config';
import { axios } from '../internal-lib';
import { BHCustomField, BHCustomFieldPost } from '../types/bh-custom-field';
import onSuccess from './onSuccess';

export const addCustomField = (customField: BHCustomFieldPost) =>
  axios
    .post<BHCustomField>(`${propertyServiceBaseURL}customfields/`, customField)
    .then(onSuccess);

export const updateCustomField = (customField: BHCustomField) =>
  axios
    .patch<BHCustomField>(`${propertyServiceBaseURL}customfields/`, customField)
    .then(onSuccess);

export const deleteCustomField = (customField: BHCustomField) =>
  axios
    .delete<string>(`${propertyServiceBaseURL}customfields/`, {
      data: customField,
    })
    .then(onSuccess);

export const getCustomFields = (params?: string) =>
  axios
    .get<BHCustomField[]>(`${propertyServiceBaseURL}customfields${params}`)
    .then(onSuccess);
