import {
  Button,
  Theme,
  createStyles,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shareModal: {
      opacity: '1',
      transform: 'translateX(0px)',
      height: 'auto',
      transition: 'all 200ms ease-in-out 20ms',
    },
    shareModalContent: {
      fontSize: '1rem',
      lineHeight: '1.75',
    },
    shareModalTitle: {
      fontSize: '1.3rem',
      lineHeight: '1.75',
      margin: 10,
    },
    closeIcon: {
      margin: 3,
      position: 'absolute',
      right: 0,
      top: 0,
    },
  }),
);

const PublishShareModal: React.FC<Props> = (props) => {
  const { id, testId, shareButtonDisabled } = props;
  const classes = useStyles();
  const [isActive, setIsActive] = React.useState(false);

  const toggleShareModal = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      {!isActive && (
        <Button
          id={id}
          data-testid={testId}
          disabled={shareButtonDisabled}
          variant="contained"
          fullWidth
          onClick={toggleShareModal}>
          Share
        </Button>
      )}
      {isActive && (
        <div className={classes.shareModal}>
          <div>
            <h4 className={classes.shareModalTitle}>{props.title}</h4>
            <IconButton
              size="small"
              onClick={toggleShareModal}
              className={classes.closeIcon}>
              <CloseIcon id="SC-CloseShareModel" />
            </IconButton>
          </div>
          <div className={classes.shareModalContent}>{props.children}</div>
        </div>
      )}
    </div>
  );
};

export type Props = {
  survey: Survey;
  title: string;
  id: string;
  testId: string;
  shareButtonDisabled: boolean;
};

PublishShareModal.displayName = 'PublishShareModal';
export default PublishShareModal;
