import { axios } from '../internal-lib'
import { propertyServiceBaseURL } from '../config'
import onSuccess from './onSuccess'
import { TemplateConfigResponse } from '../types';

const getTemplateConfig = (entity: string, surveyId: string) =>
  axios
    .get<TemplateConfigResponse>(
      `${propertyServiceBaseURL}${entity}/template/${surveyId}/SC_SURVEY`,
    )
    .then(onSuccess)
export default getTemplateConfig
