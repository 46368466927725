import React from 'react';
import { parseInputChange } from '@cbreone/utilities';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Property, PropertyPatch, Survey } from '../../types';
import PropertyAddressForm from './PropertyAddressForm';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import DynamicSectionsForm from './DynamicSectionsForm';
import CustomFieldsForm from './CustomFieldsForm';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
}));

// new component for property details
const PropertyDetails: React.FC<Props> = ({
  survey,
  property,
  onUpdateProperty,
  onAddCustomField,
  onDeleteCustomField,
  onUpdateAvailability,
  onUpdateCustomField,
  onUpdatePropertyCustomField,
}) => {
  const classes = useStyles();

  const handlePropertyUpdate = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = parseInputChange(event.target);
      onUpdateProperty({
        id: property.id,
        [name]: value,
      } as PropertyPatch);
    },
    [property],
  );

  const handlePropertyMetaDataUpdate = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = parseInputChange(event.target);
      onUpdateProperty({
        id: property.id,
        metaData: { [name]: value },
      } as unknown as PropertyPatch);
    },
    [property],
  );

  const handlePropertyDataUpdate = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = parseInputChange(event.target);
      onUpdateProperty({
        id: property.id,
        data: { [name]: value as string },
      } as unknown as PropertyPatch);
    },
    [property],
  );

  const handleCoordinatesUpdate = React.useCallback(
    (latitude: string, longitude: string) => {
      onUpdateProperty({
        id: property.id,
        latitude,
        longitude,
      });
    },
    [property],
  );

  const handleZoomAndCentroidUpdate = React.useCallback(
    (bamMapData: any) => {
      if (bamMapData) {
        onUpdateProperty({
          id: property.id,
          metaData: {
            bamMapData: JSON.stringify(bamMapData),
          },
        } as unknown as PropertyPatch);
      }
    },
    [property],
  );

  return (
    <>
      <div className={classes.v_spacer} />
      <PropertyAddressForm
        property={property}
        handlePropertyUpdate={handlePropertyUpdate}
        handleCoordinatesUpdate={handleCoordinatesUpdate}
        handlePropertyMetaDataUpdate={handlePropertyMetaDataUpdate}
        handleZoomAndCentroidUpdate={handleZoomAndCentroidUpdate}
      />
      <div className={classes.v_spacer} />
      {/* <PropertyInfoForm
        property={property}
        handlePropertyUpdate={handlePropertyUpdate}
      />
      <div className={classes.v_spacer} />
      <AdditionalInfoForm
        property={property}
        handlePropertyUpdate={handlePropertyUpdate}
      /> */}
      <DynamicSectionsForm
        property={property}
        sections={survey.templateConfig?.property?.sections}
        handlePropertyUpdate={handlePropertyDataUpdate}
      />
      <div className={classes.v_spacer} />
      <CustomFieldsForm
        property={property}
        survey={survey}
        onAddSurveyCustomField={onAddCustomField}
        onDeleteSurveyCustomField={onDeleteCustomField}
        onUpdateSurveyCustomField={onUpdateCustomField}
        onUpdatePropertyCustomField={onUpdatePropertyCustomField}
      />
    </>
  );
};

export type Props = {
  survey: Survey;
  property: Property;
  onUpdateProperty: UseSurveyEditorAPI['updateProperty'];
  onAddAvailability: UseSurveyEditorAPI['addAvailability'];
  onDeleteAvailability: UseSurveyEditorAPI['deleteAvailability'];
  onUpdateAvailability: UseSurveyEditorAPI['updateAvailability'];
  onAddCustomField: UseSurveyEditorAPI['addCustomField'];
  onDeleteCustomField: UseSurveyEditorAPI['deleteCustomField'];
  onUpdateCustomField: UseSurveyEditorAPI['updateCustomField'];
  onUpdatePropertyCustomField: UseSurveyEditorAPI['updatePropertyCustomField'];
};

PropertyDetails.displayName = 'PropertyDetails';
export default React.memo(PropertyDetails);
