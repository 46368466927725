import React from 'react';
import SurveySummary from '../pages/SurveySummary';
import SurveyBuilderWrapper from '../pages/SurveyBuilder/SurveyBuilderWrapper';
import SurveySectionPageWrapper from '../pages/SurveySectionPageWrapper';

type Route = {
  path?: string;
  exact?: boolean;
  component: React.FC<any>;
};
export type RouteParams = {
  id?: string;
  data?: string;
  encodedstr?: string;
};

export type PreviewRouteParams = {
  id?: string;
  section?: string;
  propertyId?: string;
  index?: number;
};

const routes: Route[] = [
  {
    path: '/:id',
    exact: true,
    component: SurveySummary,
  },
  {
    path: '/:id/builder',
    exact: true,
    component: SurveyBuilderWrapper,
  },
  {
    path: '/:id/builder/data/:encodedstr',
    exact: true,
    component: SurveyBuilderWrapper,
  },
  {
    path: '/:id/preview/:section',
    exact: true,
    component: SurveySectionPageWrapper,
  },
  {
    path: '/:id/preview/:section/:propertyId',
    exact: true,
    component: SurveySectionPageWrapper,
  },
  {
    path: '/:id/preview/:section/:propertyId/floorPlanImage/:index',
    exact: true,
    component: SurveySectionPageWrapper,
  },
];

export default routes;
