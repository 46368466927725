import React, {
  ReactText, useMemo, ReactElement, useState, useEffect,
} from 'react';
import {
  Theme, createStyles, makeStyles, Grid, Typography,
} from '@material-ui/core';
import { Property, Survey } from '../../types';
import * as mapUtil from '../../utilities/map';
import MapStarIcon from '../../map/Icons/MapStarIcon';
import { PDFPaperSize } from '../../data/constants';

const useStyles = makeStyles((theme: Theme) => createStyles({
  indexValue: {
    height: '20px',
    width: '20px',
    left: '0%',
    top: '0%',
    bottom: '0%',
    borderRadius: '50%',
    backgroundColor: '#4082C3',
    textAlign: 'center',
    alignItems: 'center',
  },
  iconIndexColor: {
    color: 'white',
  },
  iconPadding: {
    paddingLeft: '8px',
  },
  containerPadding: {
    paddingTop: '7px',
  },
  gridContainer: {
    flexWrap: 'nowrap',
  },
  landscapeNameAddress: {
    width: '17.3rem',
  },
  portraitNameAddress: {
    width: '325px',
  },
  root: {
    padding: '5px 8px 10px 10px',
  },
  portraitPadding: {
    padding: '8px 28px 0 22px',
  },
}));

const MarketMapLegends: React.FC<Props> = ({
  survey,
  properties,
}) => {
  const classes = useStyles();
  const { getIconData } = mapUtil.default;
  const propertyIndexMap = useMemo(() => {
    let index = 0;
    return survey.properties.reduce((acc: any, property: any) => {
      if (property.isHidden) {
        return { ...acc, [property.id]: <>&#8211;</> };
      }
      if (property.metaData?.isCurrentLocation) {
        return {
          ...acc,
          [property.id]: (
            <MapStarIcon stroke={getIconData(property).iconColor} />
          ),
        };
      }
      index += 1;
      return {
        ...acc,
        [property.id]: index,
      };
    }, {} as { [x: string]: ReactText | ReactElement });
  }, [getIconData, survey.properties]);

  const propertiesHalf = Math.ceil(properties && properties.length / 2);
  const leftSideProperties = properties && properties.slice(0, propertiesHalf);
  const rightSideProperties = properties && properties.slice(propertiesHalf);

  const [customName, setCustomName] = useState<boolean>(
    survey.customLegends?.displayFields?.hasPropertyName,
  );
  const [customAddressLine1, setCustomAddressLine1] = useState<boolean>(
    survey.customLegends?.displayFields?.hasAddressLine1,
  );
  const [customCity, setCustomCity] = useState<boolean>(
    survey.customLegends?.displayFields?.hasCity,
  );
  const [customState, setCustomState] = useState<boolean>(
    survey.customLegends?.displayFields?.hasState,
  );
  const [customZipCode, setCustZipCode] = useState<boolean>(
    survey.customLegends?.displayFields?.hasZipCode,
  );
  const [addressLineComma, setAddressLineComma] = useState<string>(',');
  const [cityComma, setCityComma] = useState<string>(',');
  useEffect(() => {
    if (survey.customLegends?.displayFields?.hasPropertyName) {
      setCustomName(true);
    } else {
      setCustomName(false);
    }
    if (survey.customLegends?.displayFields?.hasAddressLine1) {
      setCustomAddressLine1(true);
    } else {
      setCustomAddressLine1(false);
    }
    if (survey.customLegends?.displayFields?.hasCity) {
      setCustomCity(true);
    } else {
      setCustomCity(false);
    }
    if (survey.customLegends?.displayFields?.hasState) {
      setCustomState(true);
    } else {
      setCustomState(false);
    }
    if (survey.customLegends?.displayFields?.hasZipCode) {
      setCustZipCode(true);
    } else {
      setCustZipCode(false);
    }
    if (
      !survey.customLegends?.displayFields?.hasState &&
      !survey.customLegends?.displayFields?.hasZipCode
    ) {
      setCityComma('');
    } else {
      setCityComma(',');
    }
    if (
      !survey.customLegends?.displayFields?.hasState &&
      !survey.customLegends?.displayFields?.hasZipCode &&
      !survey.customLegends?.displayFields?.hasCity
    ) {
      setAddressLineComma('');
    } else {
      setAddressLineComma(',');
    }
  }, [
    survey.customLegends.displayFields.hasAddressLine1,
    survey.customLegends.displayFields.hasCity,
    survey.customLegends.displayFields.hasPropertyName,
    survey.customLegends.displayFields.hasState,
    survey.customLegends.displayFields.hasZipCode,
  ]);

  const portraitLegend = (propertyList: any) => (
    <>
      {propertyList.map((property: Property, index: number) => (
        <Grid
          container
          className={(classes.gridContainer, classes.containerPadding)}
          key={index}
          id="myPortraitLegend">
          <Grid
            className={classes.indexValue}
            style={{
              backgroundColor: property.metaData?.isClientShortlist
                ? '#003F2D '
                : getIconData(property).iconColor,
            }}>
            <Typography variant="body2" className={classes.iconIndexColor}>
              {propertyIndexMap[property.id]}
            </Typography>
          </Grid>
          <Grid className={classes.iconPadding}>
            <Grid>
              <Typography
                variant="body2"
                noWrap
                className={classes.portraitNameAddress}>
                {customName ? property.name || property.address1 || '' : ''}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body2"
                noWrap
                className={classes.portraitNameAddress}>
                {customAddressLine1
                  ? `${property.address1 || ''}${addressLineComma} `
                  : ''}
                {customCity ? `${property.city || ''}${cityComma} ` : ''}
                {customState ? `${property.state || ''} ` : ''}
                {customZipCode ? `${property.postalCode || ''}` : ''}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );

  return (
    <div className={classes.root}>
      {survey.pdfPaperSize === PDFPaperSize.Landscape ?
        (properties && properties.map((property: any, index: any) => (
          <Grid
            container
            item xs={12}
            className={(classes.gridContainer, classes.containerPadding)}
            key={index}
          >
            <Grid
              className={classes.indexValue}
              style={{ backgroundColor: getIconData(property).iconColor }}>
              <Typography variant="body2" className={classes.iconIndexColor} >
                {propertyIndexMap[property.id]}
              </Typography>
            </Grid>
            <Grid className={classes.iconPadding}>
              <Typography variant="body2" noWrap className={classes.landscapeNameAddress}>
                {customName ? property.name || property.address1 || '' : ''}
              </Typography>
              <Typography variant="body2" noWrap className={classes.landscapeNameAddress}>
                {customAddressLine1 ? `${property.address1 || ''}${addressLineComma} ` : ''}
                {customCity ? `${property.city || ''}${cityComma} ` : ''}
                {customState ? `${property.state || ''} ` : ''}
                {customZipCode ? `${property.postalCode || ''}` : ''}
              </Typography>
            </Grid>
          </Grid>
        )))
        : survey.pdfPaperSize === PDFPaperSize.Portrait &&
        <Grid
          container
          item xs={12}
          className={classes.portraitPadding}
          direction="row"
        >
          <Grid item xs={6}>
            {portraitLegend(leftSideProperties)}
          </Grid>
          <Grid item xs={6}>
            {portraitLegend(rightSideProperties)}
          </Grid>
        </Grid>
      }
    </div>
  );
};

export type Props = {
  survey: Survey;
  properties: Property[];
};

MarketMapLegends.displayName = 'MarketMapLegends';
export default React.memo(MarketMapLegends);
