import { propertyServiceBaseURL } from '../config';
import { axios } from '../internal-lib';
import {
  Property,
  PropertyCopy,
  PropertyDelete,
  PropertyPatch,
  PropertyPost,
  PropertyReorderPatch,
  PropertyStatusPatch,
} from '../types';
import { BHPropertyCustomFieldValuePatch } from '../types/bh-property-custom-field';
import onSuccess from './onSuccess';


export const reorderProperty = (post: PropertyReorderPatch) => reorderSCProperty(post)

export const addProperty = (property: PropertyPost) =>
  axios
    .post<Property>(`${propertyServiceBaseURL}property/`, property)
    .then(onSuccess);

export const getProperty = (id: string) =>
  axios
    .get<Property>(`${propertyServiceBaseURL}property/${id}`)
    .then(onSuccess);

export const getProperties = (params?: string) =>
  axios
    .get<Property[]>(`${propertyServiceBaseURL}property${params}`)
    .then(onSuccess);

export const updateProperty = (property: PropertyPatch) =>
  axios
    .patch<Property>(`${propertyServiceBaseURL}property`, property)
    .then(onSuccess);

export const updatePropertyStatus = (property: PropertyStatusPatch) =>
  axios
    .patch<Property>(`${propertyServiceBaseURL}property/status`, property)
    .then(onSuccess);

export const updatePropertyCustomField = (
  request: BHPropertyCustomFieldValuePatch,
) =>
  axios
    .patch<Property>(
      `${propertyServiceBaseURL}property/${request.propertyId}/customField`,
      request.customField,
    )
    .then(onSuccess);

export const deleteProperty = (propertyDelete: PropertyDelete) =>
  axios
    .delete<string>(`${propertyServiceBaseURL}property`, {
      data: propertyDelete,
    })
    .then(onSuccess);

export const deleteAvailabilityService = (availabilityId: string) =>
  axios
    .delete<string>(
      `${propertyServiceBaseURL}propertyAvailability/availabilityId/${availabilityId}`,
    )
    .then(onSuccess);

export const reorderSCProperty = (propertyReorder: PropertyReorderPatch) =>
  axios
    .patch<Property[]>(
      `${propertyServiceBaseURL}property/reorder`,
      propertyReorder,
    )
    .then(onSuccess);

export const getPropertyAPIState = () =>
  axios.get<any>(`${propertyServiceBaseURL}property`).then(onSuccess);

export const copyProperty = (surveyId: string, idList: string[]) =>
  axios
    .post<any>(
      `${propertyServiceBaseURL}property/copyTo/externalId/${surveyId}/ownerType/SC_SURVEY`,
      idList,
    )
    .then(onSuccess);

export const copyProperties = (propertyCopy: PropertyCopy) =>
  axios
    .post<any>(`${propertyServiceBaseURL}property/copyProperties`, propertyCopy)
    .then(onSuccess);
