import { Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { PDFPaperSize } from '../../data/constants';
import MapStarIcon from '../../map/Icons/MapStarIcon';
import { MediaState } from '../../redux/types/dataTypes';
import { MediaFile, Property, Survey } from '../../types';
import { mediaFileToGalleryFile } from '../../utilities';
import  isS3url  from '../../utilities/collateral';
import * as mapUtil from '../../utilities/map';

const useStyles = makeStyles({
  featureImg: {
    height: '336px',
    width: '480px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  img: {
    width: '90%',
    height: '100%',
    backgroundSize: 'cover',
    position: 'relative',
    backgroundPosition: 'center center',
  },
  featureImgPortrait: {
    height: '345px',
    width: '324px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  ftImgPlaceHolder: {
    width: '98%',
    margin: 'auto',
    paddingBottom: '60%',
    backgroundColor: '#f5f7f6',
    textAlign: 'center',
  },
  thumbnailImg: {
    height: '64px',
    width: '84px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  fullsizeImg: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  centerImg: {
    textAlign: 'center',
  },
  pinNumbers: {
    color: 'white',
    border: '2px solid white',
    borderRadius: '50%',
    width: '23px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PropertyFeatureImage: React.FC<Props> = ({
  propertyId,
  survey,
  size,
  mode,
  mediaState,
  order,
  isShowIcon,
}) => {
  const classes = useStyles();
  const [imageData, setImageData] = useState<any>({});
  const smallPlaceholderImg = '/assets/images/image-placeholder-small.jpg';
  const mediumPlaceholderImg = '/assets/images/image-placeholder-medium.png';
  const { getIconData } = mapUtil.default;
  useEffect(() => {
    const fileState = mediaState[propertyId] || {};
    const { images } = fileState;
    const activeImg = Array.isArray(images)
      ? images.find(
          (item: MediaFile) =>
            item.isHidden === undefined || item.isHidden === false,
        )
      : null;
    // eslint-disable-next-line arrow-body-style
    const mediaImg = activeImg
      ? [activeImg].map((img: any) => mediaFileToGalleryFile(img))
      : [];
    const propertyImg = mediaImg[0];
    let imgURL = propertyImg?.url || '';

    if (isS3url(imgURL)) {
      if (mode && mode === 'PDF') {
        imgURL += '?d=print';
      } else if (size === 'thumbnail') {
        imgURL += '?d=thumbnail';
      } else {
        imgURL += '?d=web';
      }
    }

    const url =
      imgURL ||
      (size === 'medium' ? mediumPlaceholderImg : smallPlaceholderImg);
    let imgClass = classes.thumbnailImg;
    if (size === 'fullsize') {
      imgClass = classes.fullsizeImg;
    } else if (size === 'medium') {
      if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
        imgClass = classes.featureImgPortrait;
      } else {
        imgClass = classes.featureImg;
      }
    }
    setImageData({
      ...imageData,
      url,
      imgClass,
    });
  }, [mediaState]);

  const property = useMemo(() => {
    if (survey && survey.properties) {
      return survey.properties.find((item: any) => item.id === propertyId);
    }
    return {} as Property;
  }, [propertyId, survey]);

  const propertyIcon = () => {
    if (property && property.metaData?.isCurrentLocation) {
      return (
        <div
          className={classes.pinNumbers}
          style={{ height: '23px', width: '23px' }}>
          <MapStarIcon stroke={getIconData(property).iconColor} />
        </div>
      );
    }
    if (property && !property.metaData?.isCurrentLocation) {
      return (
        <div>
          <Typography
            variant="body2"
            className={classes.pinNumbers}
            style={{
              backgroundColor: property.metaData?.isClientShortlist
                ? '#003F2D'
                : getIconData(property).iconColor,
            }}>
            {order}
          </Typography>
        </div>
      );
    }
    return '';
  };

  return (
    <>
      {property &&
        (isShowIcon ? (
          <div
            style={{ backgroundImage: `url('${imageData.url}')` }}
            className={imageData.imgClass}>
            {propertyIcon()}
          </div>
        ) : (
          <div className={imageData.imgClass}>
            <div
              style={{ backgroundImage: `url('${imageData.url}')` }}
              className={classes.img}
            />
          </div>
        ))}
    </>
  );
};

export type Props = {
  propertyId: string;
  survey: Survey;
  size: string;
  mode?: string;
  mediaState: MediaState;
  order: Object;
  isShowIcon?: boolean;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  mediaState: state.media,
  ...ownProps,
});

PropertyFeatureImage.displayName = 'PropertyFeatureImage';
export default connect(mapStateToProps)(PropertyFeatureImage);
