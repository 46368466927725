import { parseInputChange } from '@cbreone/utilities';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import marketMapAction from '../../redux/actions/marketMapAction';
import { MarketMapStatePatch } from '../../redux/types/dataTypes';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    mapOptionsTxt: {
      width: '100%',
      textAlign: 'center',
    },
    buttonColor: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      margin: 0,
      padding: 0,
      // fontSize: '16px',
    },
    centered: {
      textAlign: 'center',
    },
    centerMargins: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    icon: {
      width: '25px',
      paddingLeft: '51px',
    },
  }),
);
const MarketMapSectionForm: React.FC<Props> = ({
  survey,
  onSurveyUpdate,
  marketMapState,
  onOpenEditColumnsDrawer,
}) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const numberOfHiddenProperties = survey.properties.filter((item) => item.isHidden).length;
  const [customLegendDisabled, setCustomLegendDisabled] = useState(false);

  const setMarketMapState = (e: React.ChangeEvent<any>) => {
    const { name, value } = (e.target ? parseInputChange(e.target) : e) as {
      name: string;
      value: string | number | boolean | undefined;
    };
    dispatch(
      marketMapAction.updatePdfPaperSizeAction({
        [name]: value,
        resettingMap: true,
      } as MarketMapStatePatch),
    );
    onSurveyUpdate(e);
  };

  const displayFieldsStatus = [
    survey.customLegends?.displayFields.hasPropertyName,
    survey.customLegends?.displayFields.hasAddressLine1,
    survey.customLegends?.displayFields.hasCity,
    survey.customLegends?.displayFields.hasState,
    survey.customLegends?.displayFields.hasZipCode,
  ];

  useEffect(() => {
    const customLegendsCheckBoxUnchecked = Object.values(displayFieldsStatus)
      .every((value) => !value);
    if (customLegendsCheckBoxUnchecked === true) {
      setCustomLegendDisabled(true);
    } else {
      setCustomLegendDisabled(false);
    }
  }, [displayFieldsStatus]);

  const scrollPortraitIntoView = () => {
    if (survey.pdfPaperSize === 'Portrait') {
      const portraitLegend = document.getElementById('myPortraitLegend');
      if (!portraitLegend) {
        return;
      }
      const headerOffset = 300;
      const elementPosition = portraitLegend.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line consistent-return
    return '';
  };

  return (
    <section>
      {/* @Todo "post-pilot" work to reference for later. */}
      {/* <TextField
        className={classes.marginBottom}
        name="marketMapName"
        onChange={onSurveyUpdate}
        placeholder="Map Name"
        value={survey.marketMapName}
        label="Map Name"
        fullWidth
      /> */}
      {/* <FormControl component="fieldset">
        <FormLabel component="legend">Map Style</FormLabel>
        <RadioGroup
          aria-label="market-map-style-label"
          name="marketMapStyle"
          value={survey.marketMapStyle}
          onChange={onSurveyUpdate}
        >
          <FormControlLabel value="Aerial" control={<Radio />} label="Aerial" />
          <FormControlLabel value="Road" control={<Radio />} label="Road" />
        </RadioGroup>
      </FormControl>
      <FormControl
        fullWidth
        variant="outlined"
        className={classes.marginBottom}
      >
        <InputLabel id="market-map-zoom-label">Map Zoom</InputLabel>
        <Select
          labelId="market-map-zoom-label"
          id="market-map-zoom-select"
          value={survey.marketMapZoom}
          name="marketMapZoom"
          onChange={onSurveyUpdate}
          label="Map Zoom"
        >
          <MenuItem value="100">Best Fit</MenuItem>
        </Select>
      </FormControl> */}
      <FormControl>
        <Typography className={`${classes.marginBottom}`} component="p">
          {/* Create and customize a Market Map using <br/>
          the integrated Build a Map (BAM) tool. */}
          Create a Market Map using interactive tools
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                marketMapState.isIncludeHeader === undefined
                  ? false
                  : marketMapState.isIncludeHeader
              }
              name="isIncludeHeader"
              onChange={(e) => {
                setMarketMapState(e);
              }}
            />
          }
          label="Show Header"
        />
        <Grid container>
          <Grid item xs={10}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={marketMapState.isIncludeLegend === undefined ||
                    !customLegendDisabled === false ||
                    numberOfHiddenProperties === survey.properties.length ?
                    false : marketMapState.isIncludeLegend
                  }
                  name="isIncludeLegend"
                  onChange={(e) => {
                    setMarketMapState(e);
                  }}
                  disabled={(!!(survey.properties.length === 0 ||
                    numberOfHiddenProperties === survey.properties.length ||
                    customLegendDisabled === true))
                  }
                  style={survey.properties.length === 0 ? { color: 'rgba(26, 26, 26, .12)' } : { color: '' }}
                />
              }
              label={survey.properties.length === 0 ? <label style={{ color: 'rgba(26, 26, 26, .12)' }}>
                Show Legend</label> :
                <label>Show Legend</label>
              }
            />
            {/* <Typography className={`${classes.mapOptionsTxt}`} component="p">
          OR
        </Typography>
        <MarketMapUpload survey={survey} onUpdateMedia={onUpdateMedia} /> */}
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                onOpenEditColumnsDrawer();
                scrollPortraitIntoView();
              }}
            >
              <Edit />
            </ IconButton>
          </Grid>
        </Grid>
      </FormControl>
    </section>
  );
};

export type Props = {
  onSurveyUpdate: (
    e: React.ChangeEvent<
      | HTMLInputElement
      | HTMLTextAreaElement
      | {
        name: string | undefined;
        value: unknown;
      }
    >,
    checkedOrChild?: boolean | React.ReactNode
  ) => void;
  survey: Survey;
  marketMapState: MarketMapStatePatch;
  onOpenEditColumnsDrawer: () => void;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  marketMapState: state.map,
  ...ownProps,
});

MarketMapSectionForm.displayName = 'MarketMapSectionForm';
export default React.memo(connect(mapStateToProps)(MarketMapSectionForm));
