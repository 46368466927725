import { SURVEY_ACTION } from '../types/actionTypes';
import {
  SurveySummaryAction,
  SurveySummaryDispatchType,
  SurveySummary,
} from '../types/dataTypes';

const setSurveySummaryAction = (survey: SurveySummary) => (
  dispatch: SurveySummaryDispatchType,
) => {
    const action: SurveySummaryAction = {
      type: SURVEY_ACTION.SET_SURVEY,
      survey,
    };
    dispatch(action);
  };

export default setSurveySummaryAction;
