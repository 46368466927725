/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { axios } from '@cbreone/utilities';
import { PDFDocument } from 'pdf-lib';
import PDFMerger from 'pdf-merger-js/browser';
import { Buffer } from 'buffer';
import FileGalleryUpload from './FileGalleryUpload';
import { Property } from '../../types/property';
import * as mediaService from '../../services/media-service';
import {
  MediaFilePatch,
  MediaFileDelete,
  GalleryFile,
  MediaFile,
} from '../../types';
import mediaFileAction from '../../redux/actions/mediaFileAction';
import { MediaState } from '../../redux/types/dataTypes';
import { MediaFileType } from '../../data/constants';
import {
  flattenMediaFilesWithModifiedFiles,
  mediaFileToGalleryFile,
} from '../../utilities';
import  isS3url  from '../../utilities/collateral';

const sequence = [0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130]; // in hex:
let imgUrl = '/assets/images/image-placeholder-small.jpg';
const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(2),
  },
  alertBox: {
    marginBottom: 15,
  },
  alerContent: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  '@global': {
    '.thumb': {
      '& p[role="button"]': {
        wordBreak: 'break-word',
      },
      '&:nth-child(2) > div': {
        width: 'auto',
        fontWeight: 'normal',
        top: '2px',
        left: '5px',
      },
    },
  },
}));

const updateStatusOnlyPromise = (payload: MediaFilePatch) =>
  new Promise<any>((resolve, reject) => {
    mediaService
      .updatePropertyMediaStatus(payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });

const updateMediaFilePromise = (payload: MediaFilePatch) =>
  new Promise<any>((resolve, reject) => {
    mediaService
      .updatePropertyMediaFile(payload)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
  });

const FileGallery: React.FC<Props> = ({
  property,
  fileType,
  acceptedFiles,
  galleryTitle,
  addFilePrompt,
  mediaState,
}) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const mediaList = useSelector((state: any) => state.media, shallowEqual);
  const [propertyFileGallery, setPropertyFileGallery] = useState<any[]>([]);
  const useremail = localStorage.getItem('email');
  const galleryName = `PropertyGallery${fileType}`;
  const galleryTitleText = galleryTitle || 'Files';
  const addFileText = addFilePrompt || 'Add File';
  const [errorBool, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const getThumbnailImg = (meida: GalleryFile) => {
    imgUrl = `${meida.url}`;
    if (isS3url(imgUrl)) {
      imgUrl += '?d=thumbnail';
    }
    return imgUrl;
  };

  const getPropertyFiles = () =>
    new Promise((resolve) => {
      const fileState = mediaState[property.id] || {};
      const { images, floorPlans, fliers } = fileState;
      switch (fileType) {
        case MediaFileType.images: {
          // eslint-disable-next-line arrow-body-style
          return resolve(
            images?.map((propertyImg: MediaFile) => {
              const meida = mediaFileToGalleryFile(propertyImg);
              const url = getThumbnailImg(meida);
              const newMedia = {
                ...meida,
                url,
              };
              return newMedia;
            }),
          );
        }
        case MediaFileType.floorplans: {
          // eslint-disable-next-line arrow-body-style
          return resolve(
            floorPlans?.map((propertyImg: MediaFile) => {
              const meida = mediaFileToGalleryFile(propertyImg);
              const url = getThumbnailImg(meida);
              const newMedia = {
                ...meida,
                url,
              };
              return newMedia;
            }),
          );
        }
        case MediaFileType.fliers: {
          const flierList = fliers?.map((propertyImg: MediaFile) =>
            mediaFileToGalleryFile(propertyImg),
          );
          const flatFiles = flattenMediaFilesWithModifiedFiles(flierList);
          // eslint-disable-next-line arrow-body-style
          return resolve(flatFiles);
        }
        default: {
          return resolve([]);
        }
      }
    });

  useEffect(() => {
    getPropertyFiles().then((result: any) => {
      setPropertyFileGallery(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaList]);

  const deleteFile = (mediaFile: any) => {
    const payloadDelete: MediaFileDelete = {
      data: {
        surveyId: property.ownerId,
        propertyId: property.id,
        mediaId: mediaFile.id,
        mediaType: fileType,
      },
    };

    mediaService.deletePropertyMediaFile(payloadDelete).then(() => {
      dispatch(
        mediaFileAction.updateMediaFilesAction({
          ids: [property.id],
        }),
      );
    });
  };

  const updateFile = (mediaFile: any) => {
    const fileState = mediaState[property.id] || {};
    const { fliers } = fileState;
    let originalFlier;
    let promises = [];

    const payloadUpdate: MediaFilePatch = {
      surveyId: property.ownerId,
      propertyId: property.id,
      mediaId: mediaFile.id,
      mediaType: fileType,
    };

    promises = [
      updateMediaFilePromise({
        ...payloadUpdate,
        displayName: mediaFile.displayText,
      }),
      updateStatusOnlyPromise({
        ...payloadUpdate,
        isHidden: !mediaFile.active,
      }),
    ];
    if (mediaFile.modifiedFromMediaId) {
      originalFlier = fliers.find(
        (item) => item.mediaId === mediaFile.modifiedFromMediaId,
      );
      if (originalFlier) {
        const originalFlierPayload: MediaFilePatch = {
          surveyId: property.surveyId,
          propertyId: property.id,
          mediaId: originalFlier.mediaId,
          mediaType: fileType,
        };
        promises.push(
          updateMediaFilePromise({
            ...originalFlierPayload,
            displayName: originalFlier.displayName.replace(/[!@#$%^]/g, ''),
          }),
          updateStatusOnlyPromise({
            ...originalFlierPayload,
            isHidden: !originalFlier.isHidden,
          }),
        );
      }
    }
    Promise.all(promises).then(() => {
      dispatch(
        mediaFileAction.updateMediaFilesAction({
          ids: [property.id],
        }),
      );
    });
  };

  const reorderFile = (mediaFile: any) => {
    const payloadUpdate: MediaFilePatch = {
      surveyId: property.ownerId,
      propertyId: property.id,
      mediaId: mediaFile.id,
      mediaType: fileType,
      order: mediaFile.order,
    };

    mediaService.reorderPropertyMediaFile(payloadUpdate).then(() => {
      dispatch(
        mediaFileAction.updateMediaFilesAction({
          ids: [property.id],
        }),
      );
    });
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const mediaUpload = (
    file: any,
    fileIndex: number = 0,
    modifiedFromMediaId?: string,
    selectedPages?: string,
  ) =>
    new Promise((resolve) => {
      if (fileIndex === 0) {
        setError(false);
      }
      const payloadMedia: any = {
        surveyId: property.ownerId,
        user: useremail,
        propertyId: property.id,
        mediaType: fileType,
      };

      if (modifiedFromMediaId) {
        payloadMedia.modifiedFromMediaId = modifiedFromMediaId;
      }

      if (selectedPages) {
        payloadMedia.selectedPages = selectedPages;
      }

      //   if(file.type.indexOf('png')!==-1 || file.type.indexOf('jpeg') !==-1){
      toBase64(file).then((x: any): any => {
        let data;
        let corrupted = false;

        if (file.type.indexOf('png') !== -1) {
          data = Uint8Array.from(
            atob(x.replace('data:image/png;base64,', '')),
            (c) => c.charCodeAt(0),
          );

          // check last 12 elements of array so they contains needed values
          for (let i = 12; i > 0; i--) {
            if (data[data.length - i] !== sequence[12 - i]) {
              corrupted = true;
              break;
            }
          }
        } else if (file.type.indexOf('jpeg') !== -1) {
          data = Uint8Array.from(
            atob(x.replace('data:image/jpeg;base64,', '')),
            (c) => c.charCodeAt(0),
          );
          corrupted =
            data[data.length - 1] !== 217 || data[data.length - 2] !== 255;
        } else if (file.type.indexOf('pdf') !== -1) {
          data = atob(x.replace('data:application/pdf;base64,', ''));
          corrupted =
            data.includes('Encrypt') ||
            data
              .substring(data.lastIndexOf('<<'), data.lastIndexOf('>>'))
              .includes('/Encrypt');
        }
        if (corrupted) {
          setError(true);
          setErrorMsg(
            'The file you are attempting to upload is either encrypted or password protected.' +
              ' Please try sourcing a different version without password protection, or contact the owner of this file',
          );
          document
            .querySelectorAll<HTMLElement>(
              '[class="MuiGrid-root MuiGrid-item"]',
            )
            .forEach((node) => {
              /* eslint-disable no-param-reassign */
              if (node.outerHTML.search('Processing...') > 0) {
                node.hidden = true;
              }
            });
          // reject('');
          return;
        }

        const formData = new FormData();
        formData.append(
          'file',
          file,
          file.name.replace(/[^a-zA-Z0-9.\-_ ]/g, '_'),
        );
        formData.append('media', JSON.stringify(payloadMedia));
        const headers: any = {
          'Content-Type': 'application/json',
        };

        mediaService
          .uploadPropertyMediaFile(formData, headers)
          .then((res: any) => {
            const obj = {
              id: res.mediaId,
              url: res.cloudfrontUrl,
              previewUrl: res.pdfImageUrl,
              displayText: res.displayName,
              active: !res.isHidden,
              primary: false,
              deletable: true,
            };
            dispatch(
              mediaFileAction.updateMediaFilesAction({
                ids: [property.id],
              }),
            );
            resolve(obj);
          });
        // Event fire after media services update
        // surveyService.eventAPI(property.surveyId);

        //   });
      });
    });

  const deletePreviouslyModifiedFiles = async (mediaId: string) => {
    const fileState = mediaState[property.id] || {};
    const { fliers } = fileState;

    const isModifiedFromFlierPresent = fliers.find(
      (flier: any) => flier.modifiedFromMediaId === mediaId,
    );
    if (isModifiedFromFlierPresent) {
      // console.log('will delete the modified file', isModifiedFromFlierPresent.mediaId);
      await deleteFile({ id: isModifiedFromFlierPresent.mediaId });
    }
  };

  const InputChildren = ({ mediaId }: any) => {
    const [pdfPages, setPdfPages] = useState('');
    const fileState = mediaState[property.id] || {};
    const { fliers } = fileState;

    const pdfRes: any =
      fliers?.find((flier: any) => flier.mediaId === mediaId) || {};
    const modifiedPdf: any = fliers?.find(
      (flier: any) => flier.modifiedFromMediaId === mediaId,
    );

    useEffect(() => {
      if (modifiedPdf) {
        setPdfPages(modifiedPdf.selectedPages);
      }
    }, [modifiedPdf]);

    const modifyPdf = async () => {
      try {
        setError(false);
        const { cloudfrontUrl, displayName } = pdfRes;

        if (!cloudfrontUrl) {
          return null;
        }
        setInProgress(true);

        // Getting total pdf pages
        const response = await axios.get(cloudfrontUrl, {
          responseType: 'arraybuffer',
        });
        console.log('response', response);
        const buffer = Buffer.from(response.data, 'utf-8');
        const pdfDoc = await PDFDocument.load(buffer);
        const pages = pdfDoc.getPages().length;

        let showError = false;
        const pdfPagesStr = [
          ...new Set(pdfPages.replace(/\s*,\s*/g, ',').split(',')),
        ].join(',');
        setPdfPages(pdfPagesStr);
        pdfPagesStr.split(',').forEach((v) => {
          showError = Number(v) > pages;
        });
        // console.log('pdfpgs', pdfPagesStr);

        if (showError) {
          setInProgress(false);
          setError(true);
          setErrorMsg(`PDF has only ${pages} pages.`);
          return false;
        }
        if (!pdfPagesStr || pdfPagesStr === '') {
          setInProgress(false);
          return false;
        }

        await deletePreviouslyModifiedFiles(mediaId);

        const mergePdf = new PDFMerger();
        await mergePdf.add(
          cloudfrontUrl,
          pdfPagesStr.split(',').map((p) => p.toString().trim()),
        );

        const pdfBlob = await mergePdf.saveAsBlob();
        const fileExt = displayName.substring(
          displayName.lastIndexOf('.') + 1,
          displayName.length,
        );
        const fileName = `${displayName
          .replace(/[^a-zA-Z0-9.\-_ ]/g, '_')
          .split('.')[0]
          .replace('AfterCompressing', '')}-modified.${fileExt}`;
        const file = new File([pdfBlob], fileName, {
          type: 'application/pdf',
        });
        await mediaUpload(file, 0, mediaId, pdfPagesStr);
        setInProgress(false);
        return true;
      } catch (error) {
        setInProgress(false);
        setError(true);
        setErrorMsg('Something went wrong!');
        return false;
      }
    };

    const restorePdf = async (media: any) => {
      await deleteFile({ id: media.mediaId });
    };

    const [inProgress, setInProgress] = useState(false);

    return (
      <>
        <React.Fragment>
          <Tooltip
            title="Enter comma-separated numbers to select specific pages to export"
            arrow
            placement="right">
            <input
              placeholder="e.g. 2,3"
              value={pdfPages}
              onChange={(e) => setPdfPages(e.target.value)}
            />
          </Tooltip>
          <div style={{ marginTop: '10px' }}>
            <Button
              disabled={inProgress}
              variant="outlined"
              onClick={() => modifyPdf()}>
              {inProgress ? <CircularProgress size={24} /> : 'Modify PDF'}
            </Button>
          </div>
          {modifiedPdf && (
            <div>
              <Button variant="text" onClick={() => restorePdf(modifiedPdf)}>
                Restore PDF
              </Button>
            </div>
          )}
        </React.Fragment>
      </>
    );
  };

  return (
    <>
      <Typography variant="h6">{galleryTitleText}</Typography>
      <div className={classes.v_spacer} />

      {errorBool && (
        <div className={classes.alertBox}>
          <Box
            border={1}
            borderColor="red"
            display="flex"
            alignItems="center"
            pt="100">
            <div className={classes.alerContent}>
              <ReportProblemOutlinedIcon style={{ color: 'red' }} />
              <Typography style={{ paddingLeft: 10, fontSize: '0.9rem' }}>
                {errorMsg}
              </Typography>
            </div>
          </Box>
        </div>
      )}

      <FileGalleryUpload
        name={galleryName}
        addFilePrompt={addFileText}
        accepted={acceptedFiles}
        fileUploadCall={mediaUpload}
        fileUpdateCall={updateFile}
        fileDeleteCall={deleteFile}
        fileReorderCall={reorderFile}
        files={propertyFileGallery}
        children={fileType === 'FLIERS' && <InputChildren />}
      />
    </>
  );
};

export type Props = {
  property: Property;
  fileType: string;
  acceptedFiles: string;
  galleryTitle?: string;
  addFilePrompt?: string;
  onUpdateMedia: (mediaType: string) => void;
  mediaState: MediaState;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  mediaState: state.media,
  ...ownProps,
});

FileGallery.displayName = 'FileGallery';
export default connect(mapStateToProps)(FileGallery);
