import * as mediaService from './services/media-service';
import store from './redux/store';
import { SURVEY_ACTION } from './redux/types/actionTypes';
import { SurveyAction } from './redux/types/dataTypes';
import { Survey } from './types';

const interceptAPI = ['api/property', 'api/survey', 'media/api/media/'];
const notInterceptAPI = [
  'media/api/media/exportMap',
  'media/api/media/getMediaByIds',
];

const responseInterCeptor = async (response: any) => {
  const method = response.config.method?.toLowerCase();
  if (
    store.getState().survey?.surveyPDF &&
    ['patch', 'post', 'put', 'delete'].includes(method || '') &&
    interceptAPI.some((api: string) => response.config.url?.includes(api)) &&
    !notInterceptAPI.some((api: string) => response.config.url?.includes(api))
  ) {
    try {
      const id: string = store.getState().survey?.id ?? '';
      await mediaService.invalidateSurveyPdfFiles(id);
      const action: SurveyAction = {
        type: SURVEY_ACTION.UPDATE_SURVEY,
        survey: { surveyPDF: undefined } as unknown as Survey,
      };
      store.dispatch(action);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error calling invalidate API', error);
    }
  }
  return response;
};
export default responseInterCeptor;
