/* eslint-disable no-param-reassign */
import Analytics from '@cbreone/core-services/dist/services/analytic-service';

const AnalyticsMode = {
  None: 0,
  Google: 1, // legacy
  Segment: 2, // active
};

export const SC_EVENT = 'SC_ACTIONS';
export enum EVENT_TITLES {
    SC_SURVEY_CREATE_LOAD = 'SC_SURVEY_CREATE_LOAD',
    SC_EDIT_SURVEY_LOAD = 'SC_EDIT_SURVEY_LOAD',
    SC_SURVEY_CLONE = 'SC_SURVEY_CLONE',
    SC_SURVEY_LOAD = 'SC_SURVEY_LOAD',
    SC_ADD_PROPS_LOAD = 'SC_ADD_PROPS_LOAD',
    SC_PAGE_NAVIGATION = 'SC_PAGE_NAVIGATION',
    SC_TOGGLE_PROPERTIES = 'SC_TOGGLE_PROPERTIES',
    SC_TOGGLE_AVAILABILITIES = 'SC_TOGGLE_AVAILABILITIES',
    SC_TOGGLE_PROPERTY_DETAILS = 'SC_TOGGLE_PROPERTY_DETAILS',
    SC_TOGGLE_AVAILABILITY_DETAILS = 'SC_TOGGLE_AVAILABILITY_DETAILS'
}
const analyticsMode = AnalyticsMode.Google;

// Analytics for BH-SC
export default class BHSCAnalytics {
  static get segment() {
    // segment singleton from Analyticds.js in index.html
    return (window as any).analytics;
  }

  static identifyUser() {
    const login_key = localStorage.getItem('accessToken');
    const employeeId = localStorage.getItem('employeeId');
    const name = localStorage.getItem('displayName');
    const email = localStorage.getItem('email');

    const session = sessionStorage.getItem('wso2Token');
    let session_key;
    if (session) {
      const { access_token } = JSON.parse(session);
      session_key = access_token;
    }

    this.segment.identify(employeeId, { login_key, session_key, name, email });
  }

  static trackAction(body: any, action: string) {
    body = body || {};
    body.employeeId = localStorage.getItem('employeeId');
    body.name = localStorage.getItem('displayName');
    body.email = localStorage.getItem('email');

    body.pageUrl = window.location.href;
    body.previousPageUrl = document.referrer || '';

    switch (analyticsMode) {
      case AnalyticsMode.Segment:
        this.segment.track(action, body);
        break;
      case AnalyticsMode.Google:
        Analytics.send(body);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('unknown analytics mode', analyticsMode);
        break;
    }
  }

  static trackEvent(event: string, title = '', payload: any, subtitle = '') {
    payload = payload || {};
    payload.employeeId = localStorage.getItem('employeeId');
    payload.name = localStorage.getItem('displayName');
    payload.email = localStorage.getItem('email');
    payload.pageUrl = window.location.href;
    payload.previousPageUrl = document.referrer || '';
    this.segment.track(event, { title, payload, subtitle });
  }
}
