import { Typography } from '@cbreone/core-ui';
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { linkAttributes } from '../../data/constants';
import { getAvailabilityFieldValues } from '../../utilities/property';
import usePreviewStyles from './PreviewStyles';

const PropertyAvailabilityPreview: React.FC<Props> = ({
  availabilities,
  header,
}) => {
  const classes = usePreviewStyles();

  const emptyCells = (numEmptyCol:number, keyPrefix:any) => (
    numEmptyCol ? [...Array(numEmptyCol)]?.map((item, i) => (
      <TableCell key={`${keyPrefix}-empty-${i}`} classes={{ root: classes.availabilityTableCell }} />
    )) : null
  );
  return (
    <Grid item sm={12} xs={12} style={{ paddingLeft: '8px' }}>
      <Typography className={classes.availabilityHeading} variant={'subtitle2'}>
        {header}
      </Typography>
      <TableContainer className="availabilities-section">
        {availabilities.map((availabilityInfo, mapIndex) => (
          <Table
            aria-label="simple table"
            key={`table-${mapIndex}`}
            className={classes.availabilityTable}>
            <TableBody>
              <TableRow key="header-key" className={classes.tableHeader}>
                {
                  <TableCell
                    className={classes.availabilityPraposedTableCell}
                    component="th"
                    key={`${mapIndex}-cell-availability`}>
                    <Typography
                      variant="caption"
                      className={classes.headerStyle}>
                      Availability Name
                    </Typography>
                  </TableCell>
                }
                {availabilityInfo.fields.map((prop, i) => (
                  <TableCell
                    className={classes.availabilityTableCell}
                    component="th"
                    key={`${mapIndex}-cell-${i}`}>
                    <Typography
                      variant="caption"
                      className={classes.headerStyle}>
                      {prop.fieldLabel}
                    </Typography>
                  </TableCell>
                ))}
                {emptyCells(6 - availabilityInfo?.fields?.length, mapIndex)}
              </TableRow>
              {availabilityInfo?.availabiliites?.map(
                (availability: any, index: number) => (
                  <TableRow
                    key={`${mapIndex}-row-${index}`}
                    className={`${
                      index % 2 ? classes.oddRow : classes.evenRow
                    }`}>
                    {
                      <TableCell
                        component="th"
                        key={`${mapIndex}-table-proposedspace`}
                        className={classes.availabilityPraposedTableCell}>
                        <Typography
                          variant="caption"
                          className={classes.contentStyle}>
                          {getAvailabilityFieldValues(
                            'availabilityName',
                            availability,
                          )?.trim() || '-'}
                        </Typography>
                      </TableCell>
                    }
                    {availabilityInfo?.fields?.map((column: any) => {
                      const value = getAvailabilityFieldValues(
                        column.fieldKey,
                        availability,
                      )?.trim();

                      return (
                        <TableCell
                          component="th"
                          key={`${mapIndex}-table-${column.fieldKey}`}
                          className={classes.availabilityTableCell}>
                          {linkAttributes.includes(column.fieldLabel) &&
                          !!value ? (
                            <Link href={value} className={classes.link}>
                              {value}
                            </Link>
                          ) : (
                            <Typography
                              variant="caption"
                              className={classes.contentStyle}>
                              {value || '-'}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                    {emptyCells(
                      6 - availabilityInfo.fields?.length,
                      `${mapIndex}-table`,
                    )}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        ))}
      </TableContainer>
    </Grid>
  );
};

export type Props = {
  availabilities: any[];
  header?: string;
};

export default React.memo(PropertyAvailabilityPreview);
