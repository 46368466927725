import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  AppBar,
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@emotion/core';
import internalTheme from '@cbreone/core-ui/dist/themes/internalTheme';
import AuthRouter from '@cbreone/core-services/dist/components/AuthRouter';
// eslint-disable-next-line import/no-unresolved
import { SurveyApp } from '@cep-miq/survey-lib';
import { signOutUser } from '@cbreone/core-services';
import BHSCAnalytics from 'libs/@cep-miq/survey-lib/src/services/BHSCAnalytics';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: internalTheme.palette.grey[200],
      color: internalTheme.palette.primary.main,
    },
    userInfo: {
      float: 'right',
      fontSize: '16px',
      marginLeft: 'auto',
      color: internalTheme.palette.primary.main,
      '& span': {
        padding: theme.spacing(1.5),
        cursor: 'pointer',
      },
    },
  }),
);

export function App() {
  const [isUserIdentified, setIsUserIdentified] = useState(false);
  if (!isUserIdentified) {
    var analytics = ((window as any).analytics =
      (window as any).analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            key +
            '/analytics.min.js';
          var n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = 'wfBqxYHH4kV4RqMXf1VF6yGXzqAPxHED';
        analytics.SNIPPET_VERSION = '4.15.3';
        analytics.load(
          process.env.NX_ENVIRONMENT_TYPE === 'production'
            ? 'quFQdWBOBbVE5yq43BxHCaWjPFyPEO4p'
            : 'wfBqxYHH4kV4RqMXf1VF6yGXzqAPxHED',
        );
        analytics.page();
      }
    BHSCAnalytics.identifyUser();
    setIsUserIdentified(true);
  }
  const classes = useStyles();
  const displayName = localStorage.getItem('displayName');
  return (
    <Router>
      <Switch>
        <Route path="/">
          <div>
            <CssBaseline />
            <ThemeProvider theme={internalTheme}>
              <AuthRouter>
                <SurveyApp />
              </AuthRouter>
            </ThemeProvider>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
