/* eslint-disable max-len */
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, Typography, IconButton, TextField, FormGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { parseInputChange } from '@cbreone/utilities';
import { Survey, Property } from '../../types';
import * as mapUtil from '../../utilities/map';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';

const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    padding: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    width: '320px',
    borderBottom: '1px solid #ccc',
    backgroundColor: 'white',
    zIndex: 100,
  },
  intercomPadding: {
    height: 'calc(100% - 100px)',
    overflow: 'auto',
  },
  tourList: {
    padding: '10px',
    marginTop: '88px',
    listStyleType: 'none',
  },
  tourType: {
    paddingRight: '10px',
  },
  stop: {
    padding: '8px 0 14px 0',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  stopDetails: {
    flexGrow: 2,
  },
  propTitle: {
    fontSize: '14px',
  },
  genText: {
    fontSize: '12px',
    color: '#a1a1a1',
    margin: '6px 0 12px 0',
  },
  pinNumbers: {
    color: 'white',
    border: '2px solid white',
    borderRadius: '50%',
    width: '23px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 8px',
  },
  tooltip: {
    backgroundColor: 'black',
    color: 'white',
    position: 'fixed',
    top: '62px',
    width: '254px',
    margin: '0 38px',
    padding: '8px',
    borderRadius: '4px',
    zIndex: 101,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      top: '-6px',
      left: '150px',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderBottom: '6px solid black',
    },
  },
  toolTrigger: {
    margin: '0 0 -4px 8px',
    padding: '1px',
  },
  hide: {
    display: 'none',
  },
}));

const TourSchedule: React.FC<Props> = ({
  survey,
  onUpdateProperty,
  onDismiss,
}) => {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState<Boolean>(false);
  const { getIconData } = mapUtil.default;

  const handleStopDetailsUpdate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = parseInputChange(event.target);
    onUpdateProperty({
      id: name,
      metaData: {
        stopDetails: value?.toString(),
      },
    });
  };

  return (
    <div className={classes.intercomPadding}>
      <div className={classes.header}>
        <Typography variant="h5">
          Tour Schedule
          <InfoOutlinedIcon
            className={classes.toolTrigger}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
        </Typography>
        <IconButton id="SC-CloseManageProperties" onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </div>
      <p className={showTooltip ? classes.tooltip : classes.hide}>
        Your Tour Schedule order follows the order from the properties in your
        survey. If you don’t see a property, that means it is toggled off in
        Manage Properties.
      </p>
      <ul className={classes.tourList}>
        {survey.properties
          .filter(
            (item: Property) =>
              (item.isHidden === undefined || !item.isHidden) &&
              !item.metaData?.isCurrentLocation,
          )
          .map((property, index) => (
            <li className={classes.stop} key={property?.id}>
              <Typography
                className={classes.pinNumbers}
                style={{ backgroundColor: getIconData(property).iconColor }}>
                {index + 1}
              </Typography>
              <FormGroup className={classes.stopDetails}>
                <Typography className={classes.propTitle}>
                  {property?.name}
                </Typography>
                <p className={classes.genText}>{property?.address1}</p>
                <TextField
                  className={classes.tourType}
                  name={property?.id}
                  onBlur={handleStopDetailsUpdate}
                  placeholder="e.g. 10am, Parking across street"
                  defaultValue={property.metaData?.stopDetails}
                  label="stop details"
                  fullWidth
                />
              </FormGroup>
            </li>
          ))}
      </ul>
    </div>
  );
};

export type Props = {
  survey: Survey;
  onUpdateProperty: UseSurveyEditorAPI['updateProperty'];
  onDismiss: () => void;
};

TourSchedule.displayName = 'TourSchedule';
export default TourSchedule;
