import uniqueId from '@cbreone/utilities/dist/uniqueId';
import { useEffect } from 'react';
import BHSCAnalytics, { EVENT_TITLES, SC_EVENT } from '../../services/BHSCAnalytics';
import * as surveyService from '../../services/survey-service';
import usePropertiesAPI, { UsePropertyAPI } from './usePropertiesAPI';
import useSurveyAPI, { UseSurveyAPI } from './useSurveyAPI';
import useMediaAPI, { UseMediaAPI } from './useSurveyMediaAPI';

// TODO: temporary, remove

export type UseSurveyEditorAPI =  UseSurveyAPI & UsePropertyAPI & UseMediaAPI;

export default function useSurveyEditorAPI(
  surveyId: string,
  resetMapStateCallback: Function,
): UseSurveyEditorAPI {

  const {
    addCustomField,
    addPropertyFiles,
    addSurveyContact,
    addSurveySection,
    deleteCustomField,
    deleteSurveyContact,
    deleteSurveySection,
    reorderSurveyAttribute,
    setSurvey,
    survey,
    resetMarketMap,
    updateCustomField,
    updateSurvey,
    updateSurveyContact,
    updateSurveySection,
    updatePropertyFieldRule,
    generateSurveyPDF,
  } = useSurveyAPI(resetMapStateCallback);

  const {
    addAvailability,
    addProperty,
    deleteAvailability,
    deleteAvailabilities,
    deleteProperty,
    selectProperty,
    selectedProperty,
    updateAvailability,
    updateAvailabilityStatus,
    reorderAvailability,
    updateProperty,
    updatePropertyCustomField,
    // eslint-disable-next-line max-len
  } = usePropertiesAPI({
    survey,
    setSurvey,
    resetMarketMap,
    // updatePropertyFieldRule,
    resetMapStateCallback,
  });

  const { invalidatePdf,updateMedia } = useMediaAPI({ survey, setSurvey });

  useEffect(() => {
    // make the call to get the survey and contacts
    const startTime = new Date().toISOString();
    surveyService
      .getSurvey(surveyId)
      .then((surveyRes: any) => {
        const endTime = new Date().toISOString();
        BHSCAnalytics.trackEvent(SC_EVENT, EVENT_TITLES.SC_SURVEY_LOAD, {
          startTime,
          endTime,
          surveyId,
          projectId: survey.projectId,
          surveyName: survey.name,
          source: 'Button',
        });
        // TODO: remove - this is temporary mock data for secions
        const surveyObj = surveyRes;
        const tourSectionExists =
          surveyObj.sections.find((item: any) => item.name === 'tour schedule') || (null as any);
        if (tourSectionExists === null) {
          surveyObj.sections.push({
            surveySectionId: uniqueId(),
            surveyId,
            name: 'tour schedule',
            isHidden: true,
            order: 4,
            isCustom: false,
            isDeletable: false,
            isRenamable: false,
            hasPDF: false,
            pdfs: [],
            isReorderable: true,
            createdOn: 'Oct. 11, 2018',
            updatedOn: 'Oct. 11, 2018',
          });
        }
        setSurvey(surveyObj);
      })
      .catch(() => {
        // TODO: make a generic component to handle the error.
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

/**
 * decorator pattern to invalidate the pdf
 * @param method 
 * @returns 
 */

  const invalidator =  (method:any) => {

    return async (...args: any[]) => {
      
      const response = method(...args);
 
      invalidatePdf();
      
      return response;
    };
}

  return {
    addAvailability: invalidator(addAvailability),
    addCustomField:invalidator(addCustomField),
    addProperty:invalidator(addProperty),
    addPropertyFiles:invalidator(addPropertyFiles),
    addSurveyContact:invalidator(addSurveyContact),
    addSurveySection:invalidator(addSurveySection),
    deleteAvailability:invalidator(deleteAvailability),
    deleteAvailabilities:invalidator(deleteAvailabilities),
    deleteCustomField:invalidator(deleteCustomField),
    deleteProperty:invalidator(deleteProperty),
    deleteSurveyContact:invalidator(deleteSurveyContact),
    deleteSurveySection:invalidator(deleteSurveySection),
    reorderSurveyAttribute:invalidator(reorderSurveyAttribute),
    selectProperty,
    selectedProperty,
    survey,
    setSurvey,
    reorderAvailability:invalidator(reorderAvailability),
    updateAvailabilityStatus:invalidator(updateAvailabilityStatus),
    resetMarketMap:resetMarketMap,
    updateAvailability:invalidator(updateAvailability),
    updateCustomField:invalidator(updateCustomField),
    updateProperty:invalidator(updateProperty),
    updatePropertyCustomField:invalidator(updatePropertyCustomField),
    updateSurvey:invalidator(updateSurvey),
    updateSurveyContact:invalidator(updateSurveyContact),
    updateSurveySection:invalidator(updateSurveySection),
    updatePropertyFieldRule:invalidator(updatePropertyFieldRule),
    generateSurveyPDF:invalidator(generateSurveyPDF),
    invalidatePdf: invalidatePdf,
    updateMedia
  };
}
