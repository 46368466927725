export const PDFPaperSize = {
  Landscape: 'Landscape',
  Portrait: 'Portrait',
};

// eslint-disable-next-line no-shadow
export enum MediaFileType {
  images = 'IMAGES',
  fliers = 'FLIERS',
  floorplans = 'FLOORPLANS'
}

export enum INPUT_TYPE {
  MULTI_LINE = 'multi_line',
  TEXT = 'text',
}
export const linkAttributes = ['Website', 'Virtual Tour'];

export const excludedTableFields = ['Address', 'Amenities', 'City', 'Comments', 'Postal Code', 'State Province', 'propertyProposedSpace', 'proposedSpace', 'Proposed Space'];

export enum STATE {
  INIT = 0,
  READY = 1,
  IN_PROGRESS = 2,
  COMPLETE = 3,
  FAIL = 4,
  ERROR = 5,
}

export enum STATUS {
  ACTIVE = 0,
  DELETED = 1,
  INACTIVE = 2,
}
