/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
/* ts-ignore */
import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { Theme, makeStyles, createStyles, Box } from '@material-ui/core';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useResizeDetector } from 'react-resize-detector';
import SurveyPDFPage from '../SurveyPDFPage';
import { MetaData, Property, Survey, SurveyPatch } from '../../types';
import Header from '../PDFCommon';
import { BAMWSO2ClientID, BAMWSO2SecretID } from '../../config';
import { isEmptyObjectFromStr } from '../../utilities';
import MapStyles from '../../map/MapControls/MapStyles';
import MapCenter from '../../map/MapControls/MapCenter';
import UseDebounce from '../../hooks/useDebounce';
import * as mapUtil from '../../utilities/map';
import MapRestore from '../../map/MapControls/MapRestore';
import {
  BAM_MAP_STYLE_SOURCES,
  iconColorActive,
  iconColorInactive,
} from '../../map/constants/MapStyles';
import Footer from '../Footer';
import MarketMapLegends from './MarketMapLegends';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { PDFPaperSize } from '../../data/constants';
import marketMapAction from '../../redux/actions/marketMapAction';
import { MediaState } from '../../redux/types/dataTypes';

const defaultZoomLevel = 4;
const defaultMapCenter = [-95, 40];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: '#5D8177',
    },
    marketMapContainer: {
      display: 'inline-flex',
      width: '11in',
      // height: '7.5in',
      margin: 'auto',
      background: '#f6f7f7',
    },
    marketMapImg: {
      maxHeight: 'calc(100% - 48px)',
      maxWidth: 'calc(100% - 48px)',
      // objectFit: 'contain',
      margin: 'auto auto',
    },
    printPageContainer: {
      width: '11in',
      height: '8.5in',
      margin: 'auto',
      border: '2px dashed #A9B2B5',
      boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
      overflow: 'hidden',
      backgroundColor: 'rgba(93, 129, 119, 0.1)',
      marginBottom: '20px',
      borderRadius: '15px',
      position: 'relative',
    },
    pageContainerLandscapeNoHeaderHeight: {
      height: '8in',
    },
    pageContainerLandscapeHeaderHeight: {
      height: '7in',
    },
    footer: {
      width: '100%',
      backgroundColor: '#cccccc',
      height: '0.5in',
      position: 'absolute',
      bottom: 0,
    },
    '@global': {
      '.slide-left': {
        height: '100%',
      },
      '.mapboxgl-map': {
        height: '100% !important',
        width: '100% !important',
      },
      '.tooltip-box': {
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
      },
      '.tooltip-img': {
        height: '140px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '.tooltip-address1': {
        fontSize: '1rem',
        lineHeight: 1.25,
        color: theme.palette.primary.dark,
        textAlign: 'left',
        margin: '10px 20px 0px 20px',
      },
      '.tooltip-address2': {
        fontSize: '12px',
        color: theme.palette.grey[800],
        textAlign: 'left',
        padding: '0px 20px 15px 20px',
      },
      '.action-list': {
        padding: '0px 20px 20px 20px',
      },
      '.tooltip-action-view': {
        background: theme.palette.primary.main,
        borderRadius: '2px',
        width: '100%',
        height: '30px',
        color: 'white',
        cursor: 'pointer',
        fontSize: '1rem',
      },
      '.disabled-btn': {
        cursor: 'default !important',
      },
      '.disabled-icon': {
        opacity: 0.2,
        cursor: 'default !important',
      },

      '.transparent-btn': {
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
      },
      '.btn-event': {
        pointerEvents: 'none',
      },
      '.action-container': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      '.popover-base-padding': {
        padding: '16px 15px 16px 17px',
      },
      '.style-map-body > div:first-child': {
        display: 'none',
      },
      '.tooltip-black': {
        position: 'relative',
        '&:hover .tooltiptext-black': {
          visibility: 'visible',
          opacity: 1,
        },
      },
      '.tooltiptext-black': {
        visibility: 'hidden',
        width: '200px',
        backgroundColor: '#555',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute',
        zIndex: 1,
        bottom: '120%',
        left: '-60%',
        marginLeft: '-60px',
        opacity: 0,
        transition: 'opacity 0.3s',
        '&:after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          marginLeft: '-5px',
          borderWidth: '5px',
          borderStyle: 'solid',
          borderColor: '#555 transparent transparent transparent',
        },
      },
      '.style-map-body > div:nth-child(2) > div:first-child> div:nth-child(2)':
        {
          display: 'none',
        },
      '#groupLabel': {
        display: 'none',
      },
      '.style-map-body > div:last-child': {
        display: 'none',
      },
    },
    mapCompBox: {
      display: 'block',
      position: 'relative',
      padding: '2px 2px',
    },
    overlay: {
      width: '100%',
      height: '100%',
      zIndex: 2,
      position: 'absolute',
      bottom: 0,
      opacity: 0,
    },
  }),
);

const MarketMapPreview: React.FC<Props> = ({
  index,
  survey,
  pageMode,
  order,
  anchore,
  updateSurvey,
  updatePropertyCoordinates,
  onPropertySelect,
  onUpdateProperty,
  properties,
  marketMapState,
  mediaState,
  isDrawerOpen,
}) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { height, ref } = useResizeDetector();
  const isInitialLoading = useRef<boolean>(true);
  const [boxSX, setBoxSX] = useState<Object>();
  const { getIconData, getActiveIconColor } = mapUtil.default;
  const latestProperty = useRef<Property[]>(survey.properties);
  const surveyRef = useRef<Survey>(survey);
  const mediaRef = useRef(mediaState);
  const [marketMapData, setMarketMapData] = useState<any>(() => {
    if (
      survey.marketMapBamData &&
      typeof JSON.parse(survey.marketMapBamData) === 'object' &&
      !isEmptyObjectFromStr(survey.marketMapBamData)
    ) {
      return JSON.parse(survey.marketMapBamData);
    }
    return {
      mapCentroid: mapUtil.default.getCenterPoint(latestProperty.current),
      mapZoomLevel: defaultZoomLevel,
    };
  });

  useEffect(() => {
    surveyRef.current = survey;
  }, [survey]);

  useEffect(() => {
    mediaRef.current = mediaState;
  }, [mediaState]);

  const marketMapDataRef = React.useRef(marketMapData);
  const isMapResetRef = React.useRef(false);
  const debouncedMapData = UseDebounce<any>(marketMapData, 500);
  const mapCompPrv = useRef<any>();
  const mediaList = useSelector((state: any) => state.media, shallowEqual);

  const updateMapPoints = () => {
    const mapComp = mapCompPrv.current;
    // eslint-disable-next-line valid-typeof
    if (mapComp && typeof mapComp.addPoints === 'function') {
      // REMOVE ALL POINTS
      mapComp.removeAllPoints().then(() => {
        mapUtil.default
          .getMapPointsWithImages(survey, mediaRef?.current || mediaState, true)
          .then((points: any) => {
            if (points.features.length > 0) {
              mapComp
                .addPoints(points, false)
                .then(() => {
                  updateMapState();
                })
                .catch(() => {
                  updateMapState();
                });
            } else {
              updateMapState();
            }
          });
      });
    }
  };

  const renderMapHandler = debounce(updateMapPoints, 2500);

  useEffect(() => {
    if (
      marketMapState.pdfPaperSize === PDFPaperSize.Portrait &&
      index === 0 &&
      !isDrawerOpen
    ) {
      dispatch(
        marketMapAction.updateMarketMapLegendHeightAction({
          legendHeight: height,
          resettingMap: false,
        }),
      );
    }
  }, [height, properties, isDrawerOpen]);

  const [pinsDragged, setPinsDragged] = useState<Boolean>();
  useEffect(() => {
    toggleResetButton();
  }, []);

  function toggleResetButton() {
    const movedProperties: String[] = [];
    survey.properties?.forEach((property) => {
      const parsedBamMapData = property.metaData?.bamMapData
        ? JSON.parse(property.metaData?.bamMapData)
        : {
            pdfCoordinates: {},
          };
      if (
        parsedBamMapData?.pdfCoordinates &&
        Object.keys(parsedBamMapData.pdfCoordinates).length > 0
      ) {
        movedProperties.push(property.id);
        // console.log('Moved properties count is', movedProperties.length);
      }
    });
    if (movedProperties.length > 0) {
      setPinsDragged(true);
    } else {
      setPinsDragged(false);
    }
  }

  useEffect(() => {
    const actionFired = (event: any) => {
      event.preventDefault();
      const {
        value: { action, id },
      } = event.detail;
      if (action === 'edit-property' && id) {
        onPropertySelect(id);
      } else if (action === 'toggle-property' && id) {
        onUpdateProperty({
          id,
          isHidden: !event.target.checked,
        });
      } else if (action === 'style-icon' && id) {
        mapCompPrv.current.getPoints().then((points: any) => {
          const iconIndex = points.features.findIndex(
            (item: any) => item.id === id,
          );
          const property: Property[] = latestProperty.current.filter(
            (item) => item.id === id,
          );
          points.features[iconIndex].properties.markerConfig.iconColor =
            getIconData(property[0]).iconColor;
          mapCompPrv.current.showStyleSlider(
            points.features[iconIndex].properties.markerConfig,
            points.features[iconIndex],
            false,
          );
        });
      } else if (action === 'radius-ring' && id) {
        mapCompPrv.current.getPoints().then((points: any) => {
          const iconIndex = points.features.findIndex(
            (item: any) => item.id === id,
          );
          const property: Property[] = latestProperty.current.filter(
            (item) => item.id === id,
          );
          points.features[iconIndex].properties.markerConfig.iconColor =
            getIconData(property[0]).iconColor;
          mapCompPrv.current.showStyleSlider(
            points.features[iconIndex].properties.markerConfig,
            points.features[iconIndex],
            true,
          );
        });
      }
      const ele = mapCompPrv.current.querySelector('.custom-popover');
      if (ele) {
        ele.parentElement.removeChild(ele);
      }
    };

    const iconUpdateSelection = (event: any) => {
      const propertyIndex = latestProperty.current.findIndex(
        (item: Property) => item.id === event.target.feature.id,
      );
      let bamMapData = {
        iconSize: '24',
        iconColor: iconColorInactive,
      };
      const mapData =
        latestProperty.current[propertyIndex].metaData?.bamMapData;
      if (mapData) {
        bamMapData = JSON.parse(mapData);
      }
      const data = {
        ...bamMapData,
        iconSize: event.target.feature.properties.markerConfig.iconSize,
        iconColor: event.target.feature.properties.markerConfig.iconColor,
        radius: event.target.feature.properties.markerConfig.radius,
        radiusColor: event.target.feature.properties.markerConfig.radiusColor,
        radiusLabel: event.target.feature.properties.markerConfig.radiusLabel,
        radiusLength: event.target.feature.properties.markerConfig.radiusLength,
      };
      onUpdateProperty({
        id: event.target.feature.id,
        metaData: {
          bamMapData: JSON.stringify(data),
        },
      });
    };
    // TODO: US1285118
    const renderMapIconUpdated = debounce(iconUpdateSelection, 2500);

    const mapStateUpdated = (event: any) => {
      if (event.type === 'dragEnd') {
        isMapResetRef.current = false;
      }
      // eslint-disable-next-line max-len
      Promise.all([
        mapCompPrv.current.getMapCenter(),
        mapCompPrv.current.getMapZoom(),
      ]).then((data) => {
        const mapData = {
          ...marketMapDataRef.current,
          mapReset: isMapResetRef.current,
          mapCentroid: data[0],
          mapZoomLevel: data[1],
        };
        marketMapDataRef.current = mapData;
        setMarketMapData(mapData);
      });
    };
    // TODO: US1285118
    const renderMapStateUpdated = debounce(mapStateUpdated, 2500);
    const wheelChanged = (event: any) => {
      event.preventDefault();
      isInitialLoading.current = false;
      isMapResetRef.current = false;
    };

    const mapCtrlChanged = (event: any) => {
      event.preventDefault();
      if (event.target.className === 'mapboxgl-ctrl-icon') {
        if (isMapResetRef.current) {
          marketMapDataRef.current = {
            ...marketMapDataRef.current,
            mapCentroid: mapUtil.default.getCenterPoint(latestProperty.current),
          };
        }
        isMapResetRef.current = false;
      } else if (
        event.target &&
        event.target.parentElement &&
        mapUtil.default.isNonMapCtrlClicked(
          event.target.parentElement.className,
        )
      ) {
        // TODO: US1285118
        // skip the action
      } else {
        // TODO: US1285118
        // consider to move the logic to the mapping component.

        // reset the icon colors to the default colors when there is activated color
        let resetPoints = false;
        mapCompPrv.current.getPoints().then((points: any) => {
          /* eslint no-param-reassign: "error" */
          points.features.map((prop: any) => {
            if (prop.properties.markerConfig.iconColor === iconColorActive) {
              resetPoints = true;
            }
            return null;
          });
          if (resetPoints) {
            mapCompPrv.current.removeAllPoints().then(() => {
              mapUtil.default
                .getMapPointsWithImages(
                  surveyRef.current,
                  mediaRef?.current || mediaState,
                  true,
                )
                .then((newpoints: any) => {
                  mapCompPrv.current.addPoints(newpoints, false).then(() => {
                    // TODO: US1285118
                    mapUtil.default.freezeMarkers(mapCompPrv.current);
                  });
                });
            });
          }
        });
      }
    };

    let preventMultiTriggerTimer: any = null;
    const pointClickedHandler = (e: any) => {
      clearTimeout(preventMultiTriggerTimer);

      const slideElement = mapCompPrv.current.querySelector('.slide-left');
      if (slideElement) {
        slideElement.classList.remove('slide-left');
        slideElement.classList.add('slide-right');
      }

      preventMultiTriggerTimer = setTimeout(() => {
        const iconId = e.detail.value.id;
        const point = e.detail.value.properties.id - 1;
        togglePin(mapCompPrv.current, point, iconId);
      }, 10);
    };
    // TODO: US1285118
    const pointCoordinatesUpdated = (event: any) => {
      setPinsDragged(true);

      const propId = event.detail.value.properties.id;
      // @ts-ignore
      const bamMapData = JSON.parse(
        latestProperty.current[propId - 1].metaData.bamMapData || '{}',
      );
      const data = {
        ...bamMapData,
        pdfCoordinates: {
          longitude: event.detail.value.geometry.coordinates[0],
          latitude: event.detail.value.geometry.coordinates[1],
        },
      };
      const payload = {
        id: event.detail.value.id,
        metaData: {
          bamMapData: JSON.stringify(data),
        },
      };
      updatePropertyCoordinates(payload);
    };

    const activateMap = () => {
      isInitialLoading.current = false;
    };

    if (mapCompPrv && mapCompPrv.current) {
      mapCompPrv.current.addEventListener('hostActionTriggered', actionFired);
      mapCompPrv.current.addEventListener('zoomEnd', renderMapStateUpdated);
      mapCompPrv.current.addEventListener('dragEnd', renderMapStateUpdated);
      mapCompPrv.current.addEventListener('wheel', wheelChanged);
      mapCompPrv.current.addEventListener('click', mapCtrlChanged);
      mapCompPrv.current.addEventListener('mousedown', activateMap);
      mapCompPrv.current.addEventListener('pointClicked', pointClickedHandler);
      mapCompPrv.current.addEventListener(
        'updateOptionsToggle',
        renderMapIconUpdated,
      );
      // TODO: US1285118
      mapCompPrv.current.addEventListener(
        'pointDragEnd',
        pointCoordinatesUpdated,
      );
      setTimeout(() => {
        console.log('Started toggle drag points !');
        const enableDrag = async () => {
          await mapCompPrv?.current?.toggleAllDragPoints(false);
        };
        enableDrag()
          .then((data) => {
            console.log(data, 'drag data ?');
          })
          .catch((error) => {
            console.log(error, 'drag error ?');
          });
      }, 6000);
    }
    return () => {
      mapCompPrv.current.removeEventListener(
        'hostActionTriggered',
        actionFired,
      );
      mapCompPrv.current.removeEventListener('zoomEnd', renderMapStateUpdated);
      mapCompPrv.current.removeEventListener('dragEnd', renderMapStateUpdated);
      mapCompPrv.current.removeEventListener('wheel', wheelChanged);
      mapCompPrv.current.removeEventListener('click', mapCtrlChanged);
      mapCompPrv.current.removeEventListener('mousedown', activateMap);
      mapCompPrv.current.removeEventListener(
        'pointClicked',
        pointClickedHandler,
      );
      mapCompPrv.current.removeEventListener(
        'updateOptionsToggle',
        renderMapIconUpdated,
      );
      // TODO: US1285118
      mapCompPrv.current.removeEventListener(
        'pointDragEnd',
        pointCoordinatesUpdated,
      );
    };
  }, []);

  function clearPdfCoords() {
    const propertiesToReset = [];
    latestProperty.current.forEach((prop, index) => {
      // @ts-ignore
      const bamMapData = JSON.parse(prop.metaData?.bamMapData || '{}');
      const data = {
        ...bamMapData,
        pdfCoordinates: {},
      };
      const payload = {
        id: prop.id,
        metaData: {
          bamMapData: JSON.stringify(data),
        },
      };
      const vd = updatePropertyCoordinates(payload);
      return null;
    });
    const mapComp = mapCompPrv.current;

    // eslint-disable-next-line valid-typeof
    if (mapComp && typeof mapComp.addPoints === 'function') {
      // REMOVE ALL POINTS
      mapComp.removeAllPoints().then(() => {
        const newData = surveyRef.current;
        const newPropData = newData.properties?.map((prop) => {
          const bamMapData = JSON.parse(prop.metaData?.bamMapData || '{}');
          const data = {
            ...bamMapData,
            pdfCoordinates: {},
          };
          if (!prop.metaData) {
            prop.metaData = {} as MetaData;
          }
          prop.metaData.bamMapData = JSON.stringify(data);

          return prop;
        });
        setPinsDragged(false);
        newData.properties = newPropData;
        // re-render without checking for pdf
        mapUtil.default
          .getMapPointsWithImages(
            newData,
            mediaRef?.current || mediaState,
            false,
          )
          .then((points: any) => {
            if (points.features.length > 0) {
              mapComp
                .addPoints(points, false)
                .then(() => {
                  updateMapState();
                })
                .catch(() => {
                  updateMapState();
                });
            }
          });
      });
    }
  }

  useEffect(() => {
    if (survey.id || survey.isClientShortlist) {
      renderMapHandler();
    }
  }, [survey.id, mediaList, survey.isClientShortlist]);

  useEffect(() => {
    latestProperty.current = survey.properties || [];
  }, [survey.properties]);

  const disableControls = () => {
    const disableControlsCall = async () =>
      mapCompPrv?.current.enableZoomControls(false);
    disableControlsCall().catch((err) => console.error(err));
  };

  const updateMapState = () => {
    const mapComp = mapCompPrv.current;
    if (!mapComp) {
      return;
    }
    if (marketMapData.mapReset) {
      mapComp.zoomToFit();
    } else {
      const center = marketMapData.mapCentroid?.includes(null)
        ? defaultMapCenter
        : marketMapData.mapCentroid;
      mapComp.updateMapCenter(center);
      mapComp.updateMapZoom(marketMapData.mapZoomLevel);
    }
    mapComp.enableZoomControls(true);
    mapComp.updateMapStyles(
      BAM_MAP_STYLE_SOURCES[survey.marketMapStyle] ||
        BAM_MAP_STYLE_SOURCES.cbreLight,
    );
    // TODO: US1285118
    mapUtil.default.freezeMarkers(mapComp);
    // eslint-disable-next-line max-len
    Promise.all([
      mapCompPrv.current.getMapCenter(),
      mapCompPrv.current.getMapZoom(),
    ]).then((data) => {
      const mapState = {
        ...debouncedMapData,
        mapCentroid: data[0],
        mapZoomLevel: data[1],
        // pdfCoordinates: {},
      };
      dispatch(
        marketMapAction.updateMarketMapZoomCenterAction({
          activeMapIndex: index,
          marketMapBamData: JSON.stringify(mapState),
          resettingMap: false,
        }),
      );
    });
    if (index !== 0) {
      disableControls();
    }
  };

  const onMapStyleChange = (style: string) => {
    updateSurvey({
      id: survey.id,
      marketMapStyle: style,
    });
    dispatch(
      marketMapAction.updateMarketMapStyleAction({
        id: survey.id,
        marketMapStyle: style,
        resettingMap: false,
      }),
    );
  };

  const onRecenterChange = () => {
    isMapResetRef.current = true;
    if (
      Array.isArray(latestProperty.current) &&
      latestProperty.current.length > 0
    ) {
      mapCompPrv.current.zoomToFit().then(() => {
        const mapData = {
          ...marketMapDataRef.current,
          mapReset: isMapResetRef.current,
        };
        marketMapDataRef.current = mapData;
        setMarketMapData(mapData);
      });
    } else {
      const mapData = {
        mapCentroid: defaultMapCenter,
        mapZoomLevel: defaultZoomLevel,
        mapReset: true,
      };
      marketMapDataRef.current = mapData;
      setMarketMapData(mapData);
      mapCompPrv.current.updateMapCenter(defaultMapCenter);
      mapCompPrv.current.updateMapZoom(defaultZoomLevel);
    }
  };

  const togglePin = async (map: any, pinNum: number, iconId: String) => {
    const property: Property[] = latestProperty.current.filter(
      (item: Property) => item.id === iconId,
    );
    const res = Promise.resolve(map.getPoints());
    await res.then((points) => {
      points.features[pinNum].properties.markerConfig.iconColor =
        getActiveIconColor(property[0]);
      map.updatePoint(points.features[pinNum]);
      map.togglePopup(pinNum + 1);
    });
  };

  useEffect(() => {
    if (
      debouncedMapData &&
      Object.keys(debouncedMapData).length > 0 &&
      !isInitialLoading.current &&
      index === 0 &&
      typeof mapCompPrv.current.getMapCenter === 'function'
    ) {
      Promise.all([
        mapCompPrv.current.getMapCenter(),
        mapCompPrv.current.getMapZoom(),
      ]).then((data) => {
        const mapState = {
          ...debouncedMapData,
          mapCentroid: data[0],
          mapZoomLevel: data[1],
        };
        updateSurvey({
          id: survey.id,
          marketMapBamData: JSON.stringify(mapState),
        });
        dispatch(
          marketMapAction.updateMarketMapZoomCenterAction({
            activeMapIndex: index,
            marketMapBamData: JSON.stringify(mapState),
            resettingMap: false,
          }),
        );
      });
    }
  }, [debouncedMapData]);

  useEffect(() => {
    if (survey.pdfPaperSize === PDFPaperSize.Landscape) {
      setBoxSX({ display: 'flex' });
    } else if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
      setBoxSX({ flexDirection: 'row' });
    }
  }, [survey.pdfPaperSize]);

  return (
    <div id={`${order}`} style={anchore}>
      <SurveyPDFPage mode={pageMode} paperSize={survey.pdfPaperSize}>
        {marketMapState.isIncludeHeader && <Header title={'Market Map'} />}
        <Box sx={boxSX}>
          {survey.pdfPaperSize === PDFPaperSize.Landscape && (
            <Box
              sx={{ flexGrow: 1 }}
              width={
                marketMapState?.mapSize ? 1056 - marketMapState.mapSize[0] : 0
              }>
              <MarketMapLegends
                survey={survey}
                properties={properties as Property[]}
              />
            </Box>
          )}
          <Box
            className={classes.mapCompBox}
            sx={{ flexShrink: 1 }}
            width={marketMapState?.mapSize ? marketMapState.mapSize[0] : 0}
            height={marketMapState?.mapSize ? marketMapState.mapSize[1] : 0}>
            <mapping-component
              vendor="mapbox"
              client-id={BAMWSO2ClientID}
              secret-id={BAMWSO2SecretID}
              ref={mapCompPrv}
              id={`marketmap-${index}`}
              lat="37"
              lng="-95"
              zoom={marketMapData.mapZoomLevel}>
              {index === 0 && (
                <>
                  {pinsDragged && (
                    // TODO: US1285118
                    <MapRestore onRestoreChange={clearPdfCoords} />
                  )}
                  <MapCenter onRecenterChange={onRecenterChange} />
                  <MapStyles
                    onMapStyleChange={onMapStyleChange}
                    survey={marketMapState}
                  />
                </>
              )}
            </mapping-component>
          </Box>
          {survey.pdfPaperSize === PDFPaperSize.Portrait &&
            properties &&
            properties.length > 0 && (
              <div ref={ref}>
                <Box sx={{ flexShrink: 1 }}>
                  <MarketMapLegends
                    survey={survey}
                    properties={properties as Property[]}
                  />
                </Box>
              </div>
            )}
        </Box>
        <div className={classes.footer}>
          <Footer surveyCountry={survey.surveyCountry}></Footer>
        </div>
        {index !== 0 && <div className={classes.overlay}></div>}
      </SurveyPDFPage>
    </div>
  );
};

export type Props = {
  index?: number;
  survey: Survey;
  pageMode?: string;
  order: string;
  anchore?: object;
  updateSurvey: (survey: SurveyPatch) => void;
  updatePropertyCoordinates: UseSurveyEditorAPI['updateProperty'];
  onPropertySelect: UseSurveyEditorAPI['selectProperty'];
  onUpdateProperty: UseSurveyEditorAPI['updateProperty'];
  properties?: Property[];
  marketMapState?: any;
  mediaState?: MediaState;
  isDrawerOpen?: boolean;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  marketMapState: state.map,
  mediaState: state.media,
  isDrawerOpen: state.properties.isDrawerOpen,
  ...ownProps,
});

MarketMapPreview.displayName = 'MarketMapPreview';
export default React.memo(connect(mapStateToProps)(MarketMapPreview));
