/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Stars from '@material-ui/icons/Stars';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import PopOver, { PopOverItem } from './popover';
import Thumbnail, { ThumbnailSize } from './thumbnail';

// interface for file for display within gallery, may be modified later
export interface GalleryFile {
  id?: number;
  url: string;
  previewUrl?: string;
  displayText: string;
  active: boolean | null;
  primary?: boolean | null;
  deletable?: boolean | null;
  order?: number | null;
  userOverride?: boolean | null;
  errorDisplay?: string | null;
  loadingMsg?: string | null;
  modifiedFromMediaId?: string;
}

export interface FormUploadProps {
  name: string;
  addFilePrompt: string;
  options?: any;
  doSort?: boolean;
  featuredFileOption?: boolean;
  title?: string;
  description?: string;
  accepted: string;
  doLocalReorder?: boolean;
  fileUploadCall: (e: GalleryFile, fileIndex: number) => any;
  fileUpdateCall: (e: GalleryFile) => any;
  fileDeleteCall: (e: GalleryFile) => any;
  fileReorderCall?: (e: GalleryFile) => any;
  files: File[];
  children?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formUploadContainerDiv: {
      '& > h5': {
        height: '15px',
        width: '601px',
        color: 'red',
        fontSize: '12px',
        fontWeight: 300,
        fontFamily: 'inherit',
        lineHeight: '15px',
        marginBottom: '15px',
      },
      '& h5.formUploadHeader': {
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        margin: '30px 0',
        '& div': {
          width: 'auto',
          display: 'block',
          marginTop: 0,
          marginLeft: '30px',
          '& h5': {
            marginTop: 0,
          },
        },
      },
      '& .plus': {
        textAlign: 'center',
        color: '#8585e0',
        width: '200px',
        position: 'absolute',
        top: '25px',
      },
      '& .file-drop': {
        width: '100%',
        outline: 0,
        border: 0,
      },
      '& input:focus': {
        outline: 0,
      },
      '& .file-drop-target': {
        display: 'flex',
        '& :focus': {
          outlineColor: '#00B2DD',
        },
      },
      '& .file-drop-target > div': {
        padding: 0,
        width: '100% !important',
      },
      '& #dropIndicator': {
        lineHeight: '117px',
        textAlign: 'center',
        width: 'calc(100% - 310px)',
        fontWeight: 'normal',
        fontFamily: 'inherit',
        color: '#999',
      },
    },
    baseInputContainerDiv: {
      width: '100%',
      fontWeight: 'normal',
      fontFamily: 'inherit',
      '& .inactive': {
        '& div': {
          '& .img-thumbnail': {
            filter: 'grayscale(1)',
            opacity: 0.3,
          },
          '& .pdf-thumbnail-img': {
            filter: 'grayscale(1)',
            opacity: 0.3,
          },
        },
      },
      '& .thumb': {
        position: 'relative',
        maxWidth: '200px',
      },
      '& .small-thumb': {
        position: 'relative',
        maxWidth: '125px',
        '& div': {
          '& .img-thumbnail': {
            filter: 'grayscale(1)',
            opacity: 0.3,
          },
        },
      },
      '& .file-drop-target-columns': {
        maxWidth: 'none',
      },
    },
    fileUploadButtonDiv: {
      position: 'relative',
      width: '100%',
      cursor: 'pointer',
      textAlign: 'center',
      height: '150px',
      outline: 0,
      display: 'flex',
      '& h5': {
        fontSize: '20px',
        color: '#00B2DD',
        margin: 0,
        paddingTop: '75px',
        width: '200px',
        border: '1px rgba(0, 0, 0, 0.15) dashed',
        background: '#f6f6f6',
      },
      '& input': {
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        cursor: 'pointer',
        zIndex: 1,
        minHeight: '150px',
        width: '100%',
      },
    },
    styledHeader: {
      color: '#8e9a9d',
      fontFamily: 'inherit',
      letterFpacing: '0.03rem',
      marginBlockEnd: '0.5em',
    },
    styledTitle: {
      height: '21px',
      width: 'auto',
      color: '#8e9a9d',
      fontSize: '16px',
      fontFamily: 'helvetica',
      lineHeight: '21px',
      marginRight: '10px',
    },
    baseFieldHeading: {
      color: '#00b2dd',
      fontFamily: 'inherit',
      fontSize: '16px',
      marginBlockEnd: '0.5em',
      textTransform: 'capitalize',
      fontWeight: 300,
    },
    titleSpan: {
      fontFamily: 'inherit',
      color: '#8585e0',
      marginRight: '10px',
    },
    deleteMsg: {
      cursor: 'pointer',
      marginTop: '10px',
      marginRight: '10px',
      fontSize: '16px',
      color: '#02c2f2',
      float: 'right',
    },
  }),
);

const customStyles = {
  overlay: {
    zIndex: 10000,
  },
};

const FileGalleryUpload: FC<FormUploadProps> = (props) => {
  // variables from props:
  const {
    name,
    accepted,
    description,
    title,
    addFilePrompt,
    featuredFileOption,
    files,
    children,
  } = props;
  const classes = useStyles();
  // used to toggle popover - only one popover can be open at once for ux purposes
  const [popover, setPopover] = useState(-1);
  // used to trigger shadowbox
  const [isOpen, setIsOpen] = useState(false);
  // index for image array
  const [photoPreviewURL, setPhotoPreviewURL] = useState<string>('');
  // sets the id of the dragged file
  const [dragId, setDragId] = useState();

  let tempPayload: GalleryFile[] = [];

  const getPayload = () => {
    if (Array.isArray(files)) {
      tempPayload = [];
      files.forEach((file: any) => {
        const tempPayloadFile = {
          id: file.id,
          url: file.url,
          previewUrl: file.previewUrl,
          displayText: file.displayText,
          active: file.active,
          primary: file.primary,
          userOverride: file.userOverride || false,
          loadingMsg: file.loadingMsg || null,
          errorDisplay: file.errorDisplay || null,
          order: file.order,
          deletable: file.deletable,
          modifiedFromMediaId: file.modifiedFromMediaId,
          mediaId: file.mediaId,
        };
        tempPayload.push(tempPayloadFile);
      });
    }
    return tempPayload;
  };

  const [payload, setPayload] = useState(() => {
    const initialPayload = getPayload();
    return initialPayload;
  });

  useEffect(() => {
    const newpayload = getPayload();
    setPayload(newpayload);
  }, [files]);

  // trigger to display error warning
  const [showError, setShowError] = useState('');
  // file upload limit size
  const uploadLimit: number = 200000000;

  // force update equivalent
  const [value, setValue] = useState<number>(0);
  const forceUpdate = useCallback(() => setValue(value), [value]);
  const rerender = () => {
    const updateVal = value;
    setValue(updateVal + 1);
    setTimeout(() => {
      forceUpdate();
    }, 1);
  };

  const replaceFileInArray = (arr: any, file: any, fileData: GalleryFile) => {
    if (arr?.length > 0) {
      for (let index: number = 0; index < arr.length; index += 1) {
        const currentFile: GalleryFile = arr[index];
        if (
          currentFile.displayText === file.name &&
          (currentFile.id === -1 || currentFile.id === fileData.id)
        ) {
          // eslint-disable-next-line no-param-reassign
          arr[index] = fileData;
          break;
        }
      }
    }
    return arr;
  };

  const sendFile = (theFile: any, i: number) => {
    if (props.fileUploadCall) {
      const temp: GalleryFile[] = payload;
      props.fileUploadCall(theFile, i).then(
        (img: any) => {
          const obj: GalleryFile = {
            id: img.id ? img.id : payload.length,
            url: `${img.url}`,
            previewUrl: img.previewUrl,
            displayText: img.displayText,
            active: img.active,
            primary: img.primary, // (payload[0].loadingMsg === 'Loading' && i === 0),
            deletable: img.deletable,
          };
          const modifiedPayload = replaceFileInArray(temp, theFile, obj);
          setPayload(modifiedPayload);
          // props.updatePhotoPayload(temp);
          rerender();
        },
        (error: any) => {
          setShowError(`Error uploading: ${error}`);
        },
      );
    }
  };

  // Called whenever a user clicks on the input and selects a file - it checks to make sure it only adds accepted file types
  const onDrop = (acceptedFiles: any) => {
    const fileArray: any = [];

    const temp: GalleryFile[] = payload;
    setShowError('');
    // eslint-disable-next-line no-restricted-syntax
    for (const file of acceptedFiles) {
      const acceptedFileTypes = accepted.split(',');
      const tempFileName = file.name.substr(
        file.name.lastIndexOf('.'),
        file.name.length,
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const acceptedFileType of acceptedFileTypes) {
        if (
          tempFileName.toLowerCase() ===
          acceptedFileType.replace(/\s/g, '').toLowerCase()
        ) {
          if (file.size <= uploadLimit) {
            fileArray.push(file);

            // placeholder for file being uploaded
            const obj: GalleryFile = {
              id: -1,
              url: '',
              displayText: file.name,
              active: false,
              primary: false,
              loadingMsg: 'Loading',
              deletable: true,
            };

            temp.push(obj);
          } else {
            setShowError(
              `File size exceeds ${Math.round(uploadLimit / 1000000)}mb limit`,
            );
          }
        }
      }
    }

    setPayload(temp);

    for (let i: number = 0; i < fileArray.length; i += 1) {
      sendFile(fileArray[i], i);
    }
    rerender();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: false,
  }); // dropzone hooks

  // removes the file via the index in the array
  const deleteFile = (index: number) => {
    const temp: GalleryFile[] = payload;

    const fileToDelete = temp[index];
    const isPrimary: boolean | null = fileToDelete.primary as any;

    temp.splice(index, 1);
    if (featuredFileOption) {
      if (isPrimary && temp.length > 0) {
        let newPrimarySet = false;
        // find the first file that could be primary
        temp.forEach((tempFile) => {
          if (!newPrimarySet && tempFile?.active) {
            // eslint-disable-next-line no-param-reassign
            tempFile.primary = true;
            newPrimarySet = true;
            props.fileUpdateCall(tempFile);
          }
        });
      }
    }
    setPayload(temp);
    props.fileDeleteCall(fileToDelete);

    if (fileToDelete && fileToDelete.modifiedFromMediaId) {
      const vd: any = { id: fileToDelete.modifiedFromMediaId };
      props.fileDeleteCall(vd);
    }
  };

  // removes info message the index in the array
  const deleteFileMsg = (index: number) => {
    const temp: GalleryFile[] = payload;
    temp.splice(index, 1);
    setPayload(temp);
    rerender();
    // props.updatePhotoPayload(temp);
  };

  // function used to open the image shadowbox
  const openShadowBox = (url: string) => {
    setPhotoPreviewURL(url);
    setIsOpen(true);
  };

  // update the index inside the nameArray with the name the user updated in thumbnail
  const setFileName = (fileName: string, extension: string, index: number) => {
    if (payload[index]) {
      const temp: GalleryFile[] = payload;
      temp[index].displayText =
        fileName.replace(/[^a-zA-Z0-9.\-_ ]/g, '_') + extension;
      setPayload(temp);
      props.fileUpdateCall(payload[index]);
    }
  };

  // TODO: could be taken out as a utility
  const sortFiles = (filesToSort: GalleryFile[]): GalleryFile[] => {
    filesToSort.sort((a, b) =>
      a.order && b.order && b.order > a.order ? 1 : -1,
    );
    filesToSort.sort((a, b) => (b.primary ? 1 : -1));
    // ensure all files have an order
    let order = 1;
    filesToSort.forEach((file: GalleryFile) => {
      // eslint-disable-next-line no-param-reassign
      file.order = order;
      order += 1;
    });
    return filesToSort;
  };

  // sets the primary image by saving the index of the file within the array to the state primary
  const makePrimary = (index: number) => {
    const temp: GalleryFile[] = payload;
    for (let i = 0; i < temp.length; i += 1) {
      temp[i].primary = false;
    }

    temp[index].primary = true;

    // - Sorts files to make primary file first in the list, disable for now
    // const sortedFiles = sortFiles(temp);
    // setPayload(sortedFiles);

    setPayload(temp);
    props.fileUpdateCall(temp[index]);
    rerender();
  };

  // pushes the index of the file into this array which keeps track of inactive files
  const markInactive = (index: number) => {
    const temp: GalleryFile[] = payload;
    const isPrimary: boolean | null = temp[index].primary as any;
    const isActive: boolean | null = temp[index].active;
    temp[index].active = !temp[index].active;

    if (isPrimary && isActive) {
      if (featuredFileOption && temp?.length > 1) {
        for (let i: number = 0; i < temp.length; i += 1) {
          if (temp[i].active && i !== index) {
            temp[i].primary = true;
            temp[index].primary = false;
            props.fileUpdateCall(temp[index]);
            props.fileUpdateCall(temp[i]);
            break;
          }
        }
      }
    } else {
      props.fileUpdateCall(temp[index]);
    }
    setPayload(temp);
  };

  // popover menu options
  const getPopOverOptions = (itemActive: boolean, disableDelete: boolean) => {
    const options: PopOverItem[] = [];
    // delete is included in all current scenarios
    if (!disableDelete) {
      options.push({
        icon: <Delete />,
        name: 'Delete',
        clickHandler: deleteFile,
      });
    }

    // if the files accepted props includes images, (or image is active) include the primary object, otherwise omit it

    if (featuredFileOption && itemActive) {
      options.push({
        icon: <Stars />,
        name: 'Featured',
        clickHandler: makePrimary,
      });
      options.push({
        icon: <VisibilityOff />,
        name: 'Inactive',
        clickHandler: markInactive,
      });
    } else {
      options.push({
        icon: <VisibilityOff />,
        name: 'Inactive',
        clickHandler: markInactive,
      });
    }
    return options;
  };

  // when popover is triggered, update the index of popover's location - used to only have one open at a time
  const updatePopover = (index: number) => {
    if (popover !== index) {
      setPopover(index);
    } else {
      setPopover(-1);
    }
  };

  const doDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const doDrop = (ev: any) => {
    const temp: GalleryFile[] = payload;
    const index = Number(ev.currentTarget.id);
    temp[index].order = dragId;

    if (props.doLocalReorder) {
      const fileToMoveIndex = Number(dragId);
      const fileToMove = temp[fileToMoveIndex];

      temp.splice(fileToMoveIndex, 1);
      // insert at correct index
      temp.splice(index, 0, fileToMove);
    }

    setPayload(temp);
    if (props.fileReorderCall) {
      props.fileReorderCall(temp[index]);
    }
  };

  // render methods
  const displayProcessedFiles = (
    handleDrag: any,
    handleDrop: any,
    children: any,
  ) => {
    if (payload && payload[0] && payload[0].url !== '') {
      return (
        <>
          {payload.map((payloadFile: any, index: number) => (
            <Grid
              item
              key={index + payloadFile.displayText}
              draggable={true}
              id={index.toString()}
              onDragOver={(ev) => ev.preventDefault()}
              onDragStart={handleDrag}
              onDrop={handleDrop}>
              <DragIndicatorIcon style={{ float: 'left' }} />
              <div className={payloadFile.active ? 'thumb' : 'thumb inactive'}>
                {payload[index].errorDisplay && (
                  <div
                    key={`${index}deleteMsg`}
                    role="presentation"
                    className={classes.deleteMsg}
                    onClick={() => deleteFileMsg(index)}>
                    X
                  </div>
                )}
                <Thumbnail
                  file={payloadFile.url}
                  previewUrl={payloadFile.previewUrl}
                  showNameEdit={true}
                  size={ThumbnailSize.NORMAL}
                  loadingMsg={payloadFile.loadingMsg}
                  errorMsg={payloadFile.errorDisplay}
                  hideStar={!featuredFileOption}
                  openShadowBox={openShadowBox}
                  name={payloadFile.displayText}
                  key={index + payloadFile.displayText}
                  setFileName={setFileName}
                  index={index}
                  primary={!!payloadFile.primary}
                />
                {!(
                  payload[index].loadingMsg || payload[index].errorDisplay
                ) && (
                  <PopOver
                    index={index}
                    handlePopUpAction={true}
                    key={`${index}popover`}
                    options={getPopOverOptions(
                      payloadFile.active,
                      payloadFile.deletable !== null
                        ? !payloadFile.deletable
                        : false,
                    )}
                    inactive={payloadFile.active}
                    popoverEnabled={popover === index}
                    updatepopover={updatePopover}
                  />
                )}{' '}
                {/* tslint:disable-line */}
                {children &&
                  React.cloneElement(children, {
                    mediaId:
                      payloadFile.modifiedFromMediaId || payloadFile.mediaId,
                  })}
              </div>
            </Grid>
          ))}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <div
        className={`${classes.formUploadContainerDiv} ${
          featuredFileOption ? 'jestTest isPrimary' : 'jestTest'
        }`}>
        {title && (
          <h5 className={classes.styledHeader} {...props}>
            <span className={classes.styledTitle} {...props}>
              {title}
            </span>{' '}
            {description}
          </h5>
        )}
        <div className={classes.baseInputContainerDiv}>
          <div
            style={{ background: isDragActive ? '#f6f6f6' : 'inherit' }}
            className="file-drop">
            <div className="file-drop-target">
              <Grid container spacing={3}>
                {displayProcessedFiles(doDrag, doDrop, children)}

                <Grid item>
                  <div
                    role="presentation"
                    className={`${classes.fileUploadButtonDiv} file-upload-button`}
                    {...getRootProps({
                      onClick: (event: any) => '',
                    })}>
                    <span
                      className="plus"
                      style={{ fontSize: '20px', marginTop: '25px' }}>
                      +
                    </span>
                    <h5 className={classes.baseFieldHeading}>
                      {addFilePrompt && (
                        <span className={classes.titleSpan}>
                          {addFilePrompt}
                        </span>
                      )}
                    </h5>
                    <input
                      {...getInputProps()}
                      name={name}
                      type="file"
                      id={name}
                      accept={accepted}
                      className="form-control"
                    />
                  </div>
                  {showError !== '' && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        textIndent: '4px',
                        maxWidth: '200px',
                      }}>
                      {showError}
                    </p>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            reactModalStyle={customStyles}
            mainSrc={photoPreviewURL}
            onCloseRequest={() => {
              setIsOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default FileGalleryUpload;
